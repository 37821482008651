import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges,Inject, LOCALE_ID } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { formatNumber } from '@angular/common';

@Component({
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpandThird', [
      state('closed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('open', style({ height: '*', visibility: 'visible' })),
      transition('closed <=> open', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  selector: 'app-common-carousel-grid',
  templateUrl: './common-carousel-grid.component.html',
  styleUrls: ['./common-carousel-grid.component.scss']
})

export class CommonCarouselGridComponent implements OnInit, OnChanges {
  @Input() public gridData;
  @Input() accrualDropsChecked?: boolean = false;
  @Input() enableHoldCheckBox?: boolean = false;
  @Input() disableHoldCheckBox?: boolean = false;
  @Input() public cgToggleData;
  @Input() public totalColumnsVisibleAtATime;
  @Input() public filterEnabled = false;
  @Input() public editable?: boolean;
  @Input() public showFooter = false;
  @Output() public emitAccrualDropHoldUpdate: EventEmitter<any> = new EventEmitter();

  public dataSourceFirstLevelList = {
    dataSource: new MatTableDataSource<any>()
  };
  displayedColumns: string[] = [];
  // displayNewTableLevel2 = false;
  amountHeaders = [];
  totalNumberOfColumns;
  startIndex = 0;
  endIndex = 0;
  carouselColumnsHidden = false;
  leftCarouselArrow = false;
  rightCarouselArrow = false;

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
    // console.log(this.gridData);
    this.totalNumberOfColumns = this.gridData.columns ? this.gridData.columns.length : 0;
    // set columns in view
    this.setDefaultColumnsInView();
    this.carouselColumnsHidden = this.gridData.columns.filter(obj => obj.columnType === 'aggregate').length > this.totalColumnsVisibleAtATime ? true : false;
    this.setCarouselArrows();
    this.displayedColumns = this.gridData.displayColumns;
    // this.displayNewTableLevel2 = this.gridData.displayNewTableLevel2 ? true : false;
    this.amountHeaders = this.gridData.amountColumns ? this.gridData.amountColumns : [];
    this.dataSourceFirstLevelList.dataSource = new MatTableDataSource(this.gridData.data);
  }

  private setDefaultColumnsInView() {
    let counter = 0;
    this.gridData.columns.forEach((obj, i) => {
      if (counter < this.totalColumnsVisibleAtATime && obj.columnType === 'aggregate') {
        counter++;
        obj.refEnable = false;
        obj.inView = true;
        this.endIndex = i;
      }
    });
  }

  setCarouselArrows() {
    this.leftCarouselArrow = this.carouselColumnsHidden && this.startIndex >= 2 ? true : false;
    this.rightCarouselArrow = this.carouselColumnsHidden && this.endIndex <= this.totalNumberOfColumns - 3 ? true : false;
  }

  navigateColumns(direction) {
    let index;
    if (direction === 'left') {
      index = this.startIndex;
      const viewData = this.getCarouselColumnsInView(this.gridData.columns[index].name);
      this.getViewableColumnInViewFromLeft(1, index);
      // Hide the last column in view and set the endIndex to 2nd last column in View
      this.hideColumn(viewData.inViewColumns[viewData.inViewColumns.length - 1]);
      this.endIndex = viewData.inViewColumns[viewData.inViewColumns.length - 2]['index'];
    } else {
      index = this.endIndex;
      const viewData = this.getCarouselColumnsInView(this.gridData.columns[index].name);
      this.getViewableColumnInViewFromRight(viewData.inViewColumns[0], index, 1);
      // Hide the first column in view and set the startIndex to 2nd column in View
      this.hideColumn(viewData.inViewColumns[0]);
      this.startIndex = viewData.inViewColumns[1]['index'];
    }
    this.setCarouselArrows();
  }

  /* get inView columns and index of the clicked column within inView */
  getCarouselColumnsInView(colName) {
    let inViewColumns = [], count = 0, indexClicked;
    this.gridData.columns.filter((obj, i) => {
      if (obj.inView === true) {
        count++;
        obj['index'] = i; // Needed to move the startIndex and endIndex
        inViewColumns.push(obj);
        // console.log(140, obj, inViewColumns);
        if (obj.name === colName) {
          indexClicked = count; // index of inView of a column in Carousel
        }
      }
    });
    return {
      inViewColumns: inViewColumns,
      indexClicked: indexClicked
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(32, changes);
    // if (typeof changes.cgToggleData.previousValue !== 'undefined') {
    //   const toggleData = changes.cgToggleData.currentValue;
    //   const data = toggleData.data, level = toggleData.level, displayLevel = toggleData.displayLevel;
    // }
    if (changes.gridData) {
      this.dataSourceFirstLevelList.dataSource.data = this.gridData.data;
    }
  }

  public showRefCol(colName) {
    const viewData = this.getCarouselColumnsInView(colName);
    this.gridData.columns.filter(obj => { // Hide the aggregate column
      if (colName === obj.name) {
        obj.refEnable = true;
        obj.inView = false;
        obj.collapsed = false;
      }
    });

    let count = 0; // to keep track of new columns available in the view
    this.gridData.columns.filter(col => { // Show individual columns
      if (colName === col.refLabel) {
        col.hidden = false;
        col.refEnable = true;
        col.inView = true;
        col.collapsed = false;
        count++;
        // this.startIndex = count === 1 ? this.startIndex++ : this.startIndex;

        // give remove icon for last column
        const individualColumns = this.gridData.columns.filter(obj => obj.refLabel === col.refLabel);
        individualColumns[individualColumns.length - 1].removeIcon = true;
      }
    });

    // Update the startIndex and endIndex and hide certain column which were inView earlier
    let lastInViewColumn = viewData.inViewColumns[viewData.inViewColumns.length - 1];
    let firstInViewColumn = viewData.inViewColumns[0];
    if (count === 1) { // A quarter has 1 month
      if (viewData.indexClicked === 1) { // 1st inView column is clicked
        this.startIndex += 1;
      } else if (viewData.indexClicked === this.totalColumnsVisibleAtATime) {
        this.endIndex += 1;
      }
    } else if (count === 2) { // A quarter has 2 months
      if (viewData.indexClicked === 1) { // 1st inView column is clicked
        lastInViewColumn.inView = false;
        lastInViewColumn.refEnable = lastInViewColumn.columnType === 'aggregate' ? true : false;
        this.startIndex += 1;
        this.endIndex = viewData.inViewColumns[viewData.inViewColumns.length - 2]['index']; // 2nd last inView column
      } else if (viewData.indexClicked > 1 && viewData.indexClicked <= viewData.inViewColumns.length - 1) {
        lastInViewColumn.inView = false;
        lastInViewColumn.refEnable = lastInViewColumn.columnType === 'aggregate' ? true : false;
        if (this.totalColumnsVisibleAtATime === 3) {
          this.endIndex = viewData.inViewColumns[1]['index'] += 2;
        } else if (viewData.indexClicked === this.totalColumnsVisibleAtATime - 1) { // second last column inView was clicked
          this.endIndex = viewData.inViewColumns[this.totalColumnsVisibleAtATime - 2]['index'] += 2;
        } else {
          this.endIndex = viewData.inViewColumns[viewData.inViewColumns.length - 2]['index'];
        }
      } else if (viewData.indexClicked === this.totalColumnsVisibleAtATime) {
        firstInViewColumn.inView = false;
        firstInViewColumn.refEnable = firstInViewColumn.columnType === 'aggregate' ? true : false;
        this.startIndex = viewData.inViewColumns[1]['index'];
        this.endIndex += 2;
      }
    } else if (count === 3) { // A quarter has 3 months
      const secondLastInViewColumn = viewData.inViewColumns[viewData.inViewColumns.length - 2];
      if (viewData.indexClicked === 1) { // 1st inView column is clicked
        lastInViewColumn.inView = false;
        lastInViewColumn.refEnable = lastInViewColumn.columnType === 'aggregate' ? true : false;
        secondLastInViewColumn.inView = false;
        secondLastInViewColumn.refEnable = lastInViewColumn.columnType === 'aggregate' ? true : false;
        this.startIndex += 1;
        if (this.totalColumnsVisibleAtATime === 3) {
          this.endIndex = this.startIndex + 2;
        } else {
          this.endIndex = viewData.inViewColumns[viewData.inViewColumns.length - 3]['index'];
        }
      } else if (viewData.indexClicked > 1 && viewData.indexClicked <= viewData.inViewColumns.length - 1) {
        if (this.totalColumnsVisibleAtATime === 3) { // hide the left-most column and right-most column
          firstInViewColumn.inView = false;
          firstInViewColumn.refEnable = firstInViewColumn.columnType === 'aggregate' ? true : false;
          lastInViewColumn.inView = false;
          lastInViewColumn.refEnable = lastInViewColumn.columnType === 'aggregate' ? true : false;
          this.startIndex = viewData.inViewColumns[1]['index'] + 1;
          this.endIndex = viewData.inViewColumns[1]['index'] + 3;
        } else if (this.totalColumnsVisibleAtATime - viewData.indexClicked > 1) {
          lastInViewColumn.inView = false;
          lastInViewColumn.refEnable = lastInViewColumn.columnType === 'aggregate' ? true : false;
          secondLastInViewColumn.inView = false;
          secondLastInViewColumn.refEnable = secondLastInViewColumn.columnType === 'aggregate' ? true : false;
          if (this.totalColumnsVisibleAtATime === 4) {
            this.endIndex = viewData.inViewColumns[1]['index'] + 3;
          } else {  // 3rd last inView Column
            this.endIndex = viewData.inViewColumns[viewData.inViewColumns.length - 3]['index'];
          }
        } else { // 2nd last column clicked
          firstInViewColumn.inView = false;
          firstInViewColumn.refEnable = firstInViewColumn.columnType === 'aggregate' ? true : false;
          lastInViewColumn.inView = false;
          lastInViewColumn.refEnable = lastInViewColumn.columnType === 'aggregate' ? true : false;
          this.startIndex = viewData.inViewColumns[1]['index'];
          this.endIndex = viewData.inViewColumns[viewData.inViewColumns.length - 2]['index'] + 3;
        }
      } else if (viewData.indexClicked === this.totalColumnsVisibleAtATime) {
        firstInViewColumn.inView = false;
        firstInViewColumn.refEnable = firstInViewColumn.columnType === 'aggregate' ? true : false;
        let secondInViewColumn = viewData.inViewColumns[1];
        secondInViewColumn.inView = false;
        secondInViewColumn.refEnable = secondInViewColumn.columnType === 'aggregate' ? true : false;
        if (this.totalColumnsVisibleAtATime === 3) {
          this.startIndex = viewData.inViewColumns[2]['index'] + 1;
        } else {
          this.startIndex = viewData.inViewColumns[2]['index'];
        }
        this.endIndex += 3;
      }
    }
    this.setCarouselArrows();
  }

  public hideRefCol(colName) {
    const viewData = this.getCarouselColumnsInView(colName);
    // find all the columns with col.refLabel
    let count = 0;
    let aggrIndex = -1
    this.gridData.columns.filter(col => {
      if (colName === col.label) {  // Hide individual columns
        const individualColumns = this.gridData.columns.filter(obj => obj.refLabel === col.refLabel);
        individualColumns.forEach(ic => {
          ic.hidden = true;
          ic.inView = false;
          ic.collapsed = true;
          count++;
        });
        aggrIndex = this.gridData.columns.findIndex(obj => obj.name === col.refLabel);//.refEnable = false; // Show aggregate column
        if (aggrIndex > -1) {
          this.gridData.columns[aggrIndex].refEnable = false;
          this.gridData.columns[aggrIndex].inView = true;
          this.gridData.columns[aggrIndex].collapsed = true;
        }
      }
    });

    // Update the startIndex and endIndex and show certain column which were hidden earlier
    let firstColumnViewIndex = viewData.inViewColumns[0]['index'] > aggrIndex ? aggrIndex : viewData.inViewColumns[0]['index'];
    const indexClicked = viewData.indexClicked;
    if (count === 1) { // quarter has 1 month
      if (indexClicked === 1) { // 1st inView column is clicked
        this.startIndex -= 1;
      } else if (indexClicked === this.totalColumnsVisibleAtATime) {
        this.endIndex -= 1;
      }
    } else if (count === 2) { // quarter has 2 months
      const startChildIndex = indexClicked - 1;
      if (startChildIndex <= 1) {
        this.startIndex -= 1;
      }
      this.getViewableColumnInViewFromRight(firstColumnViewIndex, viewData.inViewColumns[viewData.inViewColumns.length - 1]['index'], 1);
    } else if (count === 3) { // quarter has 3 months
      const startChildIndex = indexClicked - 2;
      if (startChildIndex <= 1) {
        this.startIndex -= 1;
      }
      this.getViewableColumnInViewFromRight(firstColumnViewIndex, viewData.inViewColumns[viewData.inViewColumns.length - 1]['index'], 2);
    }
    this.setCarouselArrows();
  }

  // * Below 2 functions are added specifically for Carousel Navigation
  getViewableColumnInViewFromLeft(backtrackCount, index) {
    // Look for collapsed attribute and columnType combination
    if (index > 0) {
      index--;
    }
    let col = this.gridData.columns[index];
    if (col.columnType === 'aggregate' && col.collapsed === true) {
      backtrackCount--;
      this.bringAggregateColumnInView(col);
    } else {
      if (col.columnType === 'individual' && col.collapsed === false) {
        backtrackCount--;
        this.bringIndividualColumnInView(col);
      }
    }
    if (backtrackCount !== 0) {
      this.getViewableColumnInViewFromLeft(backtrackCount, index);
    } else {
      this.startIndex = index;
      return;
    }
  }

  getViewableColumnInViewFromRight(firstColumnViewIndex, index, goForwardCount) {
    // Look for collapsed attribute and columnType combination
    if (index < this.totalNumberOfColumns - 1) {
      index++;
    }
    let col = this.gridData.columns[index];
    if (col.columnType === 'aggregate' && col.collapsed === true) {
      goForwardCount--;
      this.bringAggregateColumnInView(col);
      this.endIndex = index;
    } else {
      if (col.columnType === 'individual' && col.collapsed === false) {
        goForwardCount--;
        this.bringIndividualColumnInView(col);
        this.endIndex = index;
      }
    }
    if (goForwardCount === 0) {
      this.endIndex = index;
      return;
    } else {
      if (index < this.totalNumberOfColumns - 1) {
        this.getViewableColumnInViewFromRight(firstColumnViewIndex, index, goForwardCount);
      } else {
        this.getViewableColumnInViewFromLeft(goForwardCount, firstColumnViewIndex);
        return;
      }
    }
  }

  getColumnsIntoView(backtrackCount, index) {
    // Look for collapsed attribute and columnType combination
    if (index > 0) {
      index--;
    } else {
      index = this.totalNumberOfColumns - 1;
    }
    let col = this.gridData.columns[index];
    if (col.columnType === 'aggregate') {
      backtrackCount--;
      this.bringAggregateColumnInView(col);
    } else {
      if (col.columnType === 'individual' && col.collapsed === false) {
        backtrackCount--;
        this.bringIndividualColumnInView(col);
      }
    }
    if (backtrackCount !== 0) {
      this.getColumnsIntoView(backtrackCount, index);
    } else {
      this.startIndex = index;
      return;
    }
  }

  getColumnsIntoViewOnRightNavigation(index) {
    // Look for collapsed attribute and columnType combination
    let broughtRightColumnToView = false;
    if (index < this.totalNumberOfColumns - 1) {
      index++;
    } else {
      index = 0;
    }
    let col = this.gridData.columns[index];
    if (col.columnType === 'aggregate') {
      broughtRightColumnToView = true;
      this.bringAggregateColumnInView(col);
    } else {
      if (col.collapsed === false) {
        broughtRightColumnToView = true;
        this.bringIndividualColumnInView(col);
      }
    }
    if (broughtRightColumnToView) {
      this.endIndex = index;
      return;
    } else {
      this.getColumnsIntoViewOnRightNavigation(index);
    }
  }

  bringAggregateColumnInView(col) {
    col.refEnable = false;
    col.inView = true;
    col.collapsed = true;
  }

  bringIndividualColumnInView(col) {
    col.hidden = false;
    col.inView = true;
    col.refEnable = true;
  }

  hideColumn(column) {
    column.inView = false;
    if (column.columnType === 'aggregate') {
      column.refEnable = true;
    } else {
      column.hidden = true;
    }
  }

  highlightCell(gridDataType, fourth, column) {
    return gridDataType === 'Accrual Drops' && fourth[`${column} isHold`] === 'Y' && !this.editable ? true : false;
  }

  checkHoldProperty(fourth,column)
  {
    let quarterCheckBox= false;
    let isAllmonthHold = true;
    if(column.indexOf('Q') >=0 )
    {
      for (let key of Object.keys(fourth))
      {
        if(key.indexOf("isHold") >=0)
        {
         if(fourth[key] !== 'Y')
         {
          isAllmonthHold = false;
          break;
         }
        }
      }
      quarterCheckBox = isAllmonthHold;
    }
    else
    {
      column = column.replace("accru","");
      column = column.replace("accru","");
      quarterCheckBox= fourth[`${column} isHold`] === 'Y' ? true: false;
    }
    return quarterCheckBox;
  }

  holdFromDropping(third, fourth, column, amount, $event) {
    const ioDetailId = fourth[`${column.replace('accru', '')} ioDetailId`];
    this.emitAccrualDropHoldUpdate.emit({ ioDetailId: ioDetailId, checked: $event.checked, amount: amount, third, fourth, column });
  }

  // MAFN-382, grand total displayed in footer row
  getTotal(name) {
    return this.dataSourceFirstLevelList.dataSource.filteredData.map(t => t[name]? parseInt(this.replaceAll(formatNumber(t[name],this.locale,'1.0-0'),',','')): 0).reduce((acc, value) => acc + value, 0);
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
}
