<div class="edit-table-container">
    <form class="popupForm media-form" [formGroup]="editForm">
      <ng-container [formArrayName]="getFormArrayName()">
        <mat-table [dataSource]="dataSourceObj"  matSort matSortDirection="desc" matSortDisableClear>
            <ng-container *ngFor="let header of gridColData;let colIndex= index" matColumnDef="{{header.name}}">
                <mat-header-cell *matHeaderCellDef fxFlex="{{header.width}}">
                    <span class="matheaderLabel" [ngClass]="{'filterSpacing': filterEnabled}"
                        mat-sort-header [disabled]="!header.sort">
                        {{header.label}}</span>
                    <div class="filterRowContainer media-form" *ngIf="filterEnabled" (click)="resetFilteredOptions(colIndex);$event.stopPropagation()">
                        <div class="filterRowChildContainer" *ngIf="header.filterable">
                          <!-- <ng-container *ngIf="(filterSelectObj[colIndex].filterType && filterSelectObj[colIndex].filterType ==='daterange');else genericFilter">
                            <mat-form-field class="dateRangeFilter" appearance="fill" fxFlex="38">
                              <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate #dateStart readonly>
                                <input matEndDate #dateEnd readonly (dateChange)="dateRangeFilterChange(dateStart.value,dateEnd.value,colIndex,$event)">
                              </mat-date-range-input>
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                            <mat-icon class="clearDateRange" (click)="clearDateRangeFilter(colIndex, dateStart, dateEnd)" fxFlex="20">close</mat-icon>
                          </ng-container> -->
                          <!-- <ng-template #genericFilter> -->
                            <div class="filterRowCell" [matMenuTriggerFor]="filterMenu" [matMenuTriggerData]="{index: colIndex}">
                              <label class="selectedfiltVal">
                                  <ng-container *ngIf="((filterValues[filterSelectObj[colIndex].displayField] && filterValues[filterSelectObj[colIndex].displayField].length>1) || !filterValues[filterSelectObj[colIndex].displayField]);else selectedFilter">
                                    {{filterValues[filterSelectObj[colIndex].displayField] && filterValues[filterSelectObj[colIndex].displayField].length? 'Multiple': 'Select'}}
                                  </ng-container>
                                  <ng-template #selectedFilter>
                                    <ng-container [ngSwitch]="filterSelectObj[colIndex].displayFieldType">
                                        <ng-container *ngSwitchCase="'date'">{{getFilterDisplayDate(filterValues[filterSelectObj[colIndex].displayField][0]) | date : "MM/dd/y"}}</ng-container>
                                        <ng-container *ngSwitchCase="'active'">{{filterValues[filterSelectObj[colIndex].displayField][0] ==='true' ?'Active': 'Inactive'}}</ng-container>
                                        <ng-container *ngSwitchDefault>
                                            {{filterValues[filterSelectObj[colIndex].displayField][0]}}
                                        </ng-container>
                                    </ng-container>
                                  </ng-template>
                                </label>
                              <mat-icon class="filterDownArrow" [ngClass]="{'hasSelection':filterValues[filterSelectObj[colIndex].displayField]}">arrow_drop_down</mat-icon>
                            </div>
                          <!-- </ng-template> -->
                        </div>
                        </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let data; let i = index" [formGroup]="data" fxFlex="{{header.width}}" [ngSwitch]="header.type"
                [ngClass]="{'cell-height':config === 'users' && data.value.edittableMode}">
                    <ng-container *ngIf="!data.value.edittableMode; else editTemplate">
                        <span *ngIf="header.name === 'rowEdit'">
                            <button mat-icon-button *ngIf="!noMenu && hasEditAccess" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{data: data}"><mat-icon>more_vert</mat-icon></button>
                            <button mat-icon-button *ngIf="hasEditAccess" (click)="editRow('edit',data)" [disabled]="disableEdit"><mat-icon>edit</mat-icon></button>
                        </span>
                        <ng-container *ngIf="header.name !== 'rowEdit'">
                            <span [ngSwitch]="header.name">
                                <span *ngSwitchCase="'titleTypeId'">{{data.value['titleType']}}</span>
                                <span *ngSwitchCase="'mediaTypeId'">{{data.value['mediaTypeName']}}</span>
                                <span *ngSwitchCase="'lastUpdatedDate'">{{data.value['lastUpdatedDate']}}</span>
                                <span *ngSwitchCase="'genreNo'">{{data.value['genreDesc']}}</span>
                                <span *ngSwitchCase="'seasonTypeId'">{{data.value['seasonType']}}</span>
                                <span *ngSwitchCase="'seriesNo'">{{data.value['seriesName']}}</span>
                                <span *ngSwitchCase="'endDate'">{{data.value['endDate'] | date : "MM/dd/y"}}</span>
                                <span *ngSwitchCase="'startDate'">{{data.value['startDate'] | date : "MM/dd/y"}}</span>
                                <span *ngSwitchCase="'inActiveDate'">{{data.value['inActiveDate'] | date : "MM/dd/y"}}</span>
                                <span *ngSwitchCase="'dateAdded'">{{data.value['dateAdded'] | date : "MM/dd/y"}}</span>
                                <span *ngSwitchCase="'year'">{{data.value['year']}}</span>
                                <span *ngSwitchCase="'percentage'">{{data.value['percentage']}}%</span>
                                <span *ngSwitchCase="'moduleName'">{{data.value['moduleName']}}</span>
                                <span *ngSwitchCase="'roles'">
                                    <span *ngFor="let role of data.value['userRoles']; let i = index">{{role.roleDesc}}{{i === data.value['userRoles'].length - 1 ? '' : ', '}}</span>
                                </span>
                                <span *ngSwitchCase="'active'" class="custom-switches"><ui-switch uncheckedLabel="Inactive" checkedLabel="Active" [disabled]="true" size="small" [checked]="data.value[header.name]"></ui-switch></span>
                                <span *ngSwitchCase="'incomeTypeId'">{{data.value['incomeType']}}</span>
                                <span *ngSwitchDefault>{{data.value[header.name]}}</span>
                            </span>
                        </ng-container>
                    </ng-container>
                    <ng-template #editTemplate>
                        <span *ngSwitchCase="'no-edit'">
                            <span *ngIf="header.name === 'rowEdit'">
                                <button mat-icon-button (click)="editRow('cancel',data)"><mat-icon>close</mat-icon></button>
                                <button mat-icon-button [ngClass]="{'cursor-auto': disableCheck}" (click)="!disableCheck ? editRow('update',data) : false"><mat-icon [ngClass]= "{'icon-blue' : !disableCheck}">check</mat-icon></button>
                            </span>
                            <span *ngIf="header.name !== 'rowEdit'">{{
                                header.readOnlyDate? (data.value[header.name] | date : "MM/dd/y"): data.value[header.name]}}</span>
                        </span>
                        <span *ngSwitchCase="'text'">
                            <ng-container [ngSwitch]="header.name">
                                <ng-container *ngSwitchCase="'userIdNo'">
                                    <span *ngIf="!data.value.addNew">{{data.value[header.name]}}</span>
                                    <mat-form-field appearance="outline" *ngIf="data.value.addNew">
                                        <input matInput type="number" appValidationFormat="num9" (focusout)="onFocusOut($event)" formControlName="{{header.name}}" value="{{data.value[header.name]}}" required autocomplete="off">
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'genreCode'">
                                    <mat-form-field appearance="outline">
                                        <input matInput type="text" appValidationFormat="alphaNumMax10" formControlName="{{header.name}}" value="{{data.value[header.name]}}">
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'glAccountNo'">
                                    <mat-form-field appearance="outline">
                                        <input matInput type="number" appValidationFormat="num10" formControlName="{{header.name}}" value="{{data.value[header.name]}}">
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'genreDesc'">
                                    <mat-form-field appearance="outline">
                                        <input matInput type="text" formControlName="{{header.name}}" value="{{data.value[header.name]}}" required>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'snapVendorNo'">
                                    <mat-form-field appearance="outline">
                                      <input matInput type="number" formControlName="{{header.name}}" value="{{data.value[header.name]}}" autocomplete="off">
                                  </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'postal'">
                                      <mat-form-field appearance="outline">
                                      <input matInput type="number" appValidationFormat="num5" formControlName="{{header.name}}" maxlength="5" value="{{data.value[header.name]}}">
                                    </mat-form-field>
                                      </ng-container>
                                      <ng-container *ngSwitchCase="'comments'">
                                        <mat-form-field appearance="outline">
                                        <input matInput type="text" maxlength="100" formControlName="{{header.name}}" value="{{data.value[header.name]}}">
                                      </mat-form-field>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'seasonNo'">
                                          <mat-form-field appearance="outline">
                                            <input matInput type="number" appValidationFormat="num4" formControlName="{{header.name}}" value="{{data.value[header.name]}}" [attr.required]="!(header.titleTypeId === 2)">
                                        </mat-form-field>
                                          </ng-container>
                                          <ng-container *ngSwitchCase="'showName'">
                                            <mat-form-field appearance="outline">
                                              <input matInput type="text" formControlName="{{header.name}}" value="{{data.value[header.name]}}">
                                          </mat-form-field>
                                          </ng-container>
                                    <ng-container *ngSwitchCase="'percentage'">
                                        <mat-form-field appearance="outline">
                                            <input matInput type="number" appValidationFormat="dec2" formControlName="{{header.name}}" value="{{data.value[header.name]}}" required autocomplete="off">
                                        </mat-form-field>
                                    </ng-container>
                                  <mat-form-field appearance="outline" *ngSwitchDefault>
                                      <input matInput type="text" formControlName="{{header.name}}" value="{{data.value[header.name]}}">
                                  </mat-form-field>
                            </ng-container>
                        </span>
                        <span *ngSwitchCase="'dropdown'">
                            <ng-container [ngSwitch]="header.name">
                                <ng-container *ngSwitchCase="'seriesNo'">
                                    <mat-form-field appearance="outline" fxFlex="70">
                                        <mat-select formControlName="{{header.name}}" disableOptionCentering [attr.required]="!(header.titleTypeId === 2)">
                                            <mat-option *ngFor="let option of masterData[header.control]" [value]="option.id">{{option.value}}</mat-option>
                                          </mat-select>
                                    </mat-form-field>
                                    <button mat-icon-button><mat-icon class="icon-blue" (click)="deleteField('seriesNo', data)">delete</mat-icon></button>
                                    <button mat-icon-button><mat-icon class="icon-blue" (click)="openDialog('seriesNo', data)">add</mat-icon></button>
                                </ng-container>
                                <ng-container *ngSwitchCase="'seasonTypeId'">
                                    <mat-form-field appearance="outline" fxFlex="70">
                                        <mat-select formControlName="{{header.name}}" disableOptionCentering required>
                                            <mat-option *ngFor="let option of masterData[header.control]" [value]="option.id">{{option.value}}</mat-option>
                                          </mat-select>
                                    </mat-form-field>
                                    <button mat-icon-button><mat-icon class="icon-blue" (click)="deleteField('seasonTypeId', data)">delete</mat-icon></button>
                                    <button mat-icon-button><mat-icon class="icon-blue" (click)="openDialog('seasonTypeId', data)">add</mat-icon></button>
                                </ng-container>
                                <ng-container *ngSwitchCase="'mediaTypeId'">
                                    <ng-container *ngIf="config === 'distribution'">
                                        <mat-form-field appearance="outline">
                                            <mat-select formControlName="{{header.name}}" (selectionChange)="onMediaSelect($event,data)" disableOptionCentering required>
                                                <mat-option *ngFor="let option of mediaTypeLov" [value]="option.mediaTypeId">{{option.mediaTypeName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-container *ngIf="config === 'media'">
                                        <mat-form-field appearance="outline"fxFlex="80">
                                            <mat-select formControlName="{{header.name}}" disableOptionCentering required>
                                                <mat-option *ngFor="let option of mediaList" [value]="option.mediaTypeId">{{option.mediaTypeName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button mat-icon-button><mat-icon class="icon-blue" (click)="openDialog('mediaTypeId', data)">add</mat-icon></button>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'moduleName'">
                                    <mat-form-field appearance="outline">
                                        <mat-select formControlName="{{header.name}}" disableOptionCentering required>
                                            <mat-option *ngFor="let option of userMasterData['modules']" [value]="option.value">{{option.value}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'incomeTypeId'">
                                    <mat-form-field appearance="outline" fxFlex="70">
                                        <mat-select formControlName="{{header.name}}">
                                            <mat-option *ngFor="let option of masterData[header.control]" [value]="option.id">{{option.value}}</mat-option>
                                          </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'location'">
                                  <mat-form-field appearance="outline" fxFlex="70">
                                      <mat-select formControlName="{{header.name}}" required>
                                          <mat-option *ngFor="let option of masterData[header.control]" [value]="option.id">{{option.value}}</mat-option>
                                        </mat-select>
                                  </mat-form-field>
                              </ng-container>
                              <ng-container *ngSwitchCase="'vendorType'">
                                <mat-form-field appearance="outline" fxFlex="70">
                                    <mat-select formControlName="{{header.name}}" required>
                                        <mat-option *ngFor="let option of masterData[header.control]" [value]="option.id">{{option.value}}</mat-option>
                                      </mat-select>
                                </mat-form-field>
                            </ng-container>
                                <mat-form-field appearance="outline" *ngSwitchDefault>
                                    <mat-select formControlName="{{header.name}}" disableOptionCentering>
                                        <mat-option *ngFor="let option of masterData[header.control]" [value]="option.id">{{option.value}}</mat-option>
                                      </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </span>
                        <!-- <span *ngSwitchCase="'checkbox'">
                                <div formArrayName="roles">
                                    <div *ngFor="let option of data.value['userRoles']; let rowIndex = index">
                                        <mat-checkbox (change)="onCheckBoxChanges($event, option.roleId, data)" [checked]="option.checked">{{option.roleDesc}}</mat-checkbox>
                                    </div>
                                </div>
                        </span>-->
                        <span *ngSwitchCase="'multi-select'">
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="roles" (selectionChange)="onMultiSelect($event, data)" multiple>
                                    <mat-option *ngFor="let option of data.value['userRolesTemp'];" [value]="option">{{option.roleDesc}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                        <span *ngSwitchCase="'date'">
                          <ng-container [ngSwitch]="header.name">
                            <ng-container *ngSwitchCase="'startDate'">
                              <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="picker" (ngModelChange)="onDataChange($event,data)" [max]="data.value.endDate" formControlName="{{header.name}}"
                                 required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'endDate'">
                              <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="picker" [min]="data.value.startDate" formControlName="{{header.name}}" required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                            </ng-container>
                            <mat-form-field appearance="outline" *ngSwitchDefault>
                                <input matInput [matDatepicker]="picker" formControlName="{{header.name}}">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                          </ng-container>
                        </span>
                        <span *ngSwitchCase="'toggleSwitch'" class="custom-switches">
                            <ui-switch uncheckedLabel="Inactive" *ngIf="(this.config ==='users' && !data.value.addNew) || this.config !=='users'" checkedLabel="Active" (valueChange)="onActiveChange(data, $event)" formControlName="active" size="small" [checked]="data.value['active']"></ui-switch>
                            <ui-switch uncheckedLabel="Inactive"  *ngIf="this.config==='users' && data.value.addNew" checkedLabel="Active" (valueChange)="onActiveChange(data, $event)" formControlName="active" size="small" [checked]="data.value['active']"></ui-switch>
                        </span>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let data; let i = index; columns: displayedColumns;"></mat-row>
        </mat-table>
    </ng-container>
    </form>
    <div class="no-records-wrapper" *ngIf="showNoMsg">
        <div class="no-records-found">
            <h4>{{gridData.noDataMsg}}</h4>
        </div>
    </div>
</div>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-data="data">
        <button mat-menu-item [disabled]="disableEdit" (click)="editRow('menu-edit',data)">Edit</button>
        <button mat-menu-item [disabled]="disableEdit" (click)="editRow('delete',data)">Delete</button>
    </ng-template>
</mat-menu>

<mat-menu #filterMenu="matMenu">
    <ng-template matMenuContent let-index="index">
        <input class="mat-menu-filter" matInput #filterInput placeholder="Search" autocomplete="off" (click)="$event.stopPropagation()"
            (keyup)="filterSelectItems(index,$event)"/>
        <mat-checkbox [checked]="item.checked" role="menuitemcheckbox" class="mat-menu-item-Override"
            (click)="filterChange(index,item,$event,filterInput)" *ngFor="let item of filterSelectObj[index].filteredOptions">
            <ng-container [ngSwitch]="filterSelectObj[index].displayFieldType">
                <ng-container *ngSwitchCase="'date'">{{getFilterDisplayDate(item.name) | date : "MM/dd/y"}}</ng-container>
                <ng-container *ngSwitchCase="'active'">{{item.name==='true' ?'Active': 'Inactive'}}</ng-container>
                <ng-container *ngSwitchDefault>
                    {{item.name}}
                </ng-container>
            </ng-container>
        </mat-checkbox>
    </ng-template>
</mat-menu>

<div class="no-records-wrapper" *ngIf="showNoMsg">
    <div class="no-records-found">
        <h4>{{gridData.noDataMsg}}</h4>
    </div>
</div>
