<div #notesWrapper notesSizing class="notesWrapper" [ngClass]="{ 'io-height': data?.notesHeight === 39 }">
    <span #notesDisplayFieldWrapper class="notes-displayField-wrapper" [ngClass]="{ 'hide': rowEdit }"
        [style.height.px]="data?.notesHeight ? data.notesHeight : 40" [style.width.%]="header.width">
        <span *ngIf="inlineSave" class="material-icons edit-icon"
            (click)="notesEditableMode($event, notesDisplayFieldWrapper, notesEditable);$event.stopPropagation();">edit</span>
        <span #notesOriginal class="notes-displayField"
            [style.height.px]="data?.notesHeight ? data.notesHeight - 8 : 25">{{data[header.name]}}</span>
    </span>
    <span #notesEditable class="notesEditable" [ngClass]="{ 'show': rowEdit }"
        [style.height.px]="data?.notesHeight ? data.notesHeight : 40" [style.width.%]="header.width">
        <span *ngIf="inlineSave" class="notes-edit-actions">
            <span class="material-icons cancel-icon"
                (click)="modifyNotes(data, 'removeCopy', notesDisplayFieldWrapper, notesEditable);$event.stopPropagation();">close</span>
            <span class="material-icons save-icon"
                (click)="modifyNotes(data, 'save', notesDisplayFieldWrapper, notesEditable);$event.stopPropagation();">check</span>
        </span>
        <textarea rows="3" matInput
            [style.height.px]="data?.notesHeight ? data.notesHeight - editIconHeight : defaultEditableHeight"
            [(ngModel)]="data[header.name]"
            (ngModelChange)="modifyNotes(data, 'modifyCopy', notesDisplayFieldWrapper, notesEditable, $event)"
            (focus)="focusNotesField($event);$event.stopPropagation();"
            (click)="$event.stopPropagation();">{{data[header.name]}}</textarea>
    </span>
</div>