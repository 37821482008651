<section *ngIf="popupDatas.isImport" class="import-estimates">
  <h1 mat-dialog-title class="row">{{popupDatas.header}}
    <button disableRipple mat-icon-button class="close-button float-right" (click)="onCancel()" tabindex="-1">
      <mat-icon disableRipple class="big-close">close</mat-icon>
    </button>
  </h1>
  <div>
    <app-upload-file [importType]="'estimates'" [fileType]="excel" [directSubmit]=true (importResponse)="handleResponse($event)"></app-upload-file>
  </div>
</section>
<div class="addEstimateContainer" *ngIf="!popupDatas.isImport">
  <form class="popupForm media-form" [formGroup]="tableForm">
    <div class="popupTitlediv">
      <h1 class="popuptitle" mat-dialog-title>{{popupDatas.header}}
        <button disableRipple mat-icon-button class="close-button float-right" (click)="onCancel()" tabindex="-1">
          <mat-icon disableRipple class="big-close">close</mat-icon>
        </button>
      </h1>
      <span *ngIf="popupId === 'import'">Importing will always create a new version</span>
    </div>
    <div class="popupBodydiv" fxlayout="row" fxlayout.xs="column" fxlayoutwrap="">
      <mat-table formArrayName="estimates" [dataSource]="dataSource">
        <ng-container matColumnDef="showId">
          <mat-header-cell *matHeaderCellDef fxFlex="60"> Show Name </mat-header-cell>
          <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="60">

            <mat-icon (click)="deleteShow(rowIndex)" class="close-icon">clear</mat-icon>
            <mat-form-field appearance="outline" fxFlex="80">
              <mat-select disableOptionCentering #showNameSelectElem [displaySelectboxAroundInput]="showNameSelectElem"
                panelClass="media-form-select-panel" formControlName="showId"
                (selectionChange)="onShowChange($event, rowIndex)" required>
                <mat-option *ngFor="let show of showList" [value]="show.showId">{{show.showName}}</mat-option>
              </mat-select>
            </mat-form-field>

            <ng-template #showErrorMessage>
              <div class="duplicateEstimate">{{tableForm.value.estimates[rowIndex].showNameError}}</div>
            </ng-template>
            <mat-icon *ngIf="tableForm.value.estimates[rowIndex].showNameError" class="error-icon" [tooltip]="showErrorMessage"
              content-type="template" placement="top" show-delay="100" hide-delay="500">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="year">
          <mat-header-cell *matHeaderCellDef fxFlex="14"> Year </mat-header-cell>
          <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="14">
            <span *ngIf="popupId !== 'import'">{{row.year}}</span>
            <mat-form-field appearance="outline" fxFlex="80" *ngIf="popupId === 'import'">
              <mat-select disableOptionCentering #yearSelectElem
                [displaySelectboxAroundInput]="yearSelectElem" panelClass="media-form-select-panel"
                formControlName="year" (selectionChange)="onYearChange($event, rowIndex)" required>
                <mat-option *ngFor="let year of yearList" [value]="year.id">
                  {{year.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-template #periodErrorMessage>
              <div class="duplicateEstimate">{{tableForm.value.estimates[rowIndex].estimatePeriodError}}</div>
            </ng-template>
            <mat-icon *ngIf="tableForm.value.estimates[rowIndex].estimatePeriodError" class="error-icon" [tooltip]="periodErrorMessage"
              content-type="template" placement="top" show-delay="100" hide-delay="500">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="month">
          <mat-header-cell *matHeaderCellDef fxFlex="17"> Month </mat-header-cell>
          <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="17">
            <span *ngIf="popupId !== 'import'">{{getMonthName(row.month, row.year)}} </span>
            <mat-form-field appearance="outline" fxFlex="90" *ngIf="popupId === 'import'">
              <mat-select disableOptionCentering #monthSelectElem
                [displaySelectboxAroundInput]="monthSelectElem" panelClass="media-form-select-panel"
                formControlName="month" (selectionChange)="onMonthChange($event, rowIndex)" required>
                <mat-option *ngFor="let month of monthList" [value]="month.id">
                  {{month.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-template #periodErrorMessage>
              <div class="duplicateEstimate">{{tableForm.value.estimates[rowIndex].estimatePeriodError}}</div>
            </ng-template>
            <mat-icon *ngIf="tableForm.value.estimates[rowIndex].estimatePeriodError" class="error-icon" [tooltip]="periodErrorMessage"
              content-type="template" placement="top" show-delay="100" hide-delay="500">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="mediaTypeId">
          <mat-header-cell *matHeaderCellDef fxFlex="19"> Media Type </mat-header-cell>
          <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="19">
            <mat-form-field appearance="outline" fxFlex="80">
              <mat-select disableOptionCentering #mediaTypeSelectElem
                [displaySelectboxAroundInput]="mediaTypeSelectElem" panelClass="media-form-select-panel"
                formControlName="mediaTypeId" (selectionChange)="onMediaTypeChange($event, rowIndex)" required>
                <mat-option *ngFor="let mediaType of mediaTypeList" [value]="mediaType.mediaTypeId">
                  {{mediaType.mediaTypeDesc}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-template #errorMessage>
              <div class="duplicateEstimate">{{tableForm.value.estimates[rowIndex].message}}</div>
            </ng-template>
            <mat-icon #errorIcon *ngIf="tableForm.value.estimates[rowIndex].message" class="error-icon" [tooltip]="errorMessage"
              content-type="template" placement="top" show-delay="100" hide-delay="500">warning
            </mat-icon>

            <!-- <mat-icon #errorIcon class="error-icon hide" color="accent" mdePopoverTriggerOn="hover"
              [mdePopoverTriggerFor]="appPopover">warning</mat-icon>
            <app-pop-over-tool-tip #parentcomp [popOverDetails]="toolTipData">
              <mat-icon color="accent" #popoverTrigger [appMatPopoverTooltip]="parentcomp.childId"
                *ngIf="showTooltipIcon" [mdePopoverTriggerFor]="parentcomp.childId" mdePopoverTriggerOn="hover"
                #popoverTrigger="mdePopoverTrigger">warning</mat-icon>
            </app-pop-over-tool-tip> -->
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="estimate">
          <mat-header-cell *matHeaderCellDef fxFlex="20">Estimate </mat-header-cell>
          <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="20">
            <mat-form-field appearance="outline" fxFlex="65">
              <input matInput type="text" (input)="checkError($event, rowIndex)" appValidationFormat="dec2" size="10" formControlName="estimate" class="estimate-amount" required autocomplete="off">
            </mat-form-field>
            <ng-template #amountErrorMessage>
              <div class="duplicateEstimate">{{tableForm.value.estimates[rowIndex].estimateAmountError}}</div>
              <!-- <div class="duplicateEstimate">Amount value is incorrect</div> -->
            </ng-template>
            <mat-icon *ngIf="tableForm.value.estimates[rowIndex].estimateAmountError" class="error-icon" [tooltip]="amountErrorMessage"
              content-type="template" placement="top" show-delay="100" hide-delay="500">warning
            </mat-icon>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <button class="addBtn secondary-small-btn" mat-button (click)="addNewRow()">ADD ROW</button>
    <button mat-button [ngClass]="{'disabled-save-btn': tableForm.invalid || tableForm.value.estimates.length === 0}" *ngIf="this.popupId === 'add'" (click)="addEstimates()" [disabled]="tableForm.invalid || tableForm.value.estimates.length === 0" class="primary-small-btn float-right">ADD</button>
    <button mat-button [ngClass]="{'disabled-save-btn': tableForm.invalid || tableForm.value.estimates.length === 0}" *ngIf="this.popupId === 'import'" (click)="saveEstimates()" [disabled]="tableForm.invalid || tableForm.value.estimates.length === 0" class="primary-small-btn float-right">SAVE</button>
    <button class="cancelBtn float-right" mat-button (click)="onCancel()">CANCEL</button>
  </form>
</div>
<mat-spinner *ngIf="loadingIcon" class="loader-icon"></mat-spinner>
