<span class="row-modify-wrapper">
    <span #rowEdit *ngIf="!data.editMode" class="row-edit float-left material-icons edit-icon"
        (click)="rowModifyChange(data, $event, 'edit');$event.stopPropagation();">edit</span>
    <span #rowEditMode class="row-edit-mode float-left" *ngIf="data.editMode">
        <span class="material-icons cancel-icon"
            (click)="rowModifyChange(data, $event, 'removeCopy');$event.stopPropagation();">close</span>
        <span *ngIf="data.saveDisabled === false" class="material-icons save-icon"
            (click)="rowModifyChange(data, $event, 'save');$event.stopPropagation();">check</span>
        <mat-icon *ngIf="data.saveDisabled === true" class="warning-icon" [tooltip]="validations"
            content-type="template" placement="top" show-delay="100" hide-delay="100">warning</mat-icon>
    </span>
</span>
<ng-template #validations>
    <div class="validations" *ngFor="let validation of data.validations">
        <div>{{validation}}</div>
    </div>
</ng-template>