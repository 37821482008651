<div class="monthPickerContainer" [ngClass]="{'raisedMonthPicker': raisedMonthPicker}">
    <button mat-icon-button class="arrow-left-right left-nav-arrow" [ngClass]="{'raisedMonthPicker': raisedMonthPicker}"
        (click)="shiftMonthQuarter('prev',-1)">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="month-header" [ngClass]="{'raisedMonthPicker': raisedMonthPicker}">
        <div class="monthPicker" *ngIf="enablePicker">
            <app-multi-month-picker [oneMonthOnly]="oneMonthOnly" [selectedData]="monthYearSelected"
                [createdBy]="createdBy" (monthRangeSelected)="triggerCall($event)">
            </app-multi-month-picker>
        </div>
    </div>
    <button mat-icon-button class="arrow-left-right arrow-right right-nav-arrow"
        [ngClass]="{'raisedMonthPicker': raisedMonthPicker}" (click)="shiftMonthQuarter('next',1)">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>