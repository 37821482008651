import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { IMonthPickerDetail, IMonthPickerDisplay } from '../../../models/multi-month-picker.model';
import { Store } from '@ngxs/store';
import { SetSelectedMultiMonth } from '../../../actions/estimates.action';

@Component({
  selector: 'app-multi-month-picker',
  templateUrl: 'app-multi-month-picker.component.html',
  styleUrls: ['app-multi-month-picker.component.scss']
})
export class AppMultiMonthPickerComponent implements OnInit {

  @Output() monthRangeSelected = new EventEmitter<any>();
  @Input() selectedData: IMonthPickerDetail;
  @Input() oneMonthOnly?: boolean;
  @Input() createdBy?: string = '';
  currentYearIndex: number;
  selectedDisplay: string;
  years: Array<number>;
  months: Array<string>;
  selectedYear: number;
  selectedQuarter: number;
  selectedMonth: number;
  showMonthPicker = false;
  monthsData: Array<IMonthPickerDisplay>;
  multiMonthText = 'Estimates and IOs for ';
  rangeIndexes: Array<number>;
  globalIndexOffset: number;
  isMonthView: boolean;
  quarterList: any[] = [
    { id: 1, description: 'Q1', months: ['01', '02', '03'] },
    { id: 2, description: 'Q2', months: ['04', '05', '06'] },
    { id: 3, description: 'Q3', months: ['07', '08', '09'] },
    { id: 4, description: 'Q4', months: ['10', '11', '12'] }
  ];

  constructor(private store: Store) { }

  onClick(indexClicked) {
    if (this.rangeIndexes[0] === indexClicked) {
      if (this.rangeIndexes[1] !== null) {
        indexClicked = this.rangeIndexes[1];
        this.initRangeIndexes();
      }
      else{
        this.initRangeIndexes();
        this.initMonthsData();
        return;
      }
    }

    if (this.rangeIndexes[1] === indexClicked) {
      indexClicked = this.rangeIndexes[0];
      this.initRangeIndexes();
      this.initMonthsData();
    }

    if (this.rangeIndexes[0] === null ||
      (this.rangeIndexes[0] !== null &&
        ((this.monthsData[this.rangeIndexes[0] - 1].quarter !== this.monthsData[indexClicked - 1].quarter)) ||
        (Math.abs(this.rangeIndexes[0] - indexClicked) > 1))){
      this.initRangeIndexes();
      this.initMonthsData();
      this.rangeIndexes[0] = indexClicked;
      this.highlightQuarter(0);
    } else
      if (this.rangeIndexes[1] === null) {
        if (indexClicked !== this.rangeIndexes[0]) {
          if (this.oneMonthOnly) {
            this.rangeIndexes[0] = indexClicked;
            this.highlightQuarter(1);
          } else {
            this.rangeIndexes[1] = indexClicked;
            this.highlightQuarter(1);
            this.rangeIndexes.sort((a, b) => a - b);
          }
        }
      } else {
        this.initRangeIndexes();
        this.initMonthsData();
        this.onClick(indexClicked);
      }
  }


  onQuarterClick(selectedIndex) {
    this.selectedQuarter = selectedIndex;
    this.emitSelectedData();
  }


  // For highlighting the quarter of the selected month
  highlightQuarter(rangeindex) {
    if (this.rangeIndexes[rangeindex] != null) {
      let quarterMonth = 0;
      this.monthsData.forEach((month, index) => {
        if (month.quarter === this.monthsData[this.rangeIndexes[rangeindex] - 1].quarter) {
          month.isInRange = true;
          if (quarterMonth === 0) {
            month.isLowerEdge = true;
          }
          else if (quarterMonth === 1) {
            month.isMiddle = true;
          }
          else {
            month.isUpperEdge = true;
          }
          quarterMonth++;
        }
      });
    }
  }

  // Emit selcted data to the parent component
  emitSelectedData() {
    this.setSelectedData();

    this.store.dispatch(new SetSelectedMultiMonth(this.selectedData));
    this.monthRangeSelected.emit(this.selectedData);
    this.openClosePicker(false);
  }

  // initSelectedData() {
  //   this.selectedData = { year: null, firstMonth: null, secondMonth: null, quarter: null,isMonthViewSelected: this.isMonthView};
  // }

  setSelectedData() {
    this.selectedData.year = this.selectedYear;
    this.selectedData.firstMonth = this.rangeIndexes[0];
    this.selectedData.secondMonth = this.rangeIndexes[1];
    this.selectedData.quarter = this.selectedQuarter;
    const monthRange = [];
    if (this.isMonthView) {
      if (this.rangeIndexes[1] != null) {
        monthRange.push(('0' + this.rangeIndexes[0]).slice(-2) + '-' + this.selectedYear);
        monthRange.push(('0' + this.rangeIndexes[1]).slice(-2) + '-' + this.selectedYear);
        this.selectedData.selectedDisplay = this.multiMonthText +
        new Date(this.selectedYear, this.selectedData.firstMonth - 1, 2).toLocaleDateString(undefined, { month: 'long' })  + ' - ' +
        new Date(this.selectedYear, this.selectedData.secondMonth - 1, 2).
        toLocaleDateString(undefined, { month: 'long' }) + ' ' + this.selectedYear;
      }
      else {
        monthRange.push(('0' + this.rangeIndexes[0]).slice(-2) + '-' + this.selectedYear);
        this.selectedData.selectedDisplay = new Date(this.selectedYear, this.selectedData.firstMonth - 1, 2).
        toLocaleDateString(undefined, { month: 'long' }) + ' ' + this.selectedYear;
      }
      this.selectedData.monthRange = monthRange;
      // to sync the quarter picker with the month selection
      this.selectedData.quarter = this.monthsData[this.rangeIndexes[0] - 1].quarter;
    }
    else {
      // to sync month picker month  with quarter change
      if (this.rangeIndexes[0] !== null) {
        if (this.monthsData[this.rangeIndexes[0] - 1].quarter !== this.selectedQuarter) {
          this.rangeIndexes[0] = this.monthsData.find(obj => obj.quarter === this.selectedQuarter).monthNo;
          this.rangeIndexes[1] = null;
        }
      }

      this.selectedDisplay = this.quarterList[this.selectedQuarter - 1].description + ' ' + this.selectedYear;
      this.selectedData.monthRange = this.getMonthsForQuarter();
    }
  }

  getMonthsForQuarter() {
    const monthArray = [];
    this.quarterList[this.selectedQuarter - 1].months.filter(month => {
      monthArray.push(month + '-' + this.selectedYear);
    });
    return monthArray;
  }


  incrementYear() {
    this.years.splice(0, 1);
    this.years.push(this.years[this.years.length - 1] + 1);
  }

  decrementYear() {
    this.years.splice(this.years.length - 1, 1);
    this.years.unshift(this.years[0] - 1);
  }

  onYearClick(yearSelected) {
    this.selectedYear = yearSelected;
  }

  initRangeIndexes() {
    this.rangeIndexes = [null, null];
  }

  initMonthsData() {
    this.monthsData = new Array();
    this.months.forEach((month, index) => {
      this.monthsData.push({
        monthNo: index + 1,
        monthName: month,
        quarter: Math.floor(index / 3) + 1,
        isInRange: false,
        isLowerEdge: false,
        isMiddle: false,
        isUpperEdge: false
      });
    });
  }

  initYearLabels() {
    const currentYear = this.selectedData.year;
    this.selectedYear = currentYear;
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    this.years = range(currentYear - 2, currentYear + 1, 1);
  }

  initMonthLabels() {
    this.months = new Array(12).fill(0).map((_, i) => {
      return new Date(this.selectedYear, i, 2).toLocaleDateString(undefined, { month: 'short' }) + '.';
    });
  }


  ngOnInit() {
    this.initYearLabels();
    this.initMonthLabels();
    this.initMonthsData();
    this.initRangeIndexes();
    this.setSelection();
    this.currentYearIndex = this.years.findIndex(year => year === (new Date()).getFullYear());
  }

  setSelection() {
    this.isMonthView = this.selectedData.isMonthViewSelected;
    this.selectedYear = this.selectedData.year;
    this.rangeIndexes[0] = this.selectedData.firstMonth;
    this.rangeIndexes[1] = this.selectedData.secondMonth;
    this.selectedQuarter = this.selectedData.quarter !== null ? this.selectedData.quarter : this.findQuarter();
    this.selectedDisplay = (!this.isMonthView) ?
      this.quarterList[this.selectedQuarter - 1].description + ' ' + this.selectedData.year :
      ((this.rangeIndexes[1] != null) ?
        this.selectedData.selectedDisplay = this.multiMonthText +
        new Date(this.selectedYear, this.selectedData.firstMonth - 1, 2).toLocaleDateString(undefined, { month: 'long' }) + ' - ' +
        new Date(this.selectedYear, this.selectedData.secondMonth - 1, 2).
        toLocaleDateString(undefined, { month: 'long' }) + ' ' + this.selectedYear :
        new Date(this.selectedYear, this.selectedData.firstMonth - 1, 2).
        toLocaleDateString(undefined, { month: 'long' }) + ' ' + this.selectedYear);
  }

  findQuarter() {
    const today = new Date();
    const quarter = Math.floor(today.getMonth() / 3) + 1;
    return quarter;
  }

  openClosePicker(isOpen) {
    if (isOpen)
    {
      this.initYearLabels();
    }
    if (this.isMonthView) {
      this.initRangeIndexes();
      this.initMonthsData();
      this.setSelection();
      this.highlightQuarter(0);
      this.highlightQuarter(1);
    }
    this.showMonthPicker = !this.showMonthPicker;
  }

}
