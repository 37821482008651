import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[displaySelectboxAroundInput]'
})
export class DisplaySelectboxAroundInputDirective implements AfterViewInit {

  @Input('displaySelectboxAroundInput') selectboxElem;

  constructor(private _el: ElementRef) { }

  ngAfterViewInit() {
    this.selectboxElem.overlayDir.positions[0].originY = "bottom";
    this.selectboxElem.overlayDir.positions[1].originY = "top";
  }

}
