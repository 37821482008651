import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
@Injectable()
export class AppUtilityService {
    constructor(private snackBar: MatSnackBar) {
        /** Nothing to do */
    }

    public deepClone(clone): any {
        return JSON.parse(JSON.stringify(clone));
    }

    public toastMessage(message: string, action: string) {
        this.snackBar.open(message, action, {
          duration: 2000,
          panelClass: ['white-bg'],
          verticalPosition: 'top'
        });
    }

    formatDatetoUSAString(date) {
        return typeof date === 'string' ? date : ((date.getMonth() > 8) ?
            (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ?
                date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
    }
}
