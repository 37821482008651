import { Component, OnInit , Input, OnChanges} from '@angular/core';
import { Chart } from 'angular-highcharts';
import { IDonutData } from 'src/app/models/donut.model';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit , OnChanges{
  @Input() chartData: IDonutData;
  columnChart;
  donutChart;
  initDonut() {
    const donut = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}</b>'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          size: '95%',
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            overflow: 'allow',
            crop: false,
            distance: -28,
            style: {
              color: '#FFFFFF',
              fontSize: '15px',
              fontFamily: "'OpenSansBold'",
              fontWeight: 'bold',
              textAlign: 'center',
              textOutline: 'none'
            },
            format: '{point.percentage:.0f}'
          },
          startAngle: -90,
          endAngle: -180,
          center: [150, 150] // ['50%', '50%'],
          // , innerSize: '70%'
          , showInLegend: true,
          point: {
            events: {
             legendItemClick(){
              return false;
             }
            }
           }
        }
      },
      series: [ ]
    });
    this.donutChart = donut;
    if (this.chartData){
      this.donutChart.options.series.push(this.chartData.donutSeries);
      this.donutChart.options.title = {
        text: '<strong>' + this.chartData.totalText + '</strong>'
        , verticalAlign: 'middle'
        , align: 'center'
        , x: -120
      };
      // this.donutChart.options.series.innerSize = '70%';
      this.donutChart.options.legend = {
        layout: 'vertical',
        verticalAlign: 'middle',
        align: 'right',
        itemMarginBottom: 15,
        itemStyle: {
                 font: '9pt Open Sans',
                 color: '#505D6F',
                 lineHeight: '20px'
              }
      };
    }
  }

  ngOnInit() {
    // this.initColumn();
    this.initDonut();
  }

  ngOnChanges(){
    this.initDonut();
  }
}
