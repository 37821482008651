import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource, MatTableModule, MatTable } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, Form } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { IAdminDetail, IUserDetail, IMGenreDetail, IMediaTypeDetail, IDistributionDetail } from 'src/app/models/admin.model';
import { AdminService } from 'src/app/services/admin-service';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { AppUtilityService } from 'src/app/services/utility.service';
import { CommonGridService } from '../common-grid/common-grid.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-table-component',
  templateUrl: './edit-table-component.component.html',
  styleUrls: ['./edit-table-component.component.scss']
})
export class EditTableComponentComponent implements OnInit, OnChanges {
  public editForm: FormGroup;
  @Input() edittedRow: IAdminDetail;
  @Input() dropdownMasterData = [];
  @Input() public gridData;
  @Input() public config;
  @Input() public userIdData;
  @Input() public mediaList;
  @Input() public mediaTypeLov;
  @Input() public noMenu = false;
  @Input() public showLov;
  @Input() public hasEditAccess = false;
  @Output() updatedRow: EventEmitter<any> = new EventEmitter();
  @Output() enableNew: EventEmitter<any> = new EventEmitter();
  @Output() userId: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public showNoMsg = false;
  public disableCheck = false;
  dataSource: MatTableDataSource<any>;
  dataSourceObj = new MatTableDataSource<any>([]);
  configIdObject ={
    show : 'showId'
    ,genre: 'genreNo'
    ,media: 'mediaTypeId'
    ,users: 'userNo'
    ,distribution: 'estimateDistributionId'
    ,vendors: 'vendorId'
    }
  @Output() showEdit: EventEmitter<any> = new EventEmitter();
  @Output() public emitShowFilter: EventEmitter<boolean> = new EventEmitter();
  public edittableMode;
  private MenuRow; private MenuRowIndex; private actionType;
  private series; private seasonType;
  public selectedRow;
  public selectedIndex;
  public displayedColumns: string[] = [];
  public gridColData;
  public disableEdit = false;
  public rolesData = []; public addRoleList = []; public deleteRoleList = [];
  public years; private titleList; private genreList; private seasonList; private seriesList;
  public moduleList; private ssoList = [];
  loggedinUser; public checkValidity = false;
  private incomeTypeList;
  public masterData;
  @Input() public userMasterData;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  filterAnchor = '';
  filterEnabled = false;
  filterValues = {};
  filterSelectObj = [];
  menuItems =  [];
  amountHeaders = [];
  constructor(private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private uS: AppUtilityService,
              public adminService: AdminService,
              public cS: CommonService,
              private cGS: CommonGridService) {
   }

  ngOnInit(): void {
    this.config = this.config ? this.config : 'users';
    this.loggedinUser = this.cS.getLoggedInSSO();
    this.dataSource = new MatTableDataSource();
    this.editForm = this.formBuilder.group({
      configTable: this.formBuilder.array([]),
      genreTable: this.formBuilder.array([]),
      userTable: this.formBuilder.array([]),
      mediaTable: this.formBuilder.array([]),
      vendorTable: this.formBuilder.array([]),
      distributionTable: this.formBuilder.array([]),
    });
    this.getMasterData();
    this.hideFilter();
    this.dataSourceObj.data = [...this.formArrayObject.controls];
    // console.log('data', this.gridData);
    this.filterSelectObj = this.gridData.columns;
    this.showNoMsg = this.gridData.data.length === 0 ? true : false;
    this.adminService.addRow.subscribe(res => {if (res) {this.addNewRow(); }});
    this.adminService.enableFilter.subscribe(res => {
      if (res) {
        this.filterEnabled = true;
        this.filterBtnClk(true);
      }
      else{
        this.hideFilter();
      }
    });
    this.dataSourceObj.filterPredicate = this.createFilter();
    this.dataSourceObj.sort = this.sort;
    this.dataSourceObj.sortingDataAccessor = (item, property) =>{
      let field = this.getDisplayFieldName(property);
      let fieldName = property;
      if(field && field.displayField){
        fieldName = field.displayField;
      }
      
      if (property && item.value[fieldName] && field.displayFieldType && field.displayFieldType === 'date') {
        return new Date(item.value[fieldName]);
      }
      else if (property && item.value[fieldName] && typeof item.value[fieldName] === 'string'){
        return item.value[fieldName].toLowerCase();
      }
      else {
        return item.value[fieldName];
      }
    }
  }

  hideFilter(){
      this.resetFilters();
      this.filterEnabled = false;
      this.emitShowFilter.emit(false);
    // }
  }

  // reset filter dropdown filtered options
  resetFilteredOptions(index){
    const filter = this.filterSelectObj[index];
    if (filter.options){
      if (filter.options.length > 0){
        filter.filteredOptions = filter.options.slice();
      }
      else{
        filter.filteredOptions = [];
      }
    }
  }

  getFilterDisplayDate(dateString){
    return new Date(dateString);
  }

  // Called on Filter change
  filterChange(index, item, event,filterInput?) {
    const that = this;
    let filter = this.filterSelectObj[index];
    if (item && !filter.filterType){
      item.checked = !item.checked;
      if (!item.name && item.name !== 0 && item.name!== false ) { item.name = ''; }
    }

    if (item && item.checked) {
      filter.optionselected = true;
      if (!this.filterValues[filter.displayField]) { this.filterValues[filter.displayField] = []; }
      // if(filter.filterType && filter.filterType ==='daterange'){
      //   this.filterValues[filter.name] = [];
      // }
      // else{

        this.filterValues[filter.displayField].push(item.name.toString().trim());
        if (this.filterAnchor && this.filterAnchor == '') { this.filterAnchor = filter.displayField; }
        else if (!this.filterAnchor) { this.filterAnchor = filter.displayField; }
      //}
      this.dataSourceObj.filter = JSON.stringify(this.filterValues);
    }
    else {
        let removeIndex = this.filterValues[filter.displayField].indexOf(item.name.toString().trim());
        if (removeIndex > -1) {
          this.filterValues[filter.displayField].splice(removeIndex, 1);
        }

      let removeFilter = Object.keys(this.filterValues).every((key) => {
        return that.filterValues[key].length === 0;
      });
      if (that.filterValues[filter.displayField].length === 0) { filter.optionselected = false; }
      if (removeFilter) {
        // this.resetFilters();
        this.filterValues = {};
        this.filterAnchor = '';
        //this.clearAllFilters();
        this.dataSourceObj.filter = '';
        filter.optionselected = false;
      }
      else {
        for (const col in this.filterValues) {
          if (this.filterValues[col].length <= 0) {
            delete this.filterValues[col];
          }
        }
        if (!this.filterValues[this.filterAnchor])  { this.filterAnchor = Object.keys(this.filterValues)[0]; }
        this.dataSourceObj.filter = JSON.stringify(this.filterValues);
      }
    }
    this.filterSelectObj.filter((o) => {
      if (this.filterAnchor !== o.displayField && o.options && !o.filterType) { // &&  filter.name !== o.name
        o.options = o.options.filter(row => row.checked);
        let dataString = this.dataSourceObj.filteredData.length > 0 ?
          'filteredData' : 'data';
        this.getFilterOptionAfterFilter(o, this.dataSourceObj, dataString);
      }
      if(filterInput && filterInput.value && filter.displayField === o.displayField){
        o.filteredOptions = o.options.filter(obj => obj.name && obj.name.toString().toLowerCase().includes(filterInput.value.toLowerCase()));
        switch(filter.displayFieldType){
          case 'date':
            o.filteredOptions = o.options.filter(obj => obj.name && moment(new Date(obj.name)).format('MM/DD/YYYY').toLowerCase().includes(filterInput.value.toLowerCase()));
            break;
          case 'active':
            o.filteredOptions = o.options.filter(obj => (obj.name || obj.name == false) && 
              (obj.name === 'true'?'active':' inactive').toLowerCase().includes(filterInput.value.toLowerCase()));
            break;
          default: 
            o.filteredOptions = o.options.filter(obj => (obj.name || obj.name == 0) && obj.name.toString().toLowerCase().includes(filterInput.value.toLowerCase()));
            break;
        }

      }
    });
    if(!filter.filterType){
      event.stopPropagation();
      event.preventDefault();
    }
  }

  filterSelectItems(index, event) {
    event.stopPropagation();
    if (event.target.value) {
      const text = event.target.value;
      // tslint:disable-next-line:max-line-length
      switch(this.filterSelectObj[index].displayFieldType){
        case 'date':
          this.filterSelectObj[index].filteredOptions = this.filterSelectObj[index].options.filter(obj => obj.name && moment(new Date(obj.name)).format('MM/DD/YYYY').toLowerCase().includes(text.toLowerCase()));
          break;
        case 'active':
          this.filterSelectObj[index].filteredOptions = this.filterSelectObj[index].options.filter(obj => (obj.name || obj.name == false) && (obj.name === 'true'?'active':' inactive').toLowerCase().includes(text.toLowerCase()));
          break;
        default: 
          this.filterSelectObj[index].filteredOptions = this.filterSelectObj[index].options.filter(obj => (obj.name || obj.name == 0) && obj.name.toString().toLowerCase().includes(text.toLowerCase()));
          break;
      }  
    } else {
      this.filterSelectObj[index].filteredOptions = this.filterSelectObj[index].options.slice();
    }
  }

  isAmount(headerName, data) {
    if (this.amountHeaders.length > 0 && this.amountHeaders.indexOf(headerName) > -1 && (data || data === 0)) {
      return true;
    }
    else {
      return false;
    }
  }


  public getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj.value[key])) {
        uniqChk.push(obj.value[key]);
      }
      return obj;
    });
    return uniqChk;
}
public getFilterOptions(data, object) {
  this.getFilterObject(data, object.displayField).forEach(item => {
      if (item == null || item == undefined || item == '') {
          object.options.unshift({ name: item, checked: false });
      }
      else {
          const trimmedText = item.toString().trim();
        if(object.options.filter(row=>row.name== trimmedText).length<=0)
          object.options.push({ name: trimmedText, checked: false });
      }
  });
  return object;
}

  // on click of filter button
  filterBtnClk(showFilter) {
    this.filterEnabled = showFilter;
    if (this.filterEnabled) {
          let checkFirst = true;
          this.filterSelectObj.filter((o) => {
            if(o.options){
            o.options = [];
  
            o = this.getFilterOptionAfterFilter(o, this.dataSourceObj, 'data');
          }
          });
      }
      if (!this.filterEnabled) { this.resetFilters(); }
    }


  //To populate filter menu options
  getFilterOptionAfterFilter(options, gridData, dataString) {
    if (gridData && gridData[dataString].length > 0) {
      
        options = this.getFilterOptions(gridData[dataString], options);
     
    }
    if (options.options.length > 0) {
      options = this.getFilteredValue(options);
    }
    return options;
  }


  public getFilteredValue(object) {
    object.options = [...new Map(object.options.map(item => [item.name, item])).values()];
    if (object.displayFieldType && object.displayFieldType.indexOf('date') !== -1) {
        object.options = this.orderByDateWithNullsAtStart(object.options, 'name', false);
    }
    else {
        object.options = this.orderByWithNullsAtStart(object.options, 'name', false);
    }
    object.filteredOptions = object.options.slice();
    return object;
}

// Reset table filters
  resetFilters() {
    this.filterValues = {};
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
      value.optionselected = false;
      if (value.options){
        value.options.forEach(element => {
          element.checked = false;
        });
        value.filteredOptions = value.options.slice();
      }
    });

    this.filterAnchor = '';
    this.dataSourceObj.filter = '';
    // if (this.emitFilterData){
    //   this.emitFilteredData.emit({
    //           data: this.dataSourceFirstLevelList.dataSource.filteredData,
    //           column: null,
    //           clickedValue: null,
    //           checked: false
    //         });
    // }
  }

  public orderByWithNullsAtStart(pArray: any[], pAttr: string, pReverse: boolean) {
    const partition = _.partition(pArray, (x) => !!_.get(x, pAttr, null));
    if (pAttr === 'name') {
    return _.concat(partition[1], _.orderBy(partition[0], (i) => typeof i.name === 'string' &&
                    i.name.toLowerCase(), (pReverse ? 'desc' : 'asc')));
    }
    else { return _.concat(partition[1], _.orderBy(partition[0], pAttr, (pReverse ? 'desc' : 'asc'))); }
}

public orderByDateWithNullsAtStart(pArray: any[], pAttr: string, pReverse: boolean) {
    const partition = _.partition(pArray, (x) => !!_.get(x, pAttr, null));
    return _.concat(partition[1], _.orderBy(partition[0], (dateObj) => { // function(dateObj) {
        return new Date(dateObj.name);
    }));
}

// Custom filter method fot Angular Material Datatable
createFilter() {
  let that = this;
  let filterFunction = function (rowData: any, filter: string): boolean {
    const searchTerms = JSON.parse(filter);
    const data = rowData.value;

    let nameSearch = () => {
      const foundData: boolean[] = [];
      // if (isFilterSet) {
      for (const col in searchTerms) {
        let currentFilter = that.filterSelectObj.find(row => row.name === col);
        // let fieldName = col;
        // if(currentFilter && currentFilter.displayField){
        //   fieldName = currentFilter.displayField;
        // }
        let validateData = '';
            if (data[col] || data[col] == "" || data[col] === false) {
              validateData = data[col].toString().trim().toLowerCase();
            }
            else{
              validateData= '';
            }
            if (searchTerms[col].filter(item => item.trim().toLowerCase() == validateData).length > 0) { // && isFilterSet
              foundData.push(true);
            }
            else { foundData.push(false); }
        
      }
      if (foundData.length <= 0) { return false; }
      for (var i in foundData) {
        if (!foundData[i]) { return false; }
      }
      return true;
    };
    return nameSearch();
  };
  return filterFunction;
}

  // to get the display field name for sorting
  getDisplayFieldName(property){
   return this.gridColData.find(obj=>obj.name === property);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gridData){
      this.showNoMsg = this.gridData.data.length === 0 ? true : false;
    }
    // need to check whether this is still used
    if (this.userIdData && this.editForm){
      // const userCtrl = this.editForm.get('userTable') as FormArray;
      this.formArrayObject.controls[0].patchValue({ userName: this.userIdData.userName, emailId: this.userIdData.emailId });
      // this.dataSource.data[0].userName =  this.userIdData.userName;
      // this.dataSource.data[0].emailId =  this.userIdData.emailId;
      if (this.userIdData.message !== null) {
        this.uS.toastMessage(this.userIdData.message, '');
        this.disableCheck = true;
      } else {
        this.disableCheck = false;
      }
    }
  }

  // to easily access current formArray object
  get formArrayObject(): FormArray {
    let formArrayName =this.getFormArrayName();
    return this.editForm.get(formArrayName) as FormArray;
  }

  // to get the current formArray name as string
  getFormArrayName(){
    let formArrayName ='';
    switch(this.config){
      case 'show': formArrayName = 'configTable'; break;
      case 'genre': formArrayName = 'genreTable'; break;
      case 'vendors': formArrayName = 'vendorTable'; break;
      case 'distribution': formArrayName = 'distributionTable'; break;
      case 'users': formArrayName = 'userTable'; break;
      case 'media': formArrayName = 'mediaTable'; break;
    }
    return formArrayName;
  }

  // logic to add new row on click of add button
  addNewRow() {
    let userCtrl; let newConfig = {};
    let index; const today = new Date();
    this.showNoMsg = false;
    
    switch (this.config) {
      case 'genre':
        // userCtrl = this.editForm.get('genreTable') as FormArray;
        index = this.dataSourceObj.data.length + 1;
        newConfig = { genreDesc: '', genreCode: '', edittableMode: true, addNew: true, currentIndex: index} as IMGenreDetail;
        this.generateDisplay(true);
        this.formArrayObject.insert(0, this.setGenreFormArray(newConfig));
        this.formArrayObject.controls[0].markAllAsTouched();
        break;
      case 'media':
        // userCtrl = this.editForm.get('mediaTable') as FormArray;
        index = this.dataSourceObj.data.length + 1;
        newConfig = { edittableMode: true, addNew: true, lastUpdatedDate: today,
           lastUpdatedBy: this.loggedinUser, mediaTypeId: null, mediaTypeName: null,
           mediaSubTypeId: null, mediaSubTypeName: null, glAccountNo: null, currentIndex: index} as IMediaTypeDetail;
        this.generateDisplay(true);
        this.formArrayObject.insert(0, this.setMediaFormArray(newConfig));
        this.formArrayObject.controls[0].markAllAsTouched();
        break;
      case 'users':
        //userCtrl = this.editForm.get('userTable') as FormArray;
        index = this.dataSourceObj.data.length + 1;
        newConfig = { edittableMode: true, addNew: true, userRolesTemp: this.rolesData, currentIndex: index};
        this.generateDisplay(true);
        this.formArrayObject.insert(0, this.setUserFormArray(newConfig));
        this.setValidators(newConfig['addNew']);
        this.formArrayObject.controls[0].markAllAsTouched();
        break;
      case 'distribution':
        // userCtrl = this.editForm.get('distributionTable') as FormArray;
        index = this.dataSourceObj.data.length + 1;
        newConfig = { edittableMode: true, addNew: true, percentage: 0, dateUpdated: null,
          userUpdated: null, mediaTypeId: null, mediaTypeName: null, estimateDistributionId: null, currentIndex: index} as IDistributionDetail;
        this.generateDisplay(true);
        this.formArrayObject.insert(0, this.setDistributionFormArray(newConfig));
        this.formArrayObject.controls[0].markAllAsTouched();
        break;
      case 'vendors':
        // userCtrl = this.editForm.get('vendorTable') as FormArray;
        index = this.dataSourceObj.data.length + 1;
        newConfig = {
          accountNo: null
          , active: true
          , addNew: true
          , comments: null
          , dateUpdated: null
          , edittableMode: true
          , incomeType: null
          , incomeTypeId: null
          , location: null
          , postal: null
          , snapVendorName: null
          , snapVendorNo: null
          , userUpdated: null
          , vendorId: null
          , vendorType: null
          , currentIndex: index
        };
        this.generateDisplay(true);
        this.formArrayObject.insert(0, this.setVendorFormArray(newConfig));
        this.formArrayObject.controls[0].markAllAsTouched();
        break;
    }
    if (newConfig){
      this.dataSourceObj.data = [...this.formArrayObject.controls];
      newConfig = {};
    }
  }

  //set Validators for user page
  setValidators(addNew){
    if (this.config === 'users'){
      if (addNew) {
        this.formArrayObject.controls[0].get('userIdNo').setValidators(Validators.min(100000000));
        this.formArrayObject.controls[0].get('userIdNo').updateValueAndValidity();
      } else {
        // tslint:disable-next-line:no-unused-expression
        this.formArrayObject.controls[0].get('userIdNo').clearValidators;
        this.formArrayObject.controls[0].get('userIdNo').updateValueAndValidity();
      }
    }
  }

  // to set editable and non-editable views
  generateDisplay(isEditable) {
    if (isEditable) {
      this.disableEdit = true;
      this.enableNew.emit(false);
      const dropDownFileds = ['titleTypeId', 'seriesNo', 'seasonTypeId', 'genreNo', 'year', 'moduleName', 'incomeTypeId', 'location', 'vendorType', 'mediaTypeId'];
      const checkboxFields = ['roles']; const multiSelect = ['roles'];
      const dateFileds = ['startDate', 'endDate'];
      const textFileds = ['showName', 'seasonNo', 'userIdNo', 'mediaSubTypeName', 'percentage', 'glAccountNo', 'genreDesc', 'genreCode', 'snapVendorNo', 'snapVendorName', 'postal', 'comments'];
      this.gridColData.forEach(data => {
        if (data.name === 'active') { data.type = 'toggleSwitch'; }
        dropDownFileds.filter(field => { if (data.name === field) {data.type = 'dropdown'; } });
        dateFileds.filter(field => { if (data.name === field) {data.type = 'date'; } });
        textFileds.filter(field => { if (data.name === field) {data.type = 'text'; } });
        multiSelect.filter(field => { if (data.name === field) {data.type = 'multi-select'; } });
      });
    } else { this.gridColData.forEach(data => {data.type = 'no-edit'; }); }
  }

  //to fetch master data.
  getMasterData() {
    const yearList = () => {
      const years = []; const now = moment();
      const dateStart = now.subtract('5', 'years'); const dateEnd = moment().add(9, 'y');
      while (dateEnd.diff(dateStart, 'years') >= 0) { years.push(dateStart.format('YYYY')); dateStart.add(1, 'year'); }
      return years;
     };
    switch (this.config) {
      case 'show':
        this.masterData = this.showLov; this.years = this.masterData['year'];
        this.genreList = this.masterData.genre; this.titleList = this.masterData.titleType;
        this.seasonList = this.masterData.seasonType; this.seriesList = this.masterData.series;
        this.displayedColumns = this.gridData.displayColumns;
        this.showNoMsg = this.gridData.data && this.gridData.data.length === 0 ? true : false;
        this.gridColData = this.gridData.columns;
        this.generateGrid();
        break;
      case 'media':
      case 'genre':
      case 'distribution':
        this.generateGrid();
        break;
      case 'users':
        this.moduleList = this.userMasterData.modules;
        this.rolesData = this.userMasterData.roles;
        this.generateGrid();
        break;
      
      case 'vendors':
        if (this.dropdownMasterData && this.dropdownMasterData.length > 0) {
          this.dropdownMasterData.filter(item => {
            if (item.values && item.values.length > 0) {
              if (item.id) {
                this.setDropdownValues(item.values, item.id, item.desc);
              }
              if (item.id === 'incomeTypeId') {
                this.incomeTypeList = item.values;
              }
              if (!this.masterData) {
                this.masterData = {};
              }
              this.masterData[item.name] = item.values;
            }
          });
        }

        this.generateGrid();
        break;
    }
  }

  // event on date change
  onDataChange(selDate, data) {
    const selYear = selDate.getFullYear();
    const year = this.years.find(obj => Number(obj.id) === selYear).id;
    // const addCtrl = this.editForm.get('configTable') as FormArray;
    data.patchValue({ year });
    //this.dataSource.data[i].year = Number(year);
  }

  // to form the grid binding data.
  generateGrid() {
    this.displayedColumns = this.gridData.displayColumns;
    this.gridColData = this.gridData.columns;
    this.dataSource.data = [];
    this.gridData.data.forEach(res => {
      if (this.config === 'show') {
        const seriesNo = this.seriesList.find(a => a.value === res.seriesName) &&
        this.seriesList.find(a => a.value === res.seriesName).id;
        res.year = res.year && res.year.toString(); res.seriesNo = seriesNo;
      }
      if (this.config === 'users') {
        res.addNew = false;
        res['userRolesTemp'] = [];
        this.rolesData.forEach(masterRole => {
          let checkValue = res.userRoles.find(a => a.roleId === masterRole.id);
          const checkedVal = res.userRoles.find(a => a.roleId === masterRole.id);
          if (!checkedVal) {
              res.userRolesTemp.push({ roleDesc: masterRole.value, roleId: masterRole.id, userRoleId: null});
            } else { res.userRolesTemp.push(checkedVal); }
        });
        this.ssoList.push(res.userIdNo);
      }
      if (this.config === 'genre' || this.config === 'media' || this.config === 'distribution') { res.addNew = false; }
      res.edittableMode = false;
      this.dataSource.data.push(res);
    });
    this.setForm();
  }

  // on click of edit/delete/discard operations
  editRow(val, data) {
    let i = this.getIndex(data);
    this.actionType = '';
    switch (val) {
      // case 'menu': this.MenuRow = data; this.MenuRowIndex = i; break;
      case 'menu-edit': 
      case 'edit': data.patchValue({ edittableMode: true });
                    this.generateDisplay(data.value.edittableMode);
                   break;
      case 'update':
        let control = {};
        let updateReq = {}; let rowData = {};
        // this.disableEdit = false; this.enableNew.emit(true);
        this.actionType = data.value.addNew ? 'save' : 'update';
        switch (this.config) {
          case 'show':
            control = data.value;
            this.checkValidity = data.valid;
            break;
          case 'genre':
            const newValues     = data.value;
            const isDuplicate   = this.checkGenreIfDuplicateData(newValues,i);
            if( isDuplicate ){
              this.uS.toastMessage('Genre Description already exists : '+newValues.genreDesc, '');
              data.get('genreDesc').setErrors({'incorrect': true});
            }
            control             = data.value;
            this.checkValidity  = data.valid;
            break;
          case 'vendors':
            control = data.value;
            this.checkValidity = data.valid;
            break;
          case 'distribution':
            let percentageTotal   = 0;
            control               = data.value;
            let skipCount:boolean = false;
            this.gridData.data.forEach( res => {
              skipCount = ( control['mediaTypeId'] !== undefined && res.mediaTypeId !== undefined && control['mediaTypeId'] === res.mediaTypeId )?true:false;
              if( !skipCount ) percentageTotal += res.percentage;
            });
            this.checkValidity = data.valid &&
              (percentageTotal + Number(control['percentage']) <= 100);
            if ((percentageTotal + Number(control['percentage'])) > 100) { this.checkPercentage(); }
            break;
          case 'users':
            control = data.value;
            this.checkValidity = data.valid;
            break;
          case 'media':
            this.actionType = 'update';
            control = data.value;
            this.checkValidity = data.valid;
            break;
        }
        if (this.checkValidity) {
          let userCheck = false;
          if (this.config === 'users' && data.value.addNew) { userCheck = this.checkSSN(control) ? true : false; }
          if ((this.config !== 'users') || (this.config === 'users' && data.value.addNew && userCheck) ||
           (this.config === 'users' && !data.value.addNew)) {
            this.disableEdit = false; this.enableNew.emit(true);
            data.patchValue({ edittableMode: false }); 
            updateReq = this.updateReq(control);
            // rowData = this.updateGrid(control);
            // this.dataSource.data[i] = rowData;
            // this.table.renderRows();
            this.updatedRow.emit(updateReq);
          }
        }
        break;
      case 'delete':
        this.actionType = 'delete';
        switch (this.config) {
          case 'show':
            if (data.value.ioCount || data.value.estimateRecordCount) { this.openDialog('deleteIO'); }
            else {
              //const deleteCtrl = this.editForm.get('configTable') as FormArray;
              // this.dataSourceObj.data.splice(i, 1);
              this.formArrayObject.removeAt(i);
              this.dataSourceObj.data = [...this.formArrayObject.controls];
              this.updatedRow.emit(this.updateReq(data.value));
            }
            break;
          case 'vendors':
          case 'media':
              if (!data.value.addNew){
                this.updatedRow.emit(this.updateReq(data.value));
              }
              // this.dataSourceObj.data.splice(i, 1);
              this.formArrayObject.removeAt(i);
              this.dataSourceObj.data = [...this.formArrayObject.controls];
              // this.table.renderRows();
              break;
      }
        // this.selectedRow.edittableMode = false;
        data.patchValue({ edittableMode: false });
        this.disableEdit = false; this.enableNew.emit(true);
        break;
      case 'cancel':
        this.disableEdit = false; this.enableNew.emit(true);
        if (data.value.addNew) {
          this.formArrayObject.removeAt(i);
          // this.dataSourceObj.data.splice(i, 1);
          this.dataSourceObj.data = [...this.formArrayObject.controls];
          this.table.renderRows();
          this.showNoMsg = this.dataSourceObj.data.length > 0 ? false : true;
        } else { 
          let configItem = this.getRowRawData(data);
          if(configItem){
            const patchData = this.getRowInitialData(configItem);
            if(patchData){
              data.patchValue(patchData.value);
            }
            else{
              data.patchValue({ edittableMode: false }); 
            }
          }
          else{
            data.patchValue({ edittableMode: false }); 
          }
      }
        // TODO: Remove edited values on cancel from form
        break;
    }
  }

  //to get the actual index of the row item
  getIndex(data){
    let indexValue = 0;
    this.dataSourceObj.data.some((rowValue, index) => {
      if(rowValue.value.currentIndex === data.value.currentIndex){
            indexValue = index;
            return  true;
      }
    })
    return indexValue;
  }

  // to get initial row data for revert/discard operation
  getRowRawData(data){
    let id = this.configIdObject[this.config];
    let dataRow = this.dataSource.data.find(obj => obj[id] === data.value[id]);
    return dataRow;
  }


  // get inital row data as form array object
  getRowInitialData(configItem){
    let formObj;
    switch(this.config){
      case 'show' :  formObj = this.setConfigFormArray(configItem);break;
      case 'genre':  formObj = this.setGenreFormArray(configItem);break;
      case 'media':  formObj = this.setMediaFormArray(configItem);break;
      case 'users':  formObj = this.setUserFormArray(configItem);break;
      case 'distribution': formObj = this.setDistributionFormArray(configItem);break;
      case 'vendors': formObj = this.setVendorFormArray(configItem);break;
    }
    return formObj;
  }


  // percentage validation popup for estimate distribution
  checkPercentage() {
    const data = {name: 'media', title: 'Percentage Total should not exceed 100%' };
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      width: '372px',
      height: 'auto',
      disableClose: true,
      data,
    });
    // dialogRef.afterClosed().subscribe(res => {

    // });
  }

  // user already exist validation popup for user page
  checkSSN(data) {
    const sso = {name: 'sso', title: 'User already exists' };
    if (this.ssoList.find(a => a === data.userIdNo)) {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        width: '372px',
        height: 'auto',
        disableClose: true,
        data: sso,
      });
      // dialogRef.afterClosed().subscribe(res => {

      // });
      return false;
    } else {return true; }
  }


  // on media type selection for estimate distribution
  onMediaSelect(event, data) {
    if (this.config === 'distribution') {
      for( let ctrlObj of this.formArrayObject.value){
        if( data.currentIndex !== ctrlObj.currentIndex && ctrlObj.mediaTypeId === event.value ){
          this.uS.toastMessage('Media Type already exists', '');
          data.get('mediaTypeId').setErrors({ 'invalid': true });
          data.setErrors({ 'invalid': true });
          return false;
        }
      }
    }
  }

  // to update grid data -- not used now
  updateGrid(selected) {
    switch (this.config) {
      case 'show':
        const startDate = new Date(selected.startDate.toString());
        const endDate = new Date(selected.endDate.toString());
        const genre = this.genreList.find(a => a.id === selected.genreNo);
        const seriesNoObject = this.seriesList.find(a => a.value === selected.seriesName);
        const seriesNo = (seriesNoObject !== undefined && seriesNoObject)?seriesNoObject.id:null;
        const series = this.seriesList.find(a => a.id === selected.seriesNo);
        const seasonType = this.seasonList.find(a => a.id === selected.seasonTypeId);
        const title = this.titleList.find(a => a.id === selected.titleTypeId);
        const year = this.years.find(a => a.value === selected.year);
        return {
          active: selected.active,
          endDate: moment(endDate).format('MM/DD/YYYY'),
          genreNo: selected.genreNo,
          genreDesc: genre && genre.value,
          ioCount: selected.ioCount,
          titleType: title && title.value,
          titleTypeId: selected.titleTypeId,
          seasonNo: selected.seasonNo,
          seasonType: seasonType && seasonType.value,
          seasonTypeId: selected.seasonTypeId,
          seriesNo: selected.seriesNo,
          seriesName: series && series.value,
          showId: selected.showId,
          showName: selected.showName,
          startDate: moment(startDate).format('MM/DD/YYYY'),
          estimateRecordCount: selected.estimateRecordCount,
          year: year && year.value,
          edittableMode: false
        };
        break;
      case 'genre':
        const dateAdded = new Date(selected.dateAdded.toString());
        const dateUpdated = new Date();
        return {
          active: selected.active,
          // genreNo: selected.genreNo,
          genreDesc: selected.genreDesc,
          genreCode: selected.genreCode,
          dateAdded: moment(dateAdded).format('MM/DD/YYYY'),
          dateUpdated: moment(dateUpdated).format('MM/DD/YYYY'),
          userAdded: selected.userAdded,
          userUpdated: this.loggedinUser,
          edittableMode: false
        }
        break;
        case 'media':
          const mediaTypeName = this.mediaList.find(_ => _.mediaTypeId === selected.mediaTypeId).mediaTypeName;
          return {
            mediaTypeId: selected.mediaTypeId,
            mediaTypeName,
            mediaSubTypeId: selected.mediaSubTypeId,
            mediaSubTypeName: selected.mediaSubTypeName,
            glAccountNo: selected.glAccountNo,
            edittableMode: false
          }
          break;
      case 'vendors':
        const dateAddedNew = new Date(selected.dateAdded.toString());
        const dateUpdatedNew = new Date();
        const incomeTypeValue = this.incomeTypeList && this.incomeTypeList.find(a => a.id === selected.incomeTypeId);
        return {
          actionType: selected.actionType,
          vendorId: selected.vendorId,
          snapVendorNo: selected.snapVendorNo,
          snapVendorName: selected.snapVendorName,
          location: selected.location,
          postal: selected.postal,
          vendorType: selected.vendorType,
          accountNo: selected.accountNo,
          comments: selected.comments,
          incomeTypeId: selected.incomeTypeId,
          incomeType: incomeTypeValue ? incomeTypeValue.value : null,
          active: selected.active,
          dateAdded: moment(dateAddedNew).format('MM/DD/YYYY'),
          dateUpdated: moment(dateUpdatedNew).format('MM/DD/YYYY'),
          userAdded: selected.userAdded,
          userUpdated: this.loggedinUser,
          edittableMode: false
        };
        break;
      case 'distribution':
        const mediaTypeDesc = this.mediaTypeLov.find(_ => _.mediaTypeId === selected.mediaTypeId).mediaTypeName;
        return {
          mediaTypeId: selected.mediaTypeId,
          mediaTypeName: mediaTypeDesc,
          percentage: selected.percentage,
          userUpdated: selected.userUpdated,
          dateUpdated: selected.dateUpdated,
          edittableMode: false
        }
        break;
      case 'users':
        const inActiveDate = !selected.active ? new Date(selected.dateAdded.toString()) : null;
        const moduleId = selected.moduleName ? this.moduleList.find(a => a.value === selected.moduleName).id : null;
        return {
          active: selected.active,
          // userNo: selected.userNo ? selected.userNo : '',
          userIdNo: selected.userIdNo,
          userName: selected.userName,
          userAdded: selected.userAdded,
          dateAdded: selected.dateAdded ? new Date(selected.dateAdded) : '',
          inActiveDate: inActiveDate ? Math.floor(inActiveDate.getTime() / 1000) : null,
          emailId: selected.emailId,
          moduleId,
          moduleName: selected.moduleName,
          roles: selected.userRoles
        };
        break;
    }
  }

  // to form save request object
  updateReq(selected){
    switch (this.config) {
      case 'show':
        const genre = this.genreList.find(a => a.id === selected.genreNo);
        const seriesNo = this.seriesList.find(a => a.value === selected.seriesName)
          && this.seriesList.find(a => a.value === selected.seriesName).id;
        const series = this.seriesList.find(a => a.id === selected.seriesNo);
        const seasonType = this.seasonList.find(a => a.id === selected.seasonTypeId);
        const title = this.titleList.find(a => a.id === selected.titleTypeId);
        const year = this.years.find(a => a.value === selected.year);
        return {
          actionType: this.actionType,
          titleType: title && title.value,
          titleTypeId: selected.titleTypeId,
          seriesName: series && series.value,
          seriesNo: selected.seriesNo,
          seasonNo: selected.seasonNo,
          showName: selected.showName,
          active: !selected.active ? 'N' : 'Y',
          seasonType: seasonType && seasonType.value,
          seasonTypeId: selected.seasonTypeId,
          genreNo: selected.genreNo,
          genreDesc: genre && genre.value,
          startDate: selected.startDate,
          endDate: selected.endDate,
          showId: selected.showId,
          year: year && year.value ? year.value : null
        };
      case 'genre':
        const dateAdded = new Date(selected.dateAdded.toString());
        const dateUpdated = new Date();
        return {
          actionType: this.actionType,
          active: !selected.active ? 'N' : 'Y',
          genreNo: selected.genreNo ? selected.genreNo : '',
          genreDesc: selected.genreDesc,
          genreCode: selected.genreCode,
          dateAdded: Math.floor(dateAdded.getTime() / 1000),
          dateUpdated: Math.floor(dateUpdated.getTime() / 1000),
          userAdded: selected.userAdded,
          userUpdated: this.loggedinUser
        };
        break;
      case 'media':
        const mediaTypeName = this.mediaList.find(_ => _.mediaTypeId === selected.mediaTypeId).mediaTypeName;
        return {
          actionType: this.actionType,
          mediaTypeId: selected.mediaTypeId,
          mediaTypeName: selected.mediaTypeName,
          mediaSubTypeId: selected.mediaSubTypeId,
          mediaSubTypeName: selected.mediaSubTypeName,
          glAccountNo: selected.glAccountNo,
        };
        break;
      case 'distribution':
        const mediaTypeDesc = this.mediaTypeLov.find(_ => _.mediaTypeId === selected.mediaTypeId).mediaTypeName;
        const updatedDate = new Date();
        return {
          actionType: this.actionType,
          mediaTypeId: selected.mediaTypeId,
          mediaTypeName: mediaTypeDesc,
          percentage: selected.percentage,
          estimateDistributionId: selected.estimateDistributionId,
          userUpdated: this.loggedinUser,
          dateUpdated: moment(updatedDate).format('MM/DD/YYYY')
        }
        break;
      case 'users':
        const inActiveDate = !selected.active ? new Date(selected.dateAdded.toString()) : null;
        const moduleId = selected.moduleName ? this.moduleList.find(a => a.value === selected.moduleName).id : null;
        return {
          actionType: this.actionType,
          active: !selected.active ? 'N' : 'Y',
          userNo: selected.userNo ? selected.userNo : '',
          userIdNo: selected.userIdNo,
          userName: selected.userName,
          userAdded: selected.userAdded,
          inActiveDate: inActiveDate ? Math.floor(inActiveDate.getTime() / 1000) : null,
          emailId: selected.emailId,
          moduleId,
          moduleName: selected.moduleName,
          dateAdded: selected.dateAdded ? new Date(selected.dateAdded) : '',
          roles: selected.roles,
          addRolesList: this.addRoleList,
          deleteRolesList: this.deleteRoleList
        };
        break;
      case 'vendors':
      // const incomeTypeValue = this.seasonList.find(a => a.id === selected.seasonTypeId);
      return {
        actionType: this.actionType,
        vendorId: selected.vendorId,
        snapVendorNo: selected.snapVendorNo,
        snapVendorName: selected.snapVendorName,
        location: selected.location,
        postal: selected.postal,
        vendorType: selected.vendorType,
        accountNo: selected.accountNo,
        comments: selected.comments,
        incomeTypeId: selected.incomeTypeId,
        active: !selected.active ? 'N' : 'Y'
      };
      break;
    }
  }

  // delete field value - show setup screen
  deleteField(field, val) {
    const userSSO = this.cS.getLoggedInSSO(); let index;
    //const addCtrl = this.editForm.get('configTable') as FormArray;
    let reqPayload = {};
    switch (field){
      case 'seriesNo':
        // const seriesVal = addCtrl.controls[i].get('seriesNo').value;
        const series = this.seriesList.find(a => a.id === val.value[field]);
        reqPayload = { seriesId: series.id, seriesName: series.value};
        this.adminService.deleteSeries(reqPayload).subscribe(data => {
          if (data) {
            const seriesIndex = this.masterData.series.findIndex(_ => _.id === series.id);
            this.masterData.series.splice(seriesIndex, 1);
            val.patchValue({ seriesNo: null });
            this.uS.toastMessage('Series Deleted Successfully', '');
           }
        }, err => { this.uS.toastMessage(err.error.errorDesc, ''); });
        break;
      case 'seasonTypeId':
        //const seasonVal = addCtrl.controls[i].get('seasonTypeId').value;
        const season = this.seasonList.find(a => a.id === val.value[field]);
        reqPayload = { seasonTypeId: season.id, seasonType: season.value};
        this.adminService.deleteSeasonType(reqPayload).subscribe(data => {
          if (data) {
             const seasonIndex = this.masterData.seasonType.findIndex(_ => _.id === season.id);
             this.masterData.seasonType.splice(seasonIndex, 1);
             val.patchValue({ seasonTypeId: null });
             this.uS.toastMessage('SeasonType Deleted Successfully', '');
            }
        }, err => { this.uS.toastMessage(err.error.errorDesc, ''); });
        break;
    }
  }

  // generic popup open
  openDialog(field, dataRow?) {
    const ioDel = {
      name: 'deleteIO',
      title: 'IOs Have Been Issued'
    };
    const addSeries = { name: 'addSeries', title: 'Add Series' }; const addSeasonType = { name: 'addSeasonType', title: 'Add Season Type' };
    const addMediaType = { name: 'addMediaType', title: 'Add Media Type'};
    const fieldData = field === 'deleteIO' ? ioDel : field === 'seriesNo' ? addSeries : field === 'seasonTypeId' ? addSeasonType : field === 'mediaTypeId' ? addMediaType : '';
    const userSSO = this.cS.getLoggedInSSO();
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      width: '372px',
      height: 'auto',
      disableClose: true,
      data: fieldData
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        switch (field) {
          case 'seriesNo':
            for (const data in res.data) {
              if (!(this.seriesList.find(a => a.value === res.data[data].seriesName))) {
                this.masterData.series.push({id:  res.data[data].seriesId, value:  res.data[data].seriesName});
                // const addCtrl = this.editForm.get('configTable') as FormArray;
                dataRow.patchValue({ seriesNo:  res.data[data].seriesId });
              }
            }
            break;
          case 'seasonTypeId':
            for (const data in res.data) {
              if (!(this.seasonList.find(a => a.id === res.data[data].seasonId))) {
                this.masterData.seasonType.push({id: res.data[data].seasonId, value: res.data[data].seasonName});
                const addCtrl = this.editForm.get('configTable') as FormArray;
                dataRow.patchValue({ seasonTypeId:  res.data[data].seasonId });
              }
            }
            break;
          case 'mediaTypeId':
            for (const data in res.data.mediaTypeList) {
              if (!(this.mediaList.find(a => a.mediaTypeId === res.data.mediaTypeList[data].mediaTypeId))) {
                this.mediaList.push({mediaTypeId:  res.data.mediaTypeList[data].mediaTypeId,
                  mediaTypeName:  res.data.mediaTypeList[data].mediaTypeName});
                const addCtrl = this.editForm.get('mediaTable') as FormArray;
                dataRow.patchValue({ mediaTypeId:  res.data.mediaTypeList[data].mediaTypeId });
              }
            }
            break;
        }
      }
    });
  }

  //set form method for all admin pages.(except manage roles)
  private setForm() {
    this.dataSource.data.forEach((configItem, index) => {
            configItem.currentIndex = index;
            this.formArrayObject.push(this.getRowInitialData(configItem));
          });
    this.formArrayObject.markAllAsTouched();
  }

  // to set user form array
  private setUserFormArray(user) {
    const active = user.active === 'N' ? false : true;
    return this.formBuilder.group({
      // action: '',
      rowEdit: '',
      userNo: [user.userNo],
      userIdNo: [user.userIdNo, [Validators.required]],
      userName: [user.userName],
      active: new FormControl({value: active, disabled: user.addNew ? true : false}),
      userAdded: [user.userAdded],
      emailId: [user.emailId],
      moduleId: [user.moduleId],
      moduleName: [user.moduleName],
      inActiveDate: [user.inActiveDate ? new Date(user.inActiveDate) : ''],
      dateAdded: [user.dateAdded ? new Date(user.dateAdded) : ''],
      edittableMode: user.edittableMode ? true : false,
      roles: [user.userRoles, Validators.required],
      userRolesTemp: [user.userRolesTemp],
      userRoles:[user.userRoles],
      currentIndex: user.currentIndex,
      addNew: user.addNew ? true : false
    });
  }

  // onCheckBoxChanges(e, id, data) {
  //   console.log(e, id, data);
  //   const userCtrl = this.editForm.get('userTable') as FormArray;
  //   console.log(this.editForm, userCtrl);
  //   const dataIndex = userCtrl.value.findIndex(_ => _.userIdNo === data.userIdNo);
  //   const index = data.userRoles.findIndex(_ => _.roleId === id);
  //   data.userRoles[index].checked = e.checked;
  //   console.log(data);
  //   userCtrl.controls[dataIndex].get('roles').patchValue(data.userRoles);
  // }

  // on multi select dropdown change
  onMultiSelect(e, data) {
    // console.log(e.value, data, i);
    let addedList = []; let deleteList = [];
    if (!data.value.addNew) {
      data.value.userRoles.forEach(oldData => {
        const delData = e.value.find(a => a.roleId === oldData.roleId);
        if (!delData) {deleteList.push({userRoleId: oldData.userRoleId}); }
      });
      e.value.forEach(newVal => {
        const newData = data.value.userRoles.find(a => a.roleId === newVal.roleId);
        if (!newData) {addedList.push({roleId: newVal.roleId}); }
      });
    } else {
      e.value.forEach(newVal => {
        addedList.push({roleId: newVal.roleId});
      });
     }
    this.addRoleList = [...new Set(addedList)];
    this.deleteRoleList = [...new Set(deleteList)];
    // console.log('deleteList', this.deleteRoleList);
    //const userCtrl = this.editForm.get('userTable') as FormArray;
    data.get('roles').patchValue(e.value);
  }

  // set show setup form array
  private setConfigFormArray(config) {
    const active = config.active === 'Y' ? true : false;
    config.titleType = config.titleTypeId ? this.titleList.find(a => a.id === config.titleTypeId).value : '';
    config.seasonType = config.seasonTypeId ? this.seasonList.find(a => a.id === config.seasonTypeId).value : '';
    return this.formBuilder.group({
      rowEdit: '',
      titleType: [config.titleType],
      titleTypeId: [config.titleTypeId, Validators.required],
      seriesName: ((config.titleTypeId === 2)?[config.seriesName]:[config.seriesName, Validators.required]),
      seriesNo: ((config.titleTypeId === 2)?[config.seriesNo]:[config.seriesNo, Validators.required]),
      year: ((config.titleTypeId === 2)?[config.year]:[config.year, Validators.required]),
      seasonNo: ((config.titleTypeId === 2)?[config.seasonNo]:[config.seasonNo, Validators.required]),
      showName: [config.showName, Validators.required],
      showId: [config.showId],
      active: [active, Validators.required],
      seasonType: [config.seasonType],
      seasonTypeId: [config.seasonTypeId, Validators.required],
      genreNo: [config.genreNo, Validators.required],
      genreDesc: [config.genreDesc],
      startDate: [config.startDate ? new Date(config.startDate) : '', Validators.required],
      endDate: [config.endDate ? new Date(config.endDate) : '', Validators.required],
      ioCount: [config.ioCount],
      estimateRecordCount: [config.estimateRecordCount],
      edittableMode: config.edittableMode ? true : false,
      currentIndex: config.currentIndex
    });
  }

  // set genre form array
  private setGenreFormArray(genre) {
    const dateUpdated = new Date();
    const active = genre.active === 'Y' ? true : false;
    genre.dateUpdated = moment(dateUpdated).format('MM/DD/YYYY');
    // genre.userUpdated = this.loggedinUser;
    return this.formBuilder.group({
      rowEdit: '',
      genreNo: [genre.genreNo],
      active: [active, Validators.required],
      genreDesc: [genre.genreDesc ? genre.genreDesc : '', Validators.required],
      genreCode: [genre.genreCode, Validators.required],
      userAdded: [genre.userAdded],
      userUpdated: genre.userUpdated ? genre.userUpdated : this.loggedinUser,
      dateAdded: [genre.dateAdded ? new Date(genre.dateAdded) : ''],
      dateUpdated: genre.dateUpdated,
      edittableMode: genre.edittableMode ? true : false,
      currentIndex: genre.currentIndex,
      addNew: genre.addNew ? true : false
    });
  }

  // set media type form array
  private setMediaFormArray(media) {
    const mediaTypeName = media.mediaTypeId ? this.mediaList.find(_ => _.mediaTypeId === media.mediaTypeId).mediaTypeName : '';
    media.lastUpdatedDate = media.lastUpdatedDate ? moment(media.lastUpdatedDate, moment.defaultFormat).format('MM/DD/YYYY') : '';
    media.userUpdated = this.loggedinUser;
    return this.formBuilder.group({
      rowEdit: '',
      mediaTypeId: [media.mediaTypeId, Validators.required],
      mediaTypeName: [media.mediaTypeName ? media.mediaTypeName : mediaTypeName],
      mediaSubTypeId: [media.mediaSubTypeId ? media.mediaSubTypeId : null],
      mediaSubTypeName: [media.mediaSubTypeName ? media.mediaSubTypeName : '', Validators.required],
      glAccountNo: [media.glAccountNo ? media.glAccountNo : null],
      lastUpdatedBy: [media.lastUpdatedBy ? media.lastUpdatedBy : ''],
      lastUpdatedDate: [media.lastUpdatedDate ? media.lastUpdatedDate : null],
      edittableMode: media.edittableMode ? true : false,
      currentIndex: media.currentIndex,
      addNew: media.addNew ? true : false
    });
  }

  // set estimate distribution form array
  private setDistributionFormArray(distribution) {
    const mediaTypeName = distribution.mediaTypeId ? this.mediaTypeLov.find(_ => _.mediaTypeId === distribution.mediaTypeId) &&
      this.mediaTypeLov.find(_ => _.mediaTypeId === distribution.mediaTypeId).mediaTypeName : '';
    return this.formBuilder.group({
      rowEdit: '',
      mediaTypeId: [distribution.mediaTypeId, Validators.required],
      mediaTypeName: [distribution.mediaTypeName, Validators.required],
      percentage: [distribution.percentage, Validators.required],
      estimateDistributionId: distribution.estimateDistributionId,
      userUpdated: distribution.userUpdated,
      dateUpdated: distribution.dateUpdated,
      edittableMode: distribution.edittableMode ? true : false,
      currentIndex: distribution.currentIndex,
      addNew: distribution.addNew ? true : false
    });
  }

  // set vendor form array
  setVendorFormArray(vendor){
    const dateUpdated = new Date(vendor.dateUpdated);
    const active = vendor.active === 'Y' ? true : false;
    vendor.dateUpdated = moment(dateUpdated).format('MM/DD/YYYY');
    // vendor.userUpdated = this.loggedinUser;
    return this.formBuilder.group({
      rowEdit: '',
      snapVendorNo: [vendor.snapVendorNo, Validators.required],
      snapVendorName: [vendor.snapVendorName, Validators.required],
      location: [vendor.location, Validators.required],
      postal: [vendor.postal, [Validators.required, Validators.maxLength(5)]],
      vendorType: [vendor.vendorType, Validators.required],
      incomeType: [vendor.incomeType],
      comments: [vendor.comments],
      active: [active],
      vendorId: [vendor.vendorId],
      userAdded: [vendor.userAdded],
      userUpdated: [vendor.userUpdated ? vendor.userUpdated : this.loggedinUser],
      dateAdded: [vendor.dateAdded ? new Date(vendor.dateAdded) : ''],
      dateUpdated: vendor.dateUpdated,
      accountNo: [vendor.accountNo],
      incomeTypeId: [vendor.incomeTypeId],
      currentIndex: vendor.currentIndex,
      edittableMode: vendor.edittableMode ? true : false,
      addNew: vendor.addNew ? true : false
    });
  }

  // to form dropdown list values
  setDropdownValues(list, id, name) {
    if (list && list.length > 0) {
      list.filter(item => {
        item.id = item[id];
        item.value = item[name];
      });
    }
  }

  // on focus out for sso field - user page
  onFocusOut(event) {
    if (event.target.value && event.target.value.length === 9) {this.userId.emit(event.target.value); }
    else {
      // const userCtrl = this.editForm.get('userTable') as FormArray;
      this.formArrayObject.controls[0].patchValue({ userName: null, emailId: null });
    }
  }

  // on change of active field
  onActiveChange( data, event) {
    // console.log('event', event, i, data);
    if (this.config === 'users') {
      const inActiveDate = new Date();
      //const userCtrl = this.editForm.get('userTable') as FormArray;
      if (event) {
        data.patchValue({ inActiveDate: null });
      }
      else {
        data.patchValue({ inActiveDate: new Date() });
      }
    }
  }

  //to check duplicate genre data
  checkGenreIfDuplicateData(data,index){
    let formValues        = this.formArrayObject.value;
    let increment:number  = 0;
    for( let values of formValues ){
      if( values.genreNo !== undefined && values.genreNo !== null && values.genreDesc === data.genreDesc && increment !== index ){
        return true;
      }
      increment++;
    }
    return false;
  }

}
