<span class="ca-navigate-span" *ngIf="gridData.type === 'Current Accruals';">
  <span *ngIf="leftCarouselArrow" class="material-icons ca-left-nav-icon"
      (click)="navigateColumns('left')">keyboard_arrow_left</span>
  <span *ngIf="rightCarouselArrow" class="material-icons ca-right-nav-icon"
      (click)="navigateColumns('right')">keyboard_arrow_right</span>
</span>
<mat-table #table [dataSource]="dataSourceFirstLevelList.dataSource" class="top-mat-table"
    [ngClass]="{'currentAccruals' : gridData.type === 'Current Accruals', 'accrualDrops' : gridData.type === 'Accrual Drops'}">
    <ng-container *ngIf="gridData.topColumns && gridData.topColumns.display">
        <ng-container *ngIf="gridData.topColumns.display" matColumnDef="no-group-left">
            <mat-header-cell *matHeaderCellDef fxFlex="{{gridData.topColumns.f}}" class="{{gridData.parentTab}}">
                <span>
                    <span *ngIf="leftCarouselArrow" class="material-icons left-nav-icon"
                        (click)="navigateColumns('left')">keyboard_arrow_left</span>
                    <ng-container *ngIf="gridData.type === 'Drop Summary'; else accrualTopHeader">
                        <span class="dropSummary header1">{{gridData.header1}}</span>
                        <span class="dropSummary header2">{{gridData.header2}}</span>
                    </ng-container>
                    <ng-template #accrualTopHeader><span *ngIf="accrualDropsChecked" class="accrualDrops-header">Accrual
                            Drops</span><span>{{gridData.type}}</span></ng-template>
                    <span *ngIf="rightCarouselArrow" class="material-icons right-nav-icon"
                        (click)="navigateColumns('right')">keyboard_arrow_right</span>
                </span>
            </mat-header-cell>
        </ng-container>
        <mat-header-row class="upper-header" *matHeaderRowDef="['no-group-left']; sticky: true">
        </mat-header-row>
    </ng-container>
    <mat-header-row class="lower-header"
        [ngClass]="{'filterSpaceforEmpty': gridData.columns.length === 0 && filterEnabled}"
        *matHeaderRowDef="displayedColumns; sticky: true">
        <!-- <ng-container *matColumnDef *ngIf="((!gridData.columns || (gridData.columns && gridData.columns.length === 0)))">
        <mat-header-cell *matHeaderCellDef fxFlex="100">
              <span class="matheaderLabel">

              </span>
              <div class="filterRowContainer media-form" *ngIf="filterEnabled">
              </div>
          </mat-header-cell>
      </ng-container> -->
    </mat-header-row>
    <ng-container *ngFor="let header of gridData.columns;let colIndex= index" matColumnDef="{{header.name}}">
        <mat-header-cell *matHeaderCellDef [hidden]="!header.inView && header.refEnable && header.hidden"
        [ngClass]="{'individualColumn' : header.columnType === 'individual' && gridData.type === 'Current Accruals','accrualDropHeader': (header.tableType === 'accuralDrops' && accrualDropsChecked)}"
            class="{{header.style}}" fxFlex="{{header.width}}">
            <span class="matheaderLabel"
                [ngClass]="{'individualColumn' : header.columnType === 'individual','accrualDropHeader': (header.tableType === 'accuralDrops' && accrualDropsChecked),'filterSpacing': filterEnabled}">
                <ng-container *ngIf="gridData.type === 'Drop Summary'; else accrualHeader">
                    <div class="dropSummary-LowerHeaderLabel2">{{header.label2}}</div>
                    {{header.label}}
                </ng-container>
                <ng-template #accrualHeader>
                    {{header.label.replace('accru', '')}}
                </ng-template>
                <ng-container *ngIf="header.columnType === 'aggregate'; else individualColumn"><span
                        class="material-icons add-icon" *ngIf="header.ref" (click)="showRefCol(header.label)">
                        add
                    </span></ng-container>
                <ng-template #individualColumn><span class="material-icons remove-icon"
                        *ngIf="header.refEnable && header.removeIcon" (click)="hideRefCol(header.label)">
                        remove
                    </span></ng-template>
            </span>
            <div class="filterRowContainer media-form" *ngIf="filterEnabled">
            </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let data" [hidden]="!header.inView && header.refEnable && header.hidden"
            fxFlex="{{header.width}}" class="grouping-row"
            [ngClass]="{'editEstimateInline' : header.name === 'estimate' && data['isEditable']}"
            (click)="$event.stopPropagation();">
            <div fxFlex="100">
                <!-- <span class="float-left">
                    <mat-icon class="grid_arrows"
                        *ngIf="data?.showTree && data.showTree[header.name] && !data?.secondLevel?.displayResults"
                        (click)="toggleList(data, 'secondLevel', 'eSecondData');$event.stopPropagation();">
                        arrow_right
                    </mat-icon>
                </span>
                <span class="float-left">
                    <mat-icon class="grid_arrows"
                        *ngIf="data?.showTree && data.showTree[header.name] && data?.secondLevel?.displayResults"
                        (click)="toggleList(data, 'secondLevel', 'eSecondData');$event.stopPropagation();">
                        arrow_drop_down</mat-icon>
                </span> -->
                <span class="text-span" [tooltip]="contentData" content-type="template" placement="top" show-delay="100"
                    hide-delay="100">{{(data[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                <ng-template #contentData>
                    <span>{{(data[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                </ng-template>
            </div>
        </mat-cell>
        <!-- <mat-cell *matCellDef="let data" [hidden]="!(!header.inView && header.refEnable && header.hidden)"
            fxFlex="{{header.width}}" class="grouping-row"
            [ngClass]="{'editEstimateInline' : header.name === 'estimate' && data['isEditable']}"
            (click)="$event.stopPropagation();">
            <div fxFlex="100">
              <div class="filterRowContainer media-form" *ngIf="filterEnabled">
              </div>
            </div>
        </mat-cell> -->
        <ng-container *ngIf="showFooter">
        <mat-footer-cell *matFooterCellDef class="grouping-row" [hidden]="!header.inView && header.refEnable && header.hidden">
            <span class="text-span" [tooltip]="contentData" content-type="template" placement="top" show-delay="100"
                    hide-delay="100">{{(getTotal(header.name) | number:'1.0-0' | minusSignToParens)}}</span>
                <ng-template #contentData>
                    <span>{{(getTotal(header.name) | number:'1.0-0' | minusSignToParens)}}</span>
                </ng-template>
        </mat-footer-cell>
    </ng-container>
    </ng-container>
    


    <mat-row class="first-row-header" *matRowDef="let data; columns: displayedColumns;"
        [cdkDetailRowDisplayTpl]="data.eSecondData?.length && data?.secondLevel?.displayResults" [cdkDetailRow]="data"
        [cdkDetailRowTpl]="secondLevelListingTemplate">
    </mat-row>
    <ng-container *ngIf="showFooter">
    <mat-footer-row class="total-row" *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row></ng-container>
</mat-table>
<ng-template #secondLevelListingTemplate let-data>
    <div [@detailExpand] style="overflow: hidden">
        <mat-table [dataSource]="data.secondLevel.dataSource" [ngStyle]="{'background-color':'white'}" [ngClass]="">
            <ng-container *ngFor="let header of gridData.columns" matColumnDef="{{header.name}}">
                <mat-header-cell *matHeaderCellDef [hidden]="!header.inView && header.refEnable && header.hidden"
                    mat-sort-header [disabled]="!header.sort" fxFlex="{{header.width}}">
                    {{header.label}}</mat-header-cell>
                <mat-cell *matCellDef="let second" [hidden]="!header.inView && header.refEnable && header.hidden"
                    fxFlex="{{header.width}}"
                    [ngClass]="{'editEstimateInline' : header.name === 'estimate' && second['isEditable']}"
                    (click)="$event.stopPropagation();">
                    <div fxFlex="100">
                        <span class="text-span" [tooltip]="contentData" content-type="template" placement="top"
                            show-delay="100"
                            hide-delay="100">{{(second[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                        <ng-template #contentData>
                            <span>{{(second[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                        </ng-template>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let second; columns: displayedColumns;"
                [cdkDetailRowDisplayTpl]="second.eThirdData?.length && second?.thirdLevel?.displayResults"
                [cdkDetailRow]="second" [cdkDetailRowTpl]="thirdLevelListingTemplate">
            </mat-row>
        </mat-table>
    </div>
</ng-template>
<ng-template #thirdLevelListingTemplate let-second>
    <div [@detailExpandThird]="second.thirdLevel.displayResults ? 'open' : 'closed'" style="overflow: hidden">
        <mat-table [dataSource]="second.thirdLevel.dataSource" [ngStyle]="{'background-color':'white'}" [ngClass]="">
            <ng-container *ngFor="let header of gridData.columns" matColumnDef="{{header.name}}">
                <mat-header-cell *matHeaderCellDef [hidden]="!header.inView && header.refEnable && header.hidden"
                    mat-sort-header [disabled]="!header.sort" fxFlex="{{header.width}}">
                    {{header.label}}</mat-header-cell>
                <mat-cell *matCellDef="let third" [hidden]="!header.inView && header.refEnable && header.hidden"
                    fxFlex="{{header.width}}"
                    [ngClass]="{'editEstimateInline' : header.name === 'estimate' && third['isEditable']}"
                    (click)="$event.stopPropagation();">
                    <div fxFlex="100">
                        <span class="text-span" [tooltip]="contentData" content-type="template" placement="top"
                            show-delay="100"
                            hide-delay="100">{{(third[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                        <ng-template #contentData>
                            <span>{{(third[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                        </ng-template>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let third; columns: displayedColumns;"
                [cdkDetailRowDisplayTpl]="third.eFourthData?.length && third?.fourthLevel?.displayResults"
                [cdkDetailRow]="third" [cdkDetailRowTpl]="fourthLevelListingTemplate">
            </mat-row>
        </mat-table>
    </div>
</ng-template>
<ng-template #fourthLevelListingTemplate let-third>
    <div [@detailExpandThird]="third.fourthLevel.displayResults ? 'open' : 'closed'" style="overflow: hidden">
        <mat-table [dataSource]="third.fourthLevel.dataSource" [ngStyle]="{'background-color':'white'}" [ngClass]="">
            <ng-container *ngFor="let header of gridData.columns" matColumnDef="{{header.name}}">
                <mat-header-cell *matHeaderCellDef [hidden]="!header.inView && header.refEnable && header.hidden"
                    mat-sort-header [disabled]="!header.sort" fxFlex="{{header.width}}">
                    {{header.label}}</mat-header-cell>
                <mat-cell *matCellDef="let fourth" [hidden]="!header.inView && header.refEnable && header.hidden"
                    fxFlex="{{header.width}}"
                    [ngClass]="{'accrualDropsCell' : header.tableType === 'accuralDrops' && accrualDropsChecked, 'isHiglighted': (gridData.type === 'Drop Summary' && fourth['isHiglighted'] === 'Y')}"
                    (click)="$event.stopPropagation();">
                    <div fxFlex="100">
                        <span class="text-span" [tooltip]="contentData" content-type="template" placement="top"
                            show-delay="100"
                            hide-delay="100">{{(fourth[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                        <ng-template #contentData>
                            <span>{{(fourth[header.name] | number:'1.0-0' | minusSignToParens)}}</span>
                        </ng-template>
                        <mat-checkbox (click)="$event.stopPropagation()" [checked] = "checkHoldProperty(fourth,header.name)"
                            *ngIf="header.tableType === 'accuralDrops' && accrualDropsChecked && fourth[header.name] > 0 && enableHoldCheckBox"
                            (change)="holdFromDropping(third, fourth, header.name, fourth[header.name], $event)" [disabled]="disableHoldCheckBox">
                        </mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-row *matRowDef="let fourth; columns: displayedColumns;">
            </mat-row>
        </mat-table>
    </div>
</ng-template>
