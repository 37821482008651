<div class="addEstimateContainer">
    <form class="popupForm  media-form" [formGroup]="invoiceForm">
        <div class="popupTitlediv">
            <h1 class="popuptitle" mat-dialog-title>{{popupDatas.header}}
                <button disableRipple mat-icon-button class="close-button float-right" (click)="onCancel()"
                    tabindex="-1">
                    <mat-icon disableRipple class="big-close">close</mat-icon>
                </button>
            </h1>
        </div>

        <div class="popupBodydiv" fxlayout="row" fxlayout.xs="column" fxlayoutwrap="">
            <div class="popup-row-wrapper row1">
                <div class="fieldControl" fxFlex="10">
                    <div>
                      <label>Invoice Id <span class="mandatory">*</span></label>
                    </div>
                    <mat-form-field appearance="outline" class="fieldContainer">
                        <input matInput #invoiceIdField formControlName="invoiceNo" required>
                    </mat-form-field>
                </div>
                <div class="fieldControl" fxFlex="11">
                    <label>Vendor<span class="mandatory"> *</span></label>
                    <!-- <div class="select-wrapper"> -->
                    <mat-form-field appearance="outline" class="select-wrapper fieldContainer">
                        <mat-select disableOptionCentering #vendorSelectElem [(ngModel)]="vendor"
                            [disabled]="isFormReadOnly" [displaySelectboxAroundInput]="vendorSelectElem"
                            panelClass="media-form-select-panel" formControlName="vendorId"
                            (selectionChange)="onVendorSelectionChange(vendor)" required >
                            <mat-option *ngFor="let vendor of vendors" [value]="vendor.vendorId">
                                {{vendor.vendorName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- </div> -->
                    <!-- <input matInput type="text" formControlName="vendorId"> -->
                </div>
                <div class="fieldControl" fxFlex="12">
                    <label>Vendor A/C Number</label>
                    <span class="fieldContainer">{{invoiceForm.value.vendorAccountNo}}</span>
                </div>
                <div class="fieldControl" fxFlex="10">
                    <div>
                      <label class="date-label">Invoice Date <span class="mandatory">*</span></label>
                  </div>
                    <!-- <span class="date-picker-wrapper"> -->
                    <mat-form-field appearance="outline" class="date-wrapper fieldContainer">
                        <input matInput #invoiceDateField [matDatepicker]="picker" formControlName="invoiceDate"
                            required [disabled]="isFormReadOnly" placeholder="mm/dd/yyyy" [min]="minDate"/>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <!-- </span> -->
                </div>
                <div class="fieldControl" fxFlex="14">
                    <label>Header Company Code</label>
                    <span class="fieldContainer">{{invoiceForm.value.companyCode}}</span>
                </div>
                <div class="fieldControl" fxFlex="11">
                    <label>Services Complete</label>
                    <mat-checkbox class="select-wrapper" [disabled]="true || isFormReadOnly"></mat-checkbox>
                </div>
                <div class="fieldControl" fxFlex="15">
                    <label>Income Type</label>
                    <mat-form-field appearance="outline" class="select-wrapper fieldContainer">
                        <mat-select disableOptionCentering #incomeTypeSelectElem [(ngModel)]="incomeType"
                            [disabled]="isFormReadOnly" [displaySelectboxAroundInput]="incomeTypeSelectElem"
                            panelClass="media-form-select-panel" formControlName="incomeType">
                            <mat-option *ngFor="let incomeType of incomeTypes" [value]="incomeType.incomeTypeDesc">
                                {{incomeType.incomeTypeDesc}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="popup-row-wrapper row2">
                <div class="fieldControl">
                    <label>Net Amount</label>
                    <span class="fieldContainer">{{invoiceForm.value.netAmount | currency:'USD':'symbol':'1.2-2' | minusSignToParens}}</span>
                    <!-- <ng-container *ngIf="popupDatas.screen === 'N'; else displayField">
                        <div class="fieldContainer">
                            <span class="amount-symbol">$</span>
                            <mat-form-field appearance="outline" class="amount-input-field">
                                <input matInput appValidationFormat="dec2" class="fieldContainer amountInput"
                                    formControlName="netAmount">
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-template #displayField>
                        <span
                            class="fieldContainer">{{invoiceForm.value.netAmount | currency:'USD':'symbol':'1.2-2' | minusSignToParens}}</span>
                    </ng-template> -->
                </div>
                <div class="fieldControl">
                    <label>Tax Amount</label>
                    <div class="fieldContainer">
                        <span class="amount-symbol">$</span>
                        <mat-form-field appearance="outline" class="amount-input-field">
                            <input matInput type="text" appValidationFormat="dec2" [disabled]="isFormReadOnly"
                                (blur)="onTaxAmountModification($event.currentTarget.value)"
                                class="fieldContainer amountInput" formControlName="taxAmount">
                        </mat-form-field>
                    </div>
                </div>
                <div class="fieldControl">
                    <label>Gross Amount</label>
                    <span
                        class="fieldContainer">{{invoiceForm.value.grossAmount | currency:'USD':'symbol':'1.2-2' | minusSignToParens}}</span>
                </div>
                <div class="fieldControl">
                    <label>Currency Key</label>
                    <span class="fieldContainer">{{invoiceForm.value.currency}}</span>
                </div>
                <div class="fieldControl invoiceField">
                    <label>Invoice Description <span class="mandatory">*</span></label>
                    <mat-form-field appearance="outline" class="fieldContainer" >
                        <textarea matInput class="textarea textareaStyle"
                            formControlName="description" maxlength="255" required>{{invoiceForm.value.description}}</textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="popup-row-wrapper row3">
                <h2>Location of Business</h2>
                <div>
                    <div class="fieldControl" fxFlex="22">
                        <div>
                          <label>State <span class="mandatory">*</span></label>
                      </div>
                        <mat-form-field appearance="outline" class="select-wrapper fieldContainer">
                            <mat-select disableOptionCentering #stateSelectElem [(ngModel)]="state"
                                [disabled]="multipleStatesChecked || isFormReadOnly"
                                [displaySelectboxAroundInput]="stateSelectElem" panelClass="media-form-select-panel"
                                formControlName="state" required>
                                <mat-option *ngFor="let state of states" [value]="state.abbreviation">
                                    {{state.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="fieldControl multipleStates">
                            <label>Multiple States</label>
                            <mat-checkbox #statesCheckbox class=""  [disabled]="isFormReadOnly" formControlName="multipleStates"
                                (change)='multipleStatesChange(statesCheckbox.checked)'></mat-checkbox>
                        </div>
                    </div>
                    <div class="fieldControl" fxFlex="10">
                        <label>Postal Code</label>
                        <mat-form-field appearance="outline" class="fieldContainer">
                            <input matInput formControlName="zip" [disabled]="multipleStatesChecked || isFormReadOnly">
                        </mat-form-field>
                    </div>
                    <div class="fieldControl" fxFlex="12">
                        <label>PACE Status</label>
                        <span class="fieldContainer">{{invoiceForm.value.status}}</span>
                    </div>
                </div>
            </div>
            <h2>Line Items</h2>
            <mat-table formArrayName="ios" [dataSource]="dataSource" class="iosTable">
                <ng-container matColumnDef="ioId">
                    <mat-header-cell *matHeaderCellDef fxFlex="12">
                      <label  class="lineItemsLabel"> IO #<span class="mandatory">*</span></label> </mat-header-cell>
                    <mat-cell *matCellDef="let row let rowIndex = index" class="iosTable-row" [formGroupName]="rowIndex"
                        fxFlex="12">
                        <mat-icon *ngIf="!isFormReadOnly" (click)="deleteIO(rowIndex)" class="close-icon">clear
                        </mat-icon>
                        <ng-template #ioErrorMessage>
                            <div class="">{{invoiceForm.value.ios[rowIndex].ioError}}</div>
                        </ng-template>
                        <mat-icon *ngIf="invoiceForm.value.ios[rowIndex].ioError" class="error-icon"
                            [tooltip]="ioErrorMessage" content-type="template" placement="top" show-delay="100"
                            hide-delay="100">
                            warning
                        </mat-icon>
                        <mat-form-field appearance="outline" fxFlex="73">
                            <input matInput formControlName="ioId" (focus)="onIOFieldFocus($event);"
                                [disabled]="isFormReadOnly"
                                (blur)="onIOFieldBlur($event, rowIndex); $event.stopPropagation(); $event.preventDefault();" required maxlength="10">
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lineItem">
                    <mat-header-cell *matHeaderCellDef fxFlex="8">Line Item #</mat-header-cell>
                    <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="8">
                        <span class="duplicateEstimate">{{invoiceForm.value.ios[rowIndex].lineItem}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef fxFlex="13">
                      <label class="lineItemsLabel">Amount<span class="mandatory">*</span></label> </mat-header-cell>

                    <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="13">
                        $
                        <mat-form-field appearance="outline">
                            <input matInput type="text" appValidationFormat="dec2" size="10" formControlName="amount"
                                [disabled]="isFormReadOnly" class="estimate-amount"
                                (blur)="onAmountAdd($event, rowIndex); $event.stopPropagation(); $event.preventDefault();"
                                 required>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="coCode">
                    <mat-header-cell *matHeaderCellDef fxFlex="7">CO Code </mat-header-cell>
                    <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="7">
                        <span class="duplicateEstimate">{{invoiceForm.value.ios[rowIndex].coCode}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="generalLedgerAccount">
                    <mat-header-cell *matHeaderCellDef fxFlex="14">General Ledger A/C
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="14">
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="generalLedgerAccount" [disabled]="isFormReadOnly"
                                (focus)="onGLAFieldFocus($event);"
                                (blur)="onGLAFieldBlur($event, rowIndex); $event.stopPropagation(); $event.preventDefault();">
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="wbsElement">
                    <mat-header-cell *matHeaderCellDef fxFlex="13">WBS Element
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="13">
                        <mat-form-field appearance="outline" class="select-wrapper fieldContainer">
                            <mat-select disableOptionCentering #wbsSelectElem [disabled]="isFormReadOnly"
                                [displaySelectboxAroundInput]="wbsSelectElem" panelClass="media-form-select-panel"
                                formControlName="wbsElement" (selectionChange)="onWbseSelectionChange(wbs, rowIndex)">
                                <mat-option *ngFor="let wbs of wbss[rowIndex]" [value]="wbs">
                                    {{wbs}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lineItemDescription">
                    <mat-header-cell *matHeaderCellDef fxFlex="27">Description </mat-header-cell>
                    <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="27">
                        <mat-form-field appearance="outline" class="fieldContainer">
                            <textarea matInput class="textarea textarea-lineitem" [disabled]="isFormReadOnly"
                                formControlName="lineItemDescription" maxlength="50">{{invoiceForm.value.ios[rowIndex].lineItemDescription}}</textarea>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isTaxable">
                    <mat-header-cell *matHeaderCellDef fxFlex="6">Taxable</mat-header-cell>
                    <mat-cell *matCellDef="let row let rowIndex = index" [formGroupName]="rowIndex" fxFlex="6">
                        <mat-checkbox formControlName="isTaxable" [disabled]="isFormReadOnly"
                            [checked]="isTaxableChecked(invoiceForm.value.ios[rowIndex].isTaxable)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsIO;sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsIO;"></mat-row>
            </mat-table>
            <button mat-button [disabled]="isFormReadOnly" (click)="addLineItem()"
                class="addBtn secondary-small-btn float-left deleteButton">ADD
                LINE ITEM</button>
            <div id="attachments">
                <h2>Attachments</h2>
                <div class="list-rows" *ngFor="let attachement of attachements; let i = index">
                    <div class="list-row1" *ngIf="attachement.isDeleted !=='Y'">
                        <mat-icon *ngIf="!isFormReadOnly" (click)="deleteAttachment(attachement, i)" class="close-icon">
                            clear</mat-icon>
                        <ng-container *ngIf="!attachement.isAdded; else addedAttachment">
                            <a class="s3File" [href]="attachement.url"
                                (click)="downloadFile(attachement.attachmentFileName); $event.stopPropagation(); $event.preventDefault();">{{attachement.attachmentFileName}}</a>
                        </ng-container>
                        <ng-template #addedAttachment>
                            <a class="localFile" [href]="attachement.fileUrl"
                                download="{{attachement.attachmentFileName}}">{{attachement.attachmentFileName}}</a>
                        </ng-template>
                    </div>
                </div>
                <input type="file" [disabled]="isFormReadOnly" accept="application/pdf" #UploadPdfInputt
                    id="UploadPdfInputt" class="" multiple
                    (change)="onFileSelectt($event); $event.stopPropagation(); $event.preventDefault();" />
                <button mat-button [disabled]="isFormReadOnly"
                    class="addBtn secondary-small-btn float-left deleteButton"><label class="align_center" for="UploadPdfInputt">ADD
                        ATTACHMENT</label></button>
            </div>
            <div id="snapInformation" *ngIf="snapInformation">
                <h2>Snap Information</h2>
                <div class="popup-row-wrapper row1">
                    <div class="fieldControl">
                        <label>Snap Instance Id</label>
                        <span class="fieldContainer">{{snapInformationData.instanceID}}</span>
                    </div>
                    <div class="fieldControl">
                        <label>Status Code</label>
                        <span class="fieldContainer">{{snapInformationData.statusCode}}</span>
                    </div>
                    <div class="fieldControl">
                        <label>Status Description</label>
                        <span class="fieldContainer">{{snapInformationData.statusDecription}}</span>
                    </div>
                    <div class="fieldControl">
                        <label>Accounting Document #</label>
                        <span class="fieldContainer">{{snapInformationData.accountingDocNo}}</span>
                    </div>
                    <div class="fieldControl">
                        <label>Document Type</label>
                        <span class="fieldContainer">{{snapInformationData.documnetType}}</span>
                    </div>
                    <div class="fieldControl">
                        <label>Posting Date</label>
                        <span class="fieldContainer">{{snapInformationData.postingDate}}</span>
                    </div>
                    <div class="fieldControl">
                        <label>Clearing Date</label>
                        <span class="fieldContainer">{{snapInformationData.clearingDate}}</span>
                    </div>
                    <div class="fieldControl">
                        <label>Payment Method</label>
                        <span class="fieldContainer">{{snapInformationData.paymentMethod}}</span>
                    </div>
                </div>
            </div>
            <div id="changeHistory" *ngIf="changeHistory">
                <h2>Change History</h2>
                <div class="list-rows" *ngFor="let history of changeHistoryList">
                    <div class="list-row1">
                        <span>{{history.changedOnDate}},</span>
                        <span class="list-row1-second-element">{{history.changedBy}}</span>
                    </div>
                    <div class="list-row2">{{history.comments}}</div>
                </div>
            </div>
            <ng-container *ngIf="snapApprovalHistory">
                <h2>Snap Approval History</h2>
                <div class="list-rows" *ngFor="let approvalHistory of snapApprovalHistoryData">
                    <div class="list-row1">
                        <span>{{approvalHistory.lastChanged}},</span>
                        <span class="list-row1-second-element">{{approvalHistory.firstName}}
                            {{approvalHistory.lastName}}</span>
                    </div>
                    <div class="list-row2">{{approvalHistory.actionDescription}}</div>
                    <div class="list-row2">{{approvalHistory.comments}}</div>
                </div>
                <mat-spinner *ngIf="loadingIcon" class="loader-icon inlineLoader"></mat-spinner>
            </ng-container>
        </div>
        <div class="action-buttons">
            <!-- <button class="addBtn secondary-small-btn" mat-button (click)="addNewRow()">ADD ROW</button>-->
            <button mat-button *ngIf="screen === 'N' || screen === 'U' || (screen === 'M' && !isFormReadOnly)" (click)="saveInvoice()"
                [disabled]="invoiceForm.invalid || invoiceIdField.value === '' || !saveButtonEnabled"
                class="primary-small-btn float-right">SAVE</button>
          <button mat-button *ngIf="screen === 'U' || (screen === 'M' && !isFormReadOnly)" (click)="deleteInvoice()"
            class="addBtn secondary-small-btn float-right deleteButton">DELETE INVOICE</button>
            <button *ngIf="screen != 'H' && screen === 'F' && screen === 'S' && screen != 'D' && screen !== 'M'" class="cancelBtn float-right" mat-button (click)="onCancel()">CANCEL</button>
            <button *ngIf="screen === 'H' || screen === 'F'|| screen === 'S'|| screen === 'D' || screen === 'M' || screen === 'N'" class="cancelBtn float-right" mat-button (click)="onCancel()">CLOSE</button>        </div>
    </form>
    <app-spinner [loadingIcon]="loadingIcon"></app-spinner>
</div>
