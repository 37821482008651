import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'header-links',
  templateUrl: './header-links.component.html',
  styleUrls: ['./header-links.component.scss'],
})
export class HeaderLinksComponent implements OnInit {
  @Input() public linkItems = [];
  @Input() filterActive = false;
  @Output() emitLinkText: EventEmitter<any> = new EventEmitter();

  links: any[] = [];
  constructor(private router: Router) {}

  ngOnInit(): void {
  }

  linkAction(link) {
    if (link.routerLink) {
      this.router.navigateByUrl(link.routerLink);
    }
    else{
      this.emitLinkText.emit(link.name);
    }

  }
}
