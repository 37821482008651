export const CampaignsConstants = {
  apiUrl: {
    getCampaigns: 'campaign/load',
    masterDataCampaigns: 'campaign/lov',
    saveAddIOs: 'campaign/save/io',
    saveNotes: 'campaign/comments/save',
    getCampaignsIOs: 'campaign/load/details',
    issueSelectedIOs: 'campaign/io/issue',
    updateIO: 'campaign/update/io',
    export: 'aspose/exportIo',
    deleteIO: 'campaign/io/delete',
    importIO: 'aspose/importIO',
    importIOUpdate: 'aspose/bulkUpdate',
    downloadTemplate: 'aspose/downloadIOTemplate'
  }
};
