import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommonService } from '../services/common.service';
import { environment } from '../../environments/environment';
import { AdminConstants } from '../media-management/admin/admin.constants';
import { BehaviorSubject,Subject } from 'rxjs';
import * as moment from 'moment';
import { ExportExcelService } from './exportExcel.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public addRow = new BehaviorSubject(false);
  public enableFilter = new Subject();
  dataForExcel = [];
  private MOCK_ADMIN_JSON_URL: string = '../assets/jsons/admin-get-show-title.json?v=' + Math.random();
  private MOCK_ROLES_JSON_URL: string = '../assets/jsons/roles.json?v=' + Math.random();
  private MOCK_USERS_JSON_URL: string = '../assets/jsons/get-users.json?v=' + Math.random();
  private MOCK_GENRE_JSON_URL: string = '../assets/jsons/get-genre.json?v=' + Math.random();
  private MOCK_MEDIATYPE_JSON_URL: string = '../assets/jsons/get-mediaType.json?v=' + Math.random();
  private MOCK_DISTRIBUTION_JSON_URL: string = '../assets/jsons/fetch-distribution.json?v=' + Math.random();
  private MOCK_USERACCESS_JSON_URL: string = '../assets/jsons/user-access.json?v=' + Math.random();

  constructor(private http: HttpClient, private cS: CommonService, private eES: ExportExcelService) {
  }

  fetchConfigData() {
    const url = environment.adminBasePath + AdminConstants.apiUrl.getConfigTable;
    return this.cS.serviceRequestCommon('get', url);
    // return this.cS.serviceRequestCommon('get', this.MOCK_ADMIN_JSON_URL);
  }

  fetchGenreData() {
    const url = environment.adminBasePath + AdminConstants.apiUrl.getGenre;
    return this.cS.serviceRequestCommon('get', url);
    // return this.cS.serviceRequestCommon('get', this.MOCK_GENRE_JSON_URL);
  }

  fetchUserData() {
    const url = environment.adminBasePath + AdminConstants.apiUrl.getUserTable;
    return this.cS.serviceRequestCommon('get', url);
    // return this.cS.serviceRequestCommon('get', this.MOCK_USERS_JSON_URL);
  }

  getUserDetails(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.userDetails;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  saveUser(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.saveUser;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateUser(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.updateUser;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  fetchUserLov() {
    const url = environment.userBasePath + AdminConstants.apiUrl.userLov;
    return this.cS.serviceRequestCommon('get', url);
  }

  fetchMasterData() {
    // const jsonUrl = '../assets/jsons/config-master-data.json?v=' + Math.random();
    // return this.cS.serviceRequestCommon('get', jsonUrl);
    const url = environment.adminBasePath + AdminConstants.apiUrl.masterDataAdmin;
    return this.cS.serviceRequestCommon('get', url);
  }

  saveShow(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.saveShow;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateShow(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.updateShow;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  fetchRoleMasterData(){
    const url = environment.userBasePath + AdminConstants.apiUrl.rolesMasterData;
    return this.cS.serviceRequestCommon('get', url);
  }

  fetchRoleData(payLoad){
    const url = environment.userBasePath + AdminConstants.apiUrl.getRoleData;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  addRoleEntitlements(payLoad){
    const url = environment.userBasePath + AdminConstants.apiUrl.addRoleModuleEntitlements;
    console.log(url, payLoad);
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateRoleEntitlements(payLoad){
    const url = environment.userBasePath + AdminConstants.apiUrl.updateRole;
    console.log(url, payLoad);
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  addNewRole(payLoad){
    const url = environment.userBasePath + AdminConstants.apiUrl.addRole;
    console.log(url, payLoad);
    return this.cS.serviceRequestCommon('post', url, '', payLoad);

  }

  addSeries(payLoad) {
    // const jsonUrl = '../assets/jsons/add-series.json?v=' + Math.random();
    // const jsonUrl = '../assets/jsons/duplicate-series.json?v=' + Math.random();.
    // return this.cS.serviceRequestCommon('get', jsonUrl);
    const url = environment.adminBasePath + AdminConstants.apiUrl.addSeries;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  addSeasonType(payLoad) {
    // const jsonUrl = '../assets/jsons/add-seasonType.json?v=' + Math.random();
    // const jsonUrl = '../assets/jsons/duplicate-seasonType.json?v=' + Math.random();
    // return this.cS.serviceRequestCommon('get', jsonUrl);
    const url = environment.adminBasePath + AdminConstants.apiUrl.addSeasonType;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  deleteSeries(payLoad) {
    // const jsonUrl = '../assets/jsons/add-series.json?v=' + Math.random();
    // return this.cS.serviceRequestCommon('get', jsonUrl);
    const url = environment.adminBasePath + AdminConstants.apiUrl.deleteSeries;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  deleteSeasonType(payLoad) {
    // const jsonUrl = '../assets/jsons/add-series.json?v=' + Math.random();
    // return this.cS.serviceRequestCommon('get', jsonUrl);
    const url = environment.adminBasePath + AdminConstants.apiUrl.deleteSeasonType;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  saveGenre(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.saveGenre;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateGenre(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.updateGenre;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  addVendor(payLoad){
    const url = environment.adminBasePath + AdminConstants.apiUrl.addVendor;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateVendor(payLoad){
    const url = environment.adminBasePath + AdminConstants.apiUrl.updateVendor;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  fetchVendor(){
    const url = environment.userBasePath + AdminConstants.apiUrl.getVendor;
    return this.cS.serviceRequestCommon('get', url);
  }

  fetchMediaType() {
    const url = environment.userBasePath + AdminConstants.apiUrl.getMediaType;
    return this.cS.serviceRequestCommon('get', url);
    // return this.cS.serviceRequestCommon('get', this.MOCK_MEDIATYPE_JSON_URL);
  }

  fetchMediaTypeLov() {
    const url = environment.userBasePath + AdminConstants.apiUrl.mediaLov;
    return this.cS.serviceRequestCommon('get', url);
  }

  savMediaType(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.saveMediaType;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateMediaType(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.updateMediaType;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  deleteMediaType(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.deleteMediaType;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  getDistributionMediaTypes() {
    // const jsonUrl = '../assets/jsons/get-distribution-mediaTypes.json?v=' + Math.random();
    // return this.cS.serviceRequestCommon('get', jsonUrl);
    const url = environment.adminBasePath + AdminConstants.apiUrl.getDistributionMediaTypes;
    return this.cS.serviceRequestCommon('get', url);
  }

  fetchDistribution() {
    const url = environment.userBasePath + AdminConstants.apiUrl.distributionSearch;
    return this.cS.serviceRequestCommon('get', url);
    // return this.cS.serviceRequestCommon('get', this.MOCK_DISTRIBUTION_JSON_URL);
  }


  saveDistribution(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.saveDistribution;
    return this.cS.serviceRequestCommon('post', url, '', payLoad);
  }

  updateDistribution(payLoad) {
    const url = environment.adminBasePath + AdminConstants.apiUrl.updateDistribution;
    return this.cS.serviceRequestCommon('put', url, '', payLoad);
  }

  getUserAccessAll(){
    return this.cS.serviceRequestCommon('get', this.MOCK_USERACCESS_JSON_URL);
  }

  // exportHandlerService(payLoad) {
  //   const url = environment.basePath + AdminConstants.apiUrl.adminExport;
  //   this.cS.fileDownload(url, payLoad).subscribe((res: HttpResponse<any>) => {
  //     const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
  //     const a = document.createElement('a');
  //     const file = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     a.href = URL.createObjectURL(file);
  //     a.download = filename;
  //     a.click();
  //     // this.outputElem.nativeElement.click();
  //   }); }

  exportHandlerService(data, removeColumns, headers, fileName, worksheetName) {
    this.dataForExcel =[];
    data.forEach((row: any) => {
      if (removeColumns.length > 0){
        removeColumns.forEach(element => {
          if (row.hasOwnProperty(element)){
            delete row[element];
          }
        });
      }
      // console.log(Object.keys(row));
      this.dataForExcel.push(Object.values(row));
    });

    // const headers = [ { name: 'Show Name', width: 35 }, { name: 'Genre Name', width: 20 }, { name: 'Season Type', width: 20 },
    //                   { name: 'Year', width: 8 }, { name: 'Start Date', width: 15 }, { name: 'End Date', width: 15 },
    //                   { name: 'Issued IO Count', width: 20 }, { name: 'Issued Commitments', width: 22 },
    //                   { name: 'Draft IO Count', width: 16 }, { name: 'Draft Commitments', width: 20 },
    //                   { name: 'Total Commitment Amount', width: 27 }, { name: 'Comments', width: 35 }
    //                 ];

    // new Date('MM-DD-YYYY-HH-MM-ss-a')
    const now = moment().format('MM-DD-YYYY-hh-mm-ss-A');

    const reportData = {
      title: fileName + now,
      data: this.dataForExcel,
      headers,
      worksheetName: 'Show Title Data'
    };
    this.eES.exportExcel(reportData);
  }
}
