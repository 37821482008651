import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {


  constructor() { }

  exportExcel(excelData) {

    // Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const data = excelData.data;

    // Create a workbook with a worksheet
    const workbook = new Workbook();
    const worksheet = excelData.worksheetName ? workbook.addWorksheet(excelData.worksheetName) : workbook.addWorksheet('Data');


    // Add Row and formatting
    // worksheet.mergeCells('C1', 'F4');
    // const titleRow = worksheet.getCell('C1');
    // titleRow.value = title;
    // titleRow.font = {
    //   name: 'Calibri',
    //   size: 16,
    //   underline: 'single',
    //   bold: true,
    //   color: { argb: '0085A3' }
    // };
    // titleRow.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    // worksheet.mergeCells('G1:H4');
    // const d = new Date();
    // const date = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
    // const dateCell = worksheet.getCell('G1');
    // dateCell.value = date;
    // dateCell.font = {
    //   name: 'Calibri',
    //   size: 12,
    //   bold: true
    // };
    // dateCell.alignment = { vertical: 'middle', horizontal: 'center' };

    // // Add Image
    // const myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    // Blank Row
    worksheet.addRow([]);

    // Adding Header Row
    const headerNames = [];
    header.forEach(row => {
      headerNames.push(row.name);
    });
    const headerRow = worksheet.addRow(headerNames);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffcc99' },
        bgColor: { argb: '' }
      };
      cell.font = {
        // bold: true,
        // color: { argb: 'FFFFFF' },
        size: 12
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      const row = worksheet.addRow(d);

      // const sales = row.getCell(6);
      // let color = 'FF99FF99';
      // // if (+sales.value < 200000) {
      // //   color = 'FF9999';
      // // }

      // sales.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: color }
      // };
    }
    );
    worksheet.columns.forEach(cols => {
      header.forEach(column => {
        if (column.name === cols.values[2]) { cols.width = column.width; }
        // console.log('cols', cols.values[2]);
      });
    });
    // worksheet.getColumn(3).width = 20;
    worksheet.addRow([]);

    // // Footer Row
    // const footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    // Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = title + '.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      // fs.saveAs(blob, title + '.xlsx');
    });

  }
}
