import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IMonthPickerDetail } from '../../../models/multi-month-picker.model';

@Component({
  selector: 'app-month-picker',
  templateUrl: './app-month-picker.component.html',
  styleUrls: ['./app-month-picker.component.scss']
})

export class AppMonthPickerComponent implements OnInit {
  public monthYearSelected: IMonthPickerDetail;
  public enablePicker = false;
  oneMonthOnly = true;
  @Input() raisedMonthPicker?: boolean;
  @Input() createdBy?: string = '';
  @Input() selectedValue: any = null;
  @Output() monthRangeSelected = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.initMonthPicker();
    this.enablePicker = true;
  }

  initMonthPicker() {
    if(this.selectedValue){
      this.monthYearSelected = this.selectedValue;
    }
    else{
      const date = new Date();
      const monthRange = [];
      monthRange.push(('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear());
      this.monthYearSelected = {
        year: date.getFullYear(),
        firstMonth: date.getMonth() + 1,
        secondMonth: null,
        quarter: this.findQuarter(),
        isMonthViewSelected: true,
        monthRange,
        selectedDisplay: date.toLocaleDateString(undefined, { month: 'short' }) + '. ' + date.getFullYear()
      } as IMonthPickerDetail;
    }
    this.triggerCall(this.monthYearSelected);
  }

  findQuarter() {
    const today = new Date();
    const quarter = Math.floor(today.getMonth() / 3) + 1;
    return quarter;
  }

  shiftMonthQuarter(shiftType, shiftNumber) {
    this.enablePicker = false;
    if (this.monthYearSelected.secondMonth == null) {
      if ((shiftType === 'next' && this.monthYearSelected.firstMonth === 12)
        || (shiftType === 'prev' && this.monthYearSelected.firstMonth === 1)) {
        this.monthYearSelected.year = this.monthYearSelected.year + shiftNumber;
        this.monthYearSelected.firstMonth = (this.monthYearSelected.firstMonth === 12) ? 1 : 12;
      }
      else {
        this.monthYearSelected.firstMonth = this.monthYearSelected.firstMonth + shiftNumber;
      }
      // to keep quarter picker in sync with month picker
      this.monthYearSelected.quarter = Math.floor(((this.monthYearSelected.firstMonth - 1) / 3)) + 1;
      this.setMonthPickerDisplay();
    }
    this.triggerCall(this.monthYearSelected);
  }

  setMonthPickerDisplay() {
    this.monthYearSelected.selectedDisplay = new Date(`${this.monthYearSelected.firstMonth}/1`).toLocaleDateString(undefined, { month: 'long' }) + '. ' + this.monthYearSelected.year;
    this.monthYearSelected.monthRange = [('0' + this.monthYearSelected.firstMonth).slice(-2) + '-' + this.monthYearSelected.year];
    setTimeout(() => {
      this.enablePicker = true;
    }, 0);
  }

  triggerCall(data) {
    // console.log(4231, data);
    this.monthRangeSelected.emit(data);
  }

}
