import { IEstimatesDetail } from '../models/estimates.model';

export class AddEstimates {
    static readonly type = '[Estimates] Add';

    constructor(public payload: IEstimatesDetail[]) {
    }
}

export class GetEstimates {
    static readonly type = '[Estimates] Get';

    constructor(public payload: any) {
    }
}

export class SetSelectedMultiMonth {
    static readonly type = '[MultiMonth] Set';

    constructor(public payload: any) {
    }
}

export class SetClickedHeaderLink {
  static readonly type = '[HeaderLink] Set';
  constructor(public payload: any) {
  }
}

export class SetViewBy {
    static readonly type = '[ViewBy] Set';
    constructor(public payload: any) {
    }
}
