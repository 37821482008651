export const InvoiceConstants = {
    apiUrl: {
        uploadInvoices: 'aspose/uploadInvoice',
        importInvoices: 'invoices/import',
        cancelImportInvoices: 'invoices/cancel/',
        mediaReviewInvoices: 'invoices/io/M',
        directorReviewInvoices: 'invoices/io/D',
        FinanceReviewInvoices: 'invoices/io/F',
        snapReviewInvoices: 'invoices/io/S',
        createInvoice: 'invoices/invoice',
        fetchInvoiceDetails: 'invoices/invoice/',
        updateInvoice: 'invoices/invoice/',
        deleteInvoice: 'invoices/delete',
        fetchIOData: 'invoices/ioInformation/',
        fetchInvoicesMasterData: 'invoices/lov',
        fetchInvoicesByTransactionId: 'invoices/',
        approveInvoices: 'invoices/approve',
        declineInvoices: 'invoices/decline',
        exportInvoices: 'aspose/exportInvoice/',
        preSignedPutFile: 'invoices/upload/url',
        preSignedGetFile: 'invoices/document/url/',
        fetchInvoiceHistory: 'invoices/history',
        submitToSNAP: 'invoices/submitToSNAP',
        approvalHistory: 'invoices/approvalHistory/',
        downloadTemplate: 'invoices/template',
        balanceInformation: 'invoices/balanceInformation/'
    }
};
