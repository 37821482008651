import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, Form } from '@angular/forms';
import { MatTableDataSource, MatTableModule, MatTable } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInvoiceDetail, IIODetail } from '../../../models/invoice.model';
import { InvoiceService } from '../../../services/invoice.service';
import { CommonService } from '../../../services/common.service';
import { AppUtilityService } from '../../../services/utility.service';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { int } from 'aws-sdk/clients/datapipeline';

@Component({
  selector: 'app-app-invoice',
  templateUrl: './app-invoice.component.html',
  styleUrls: ['./app-invoice.component.scss']
})
export class AppInvoiceComponent implements OnInit {
  // @Input() addEstimateDetail: any;
  displayedColumns: string[];
  dataSource: IInvoiceDetail[] = [];
  invoiceForm: FormGroup;
  displayedColumnsIO = ["ioId", "lineItem", "amount", "coCode", "generalLedgerAccount", "wbsElement", "isTaxable", "lineItemDescription"];
  vendors;
  vendor;
  incomeTypes;
  incomeType;
  states;
  state;
  multipleStatesChecked = false;
  screens = ["N", "U", "M", "D", "F", "S", "H"];
  screen;
  lineItemNumber;
  wbsElement;
  wbs;
  ioFieldFocussedValue;
  glaFieldFocussedValue;
  wbss = [];
  fileList = [];
  nameList = [];
  public wrongPdfFormat = false;
  isFormReadOnly = false;
  attachements = [];
  snapInformation = false;
  snapInformationData;
  minDate: Date; //Added for Minimum date validation
  netAmount: number;
  saveButtonEnabled = false;

  changeHistory = false;
  changeHistoryList;
  snapApprovalHistory = false;
  snapApprovalHistoryData;
  loadingIcon = false;
  deletedIos = [];
  @Output() invoiceUpdateEmit: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public popupDatas: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AppInvoiceComponent>,
    private sanitizer: DomSanitizer,
    private invoiceService: InvoiceService,
    private cS: CommonService,
    private uS: AppUtilityService) {
    //Added for Minimum date validation
    this.minDate = new Date("2019-01-01");
    this.netAmount = 0;
  }

  ngOnInit(): void {
    this.isFormReadOnly = this.popupDatas.isFormReadOnly ? true : false;
    if (this.popupDatas.screen === 'D' || this.popupDatas.screen === 'F' || this.popupDatas.screen === 'S') {
      this.isFormReadOnly = true;
    }
    if (this.popupDatas.screen === 'H') {
      this.loadingIcon = true;
      this.snapApprovalHistory = true;
      this.invoiceService.fetchApprovalHistory(this.popupDatas.initValue.invoiceId).subscribe((res: any) => {
        this.loadingIcon = false;
        if (typeof res !== 'undefined' && res.data !== null && res.data.length) {
          this.snapApprovalHistoryData = res.data;
        }
      }, (error: any) => {
        this.loadingIcon = false;
        console.log(error, this.popupDatas.initValue.invoiceId);
      });
      this.isFormReadOnly = true;
    }
    if (this.popupDatas.snapInformation) {
      this.snapInformation = true;
      this.snapInformationData = this.popupDatas.snapInformation;
    }
    if (this.popupDatas.changeHistory) {
      this.changeHistory = true;
      this.changeHistoryList = this.popupDatas.changeHistory;
    }
    this.setMasterData();
    this.invoiceForm = this.formBuilder.group({
      invoiceNo: new FormControl({ value: '', disabled: this.isFormReadOnly }),
      vendorId: new FormControl(),
      vendorAccountNo: new FormControl(),
      invoiceDate: new FormControl(),
      companyCode: new FormControl(),
      taxAmount: new FormControl({ value: '', disabled: this.isFormReadOnly }),
      grossAmount: new FormControl(),
      currency: new FormControl(),
      netAmount: new FormControl(''),
      description: new FormControl({ value: '', disabled: this.isFormReadOnly },Validators.required),

      incomeType: new FormControl({ value: '', disabled: this.isFormReadOnly }),
      state: new FormControl(''),
      multipleStates: new FormControl(''),
      zip: new FormControl({ value: null, disabled: this.isFormReadOnly }),
      status: new FormControl(''),
      ios: this.formBuilder.array([]),
    });
    this.invoiceForm.controls.description.markAsTouched();
    // this.popupDatas.screen = 'N';  // Will be sent on click of Create Single Invoice link
    if (this.popupDatas.screen === 'N') {
      this.createNewInvoiceDefault();
    } else {
      this.loadValues(this.popupDatas.initValue);
      this.setInvoiceForm(this.popupDatas.initValue);
    }
  }

  createNewInvoiceDefault() {
    this.invoiceForm.controls['netAmount'].setValue(0);
    this.invoiceForm.controls['grossAmount'].setValue(0);
    this.invoiceForm.controls['companyCode'].setValue("B0A1");
    this.invoiceForm.controls['currency'].setValue("USD");
    // Adding default values for incomtype , state and postal code for create single invoice
    this.incomeType = 'Services';
    this.state = 'CA';
    this.invoiceForm.controls['incomeType'].setValue('Services');
    this.invoiceForm.controls['state'].setValue('CA');
    this.invoiceForm.controls['zip'].setValue('91608');
  }

  onAmountAdd(e, index) {
    this.calculateNetAmount();
  }

  calculateNetAmount() {
    const taxamount = this.invoiceForm.controls['taxAmount'].value;
    this.netAmount = 0;
    const formCtrl = this.invoiceForm.get('ios') as FormArray
    let formValue = this.invoiceForm.value;
    let copyLineItems = this.iosArrayForSave(formValue.ios);
    for (let i = 0; i < copyLineItems.length; i++) {
      this.netAmount += Number(copyLineItems[i].amount)
    }
    this.invoiceForm.controls['netAmount'].setValue(this.netAmount);
    this.invoiceForm.controls['grossAmount'].setValue(this.netAmount - taxamount);
  }

  loadValues(data) {
    this.attachements = data.attachements;
    this.saveButtonEnabled = data.ioList.length ? true: false;
    data.ioList.forEach(data => {
      this.dataSource.push(this.initInvoice(data));
    });
  }

  initInvoice(data?) {
    let newIO;
    if (data) {
      newIO = {
        ioError: data.ioError,
        ioId: data.ioId,
        lineItem: data.lineItem,
        amount: data.amount,
        isDeleted: "N",
        coCode: data.coCode,
        generalLedgerAccount: data.generalLedgerAccount,
        wbsElement: data.wbsElement,
        isTaxable: data.isTaxable,
        lineItemDescription: data.lineItemDescription
      } as IIODetail;
    } else {
      newIO = {
        ioError: '',
        ioId: null,
        lineItem: this.lineItemNumber,
        amount: null,
        isDeleted: "N",
        coCode: null,
        generalLedgerAccount: null,
        wbsElement: null,
        isTaxable: null,
        lineItemDescription: null,
        isAdded: true
      } as IIODetail;
    }
    return newIO;
  }

  setInvoiceForm(data) {
    this.invoiceForm.controls['invoiceNo'].setValue(data.invoiceId);
    this.vendor = data.vendorId; // this.getVendorAttributeValue(data.vendorId, 'vendorId');// this.vendors.find(obj => obj.vendorId === data.vendorId)['vendorId'];
    this.invoiceForm.controls['vendorId'].setValue(data.vendorId);
    this.invoiceForm.controls['vendorAccountNo'].setValue(data.vendorAccountNo);
    this.invoiceForm.controls['invoiceDate'].setValue(new Date(data.invoiceDate));
    this.invoiceForm.controls['companyCode'].setValue(data.companyCode);

    this.invoiceForm.controls['netAmount'].setValue(data.netAmount);
    this.invoiceForm.controls['taxAmount'].setValue(data.taxAmount);
    this.invoiceForm.controls['grossAmount'].setValue(data.grossAmount);
    this.invoiceForm.controls['currency'].setValue(data.currency);
    this.invoiceForm.controls['description'].setValue(data.description);
    // this.vendors.find(obj => obj.id === data.incomeType)
    this.incomeType = data.incomeType;

    this.state = data.state;
    this.invoiceForm.controls['incomeType'].setValue(data.incomeType);
    // this.states.find(obj => obj.abbreviation === data.state)['abbreviation']
    this.invoiceForm.controls['state'].setValue(data.state);
    if (data.state === 'MU') {
      this.invoiceForm.controls['multipleStates'].setValue('checked');
      this.multipleStatesChecked = true;
    }
    this.invoiceForm.controls['zip'].setValue(data.zip);
    this.invoiceForm.controls['status'].setValue(data.status);

    const userCtrl = this.invoiceForm.get('ios') as FormArray;
    this.dataSource.forEach((ioItem, index) => {
      userCtrl.push(this.setInvoiceFormArray(ioItem, index));
      // userCtrl.controls[index]['controls']['wbsElement'].setValue(ioItem['wbsElement'][0]);
    });
  }

  private setInvoiceFormArray(ioItem, index) {

    this.wbss[index] = ioItem.wbsElement;
    return this.formBuilder.group({
      ioError: [{ value: '', disabled: this.isFormReadOnly }],
      ioId: [{ value: ioItem.ioId, disabled: this.isFormReadOnly }, Validators.required],
      lineItem: ioItem.lineItem,
      amount: [{ value: ioItem.amount, disabled: this.isFormReadOnly }],
      isDeleted: [{ value: ioItem.isDeleted, disabled: this.isFormReadOnly }],
      coCode: [{ value: ioItem.coCode, disabled: this.isFormReadOnly }],
      generalLedgerAccount: [{ value: ioItem.generalLedgerAccount, disabled: this.isFormReadOnly }],
      wbsElement: ioItem.wbsElement === null ? [null] : [ioItem.wbsElement[0]],
      lineItemDescription: [{ value: ioItem.lineItemDescription, disabled: this.isFormReadOnly }],
      isTaxable: [this.getTaxableConverted(ioItem.isTaxable)]
    });
  }

  setMasterData() {
    this.screen = this.popupDatas.screen;
    this.vendors = this.popupDatas.masterLOVData?.vendorList;
    this.incomeTypes = this.popupDatas.masterLOVData?.incomeType;
    this.states = this.popupDatas.USAStates;
  }

  onIOFieldFocus(e) {
    e.stopPropagation();
    e.preventDefault();
    this.ioFieldFocussedValue = e.currentTarget.value;
  }

  onIOFieldBlur(e, index) {
    if (this.ioFieldFocussedValue !== e.currentTarget.value) { // Get IO details
      this.invoiceService.fetchIOData(e.currentTarget.value).subscribe((response: any) => {
        this.updateLineItemFields(response, index);
        this.calculateNetAmount();
      }, (error: any) => {
        console.log(error);
        if (error.error.errorCode === "I00203") {
          this.ioFieldErrorIndicate(index, error.error.errorDesc);
        }
      });
    }
  }

  setGLAccountWBSEError(response, index) {
    const errResp = response.item.filter(obj => obj.type === 'E');
    let errorMessages = '';
    const userCtrl = this.invoiceForm.get('ios') as FormArray;
    if (errResp.length) {
      errResp.forEach(err => {  // set error indicator
        errorMessages = errorMessages + err.message + '. ';
        const errorField = err.message.indexOf('WBS') > -1 ? 'wbsElement' : 'generalLedgerAccount';
        userCtrl.controls[index]['controls'][errorField].setErrors({ 'incorrect': true });
      });
      userCtrl.controls[index]['controls']['ioError'].setValue(errorMessages);
    } else {  // reset error indicator
      userCtrl.controls[index]['controls']['wbsElement'].setErrors(null);
      userCtrl.controls[index]['controls']['generalLedgerAccount'].setErrors(null);
      userCtrl.controls[index]['controls']['ioError'].setValue('');
    }
  }

  onGLAFieldFocus(e) {
    e.stopPropagation(); e.preventDefault();
    this.glaFieldFocussedValue = e.currentTarget.value;
  }

  onGLAFieldBlur(e, index) {
    if (this.glaFieldFocussedValue !== e.currentTarget.value) { // Validate
      const url = `&GL_ACCOUNT=${e.currentTarget.value}&WBS_ELEMENT=${this.invoiceForm.controls['ios']['controls'][index]['controls']['wbsElement'].value}`;
      this.cS.validateGLAccountWBSEWithSAP(url).subscribe((response: any) => {
        if (response.item) {
          this.setGLAccountWBSEError(response, index);
        }
      }, (error: any) => {
        console.log(error);
      });
    }
  }

  onWbseSelectionChange(wbse, index) {
    const userCtrl = this.invoiceForm.get('ios') as FormArray;
    const url = `&GL_ACCOUNT=${userCtrl.controls[index]['controls']['generalLedgerAccount'].value}&WBS_ELEMENT=${userCtrl.controls[index]['controls']['wbsElement'].value}`;
    this.cS.validateGLAccountWBSEWithSAP(url).subscribe((response: any) => {
      if (response.item) {
        this.setGLAccountWBSEError(response, index);
      }
    }, (error: any) => {
      console.log(error);
    });
  }

  ioFieldErrorIndicate(index, error) {
    const userCtrl = this.invoiceForm.get('ios') as FormArray;
    userCtrl.controls[index]['controls']['ioId'].setErrors({ 'incorrect': true });
    userCtrl.controls[index]['controls']['ioError'].setValue(error);
  }

  updateLineItemFields(ioData, index) {
    const userCtrl = this.invoiceForm.get('ios') as FormArray;
    this.wbss[index] = ioData.wbsElement;
    userCtrl.controls[index]['controls']['ioError'].setValue('');
    userCtrl.controls[index]['controls']['ioId'].setValue(ioData.ioId);
    //userCtrl.controls[index]['controls']['amount'].setValue(ioData.amount);
    userCtrl.controls[index]['controls']['coCode'].setValue(ioData.coCode);
    userCtrl.controls[index]['controls']['generalLedgerAccount'].setValue(ioData.generalLedgerAccount);
    userCtrl.controls[index]['controls']['wbsElement'].setValue(ioData.wbsElement[0]);
    userCtrl.controls[index]['controls']['lineItemDescription'].setValue(ioData.lineItemDescription);
    userCtrl.controls[index]['controls']['isTaxable'].setValue(this.getTaxableConverted(ioData.isTaxable));
  }

  addLineItem() {
    this.saveButtonEnabled=true;
    const formCtrl = this.invoiceForm.get('ios') as FormArray;
    this.lineItemNumber = this.getlineItemNumber();
    const newIO = this.initInvoice();
    this.dataSource.push(newIO);
    formCtrl.push(this.setInvoiceFormArray(newIO, this.dataSource.length));
    this.table.renderRows();
  }

  getlineItemNumber() {
    return this.invoiceForm.value.ios.length === 0 ? 1 : this.invoiceForm.value.ios[this.invoiceForm.value.ios.length - 1].lineItem + 1;
  }

  // getVendor()
  onVendorSelectionChange(vendor) {
    this.invoiceForm.controls['vendorAccountNo'].setValue(this.getVendorAttributeValue(vendor, 'vendorAccountNo'));
  }

  onIncomeTypeAddition(incomeType) {
    this.incomeTypes.push({ "incomeTypeDesc": incomeType });
  }

  getVendorAttributeValue(vendorId, attr) {
    return this.vendors.find(obj => obj.vendorId === vendorId)[attr];
  }

  onTaxAmountModification(taxAmount) {
    const grossAmount = this.invoiceForm.controls['netAmount'].value - taxAmount;
    this.invoiceForm.controls['grossAmount'].setValue(grossAmount);
  }

  getTaxableConverted(isTaxable) {
    return isTaxable === "Y" ? true : false
  }

  isTaxableChecked(isChecked) {
    return isChecked === true ? true : false;
  }


  convertTaxableForSave(lineItems) {
    /* Iterate lineItems and convert isTaxable attribute for SAVE */
    // let convertedLineItems = lineItems.slice(0);
    const ar3: any[] = [];
    let convertedLineItems = ar3.concat(lineItems);
    convertedLineItems.forEach((lineItem, i) => {
      convertedLineItems[i]['isTaxable'] = lineItem.isTaxable === true ? "Y" : "N";
      if (typeof convertedLineItems[i]['isAdded'] !== 'undefined') {
        delete convertedLineItems[i]['isAdded'];
      }
    });
    return convertedLineItems;
  }

  multipleStatesChange(checked) {
    if (checked) {
      // disable
      this.multipleStatesChecked = true;
      this.invoiceForm.controls['state'].setValue('MU');
      this.invoiceForm.controls['zip'].setValue(null);
    } else {
      // enable
      this.multipleStatesChecked = false;
      this.invoiceForm.controls['state'].setValue('');
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  deleteIO(rowIndex) {
    const userCtrl = this.invoiceForm.get('ios') as FormArray;
    if (typeof this.dataSource[rowIndex]['isAdded'] !== 'undefined') {
      this.dataSource.splice(rowIndex, 1);
      userCtrl.removeAt(rowIndex);
    } else {
      this.dataSource[rowIndex]['isDeleted'] = 'Y';
      this.deletedIos.push(this.dataSource[rowIndex]);
      this.dataSource.splice(rowIndex, 1);
      // userCtrl.controls[rowIndex]['controls']['isDeleted'].setValue("Y");
      userCtrl.removeAt(rowIndex);
    }
    this.calculateNetAmount();
    this.table.renderRows();
    this.saveButtonEnabled = this.dataSource.length ? true: false;
  }

  onFileSelectt(event) {
    const pdf = ['application/pdf'];
    if (event.target.files.length > 0) {
      const fileArr = [];
      const files = event.target.files;
      const fileNames = [];
      Array.from(files).forEach((file, index) => {
        const fileContent = event.target.files[index];
        if (_.includes(pdf, fileContent.type)) {
          fileArr.push(fileContent);
          fileNames.push(fileContent.name);
          this.nameList.push(fileContent.name);
        }
      });
      if (fileArr.length <= 0) {
        this.wrongPdfFormat = true;
        // alert('Only pdf Docs Allowed!');
      } else {
        this.wrongPdfFormat = false;
        this.fileList = [...fileArr];
        this.addToAttachements(fileNames, this.fileList);
      }
    }
  }

  addToAttachements(fileNames, fileListObj) {
    fileNames.forEach(fileName => {
      const fileList = fileListObj.filter(obj => obj.name === fileName);
      if (this.popupDatas.screen === 'N') {
        const blob = new Blob(fileList, { type: 'application/pdf' });
        const fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        this.attachements.push({ attachmentFileName: fileName, fileUrl: fileUrl, isAdded: true });
      } else {
        const blob = new Blob(fileList, { type: 'application/pdf' });
        const fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        this.attachements.push({ attachmentFileName: fileName, fileUrl: fileUrl, isDeleted: 'N', isAdded: true });
      }
    });
  }

  deleteAttachment(attachement, i) {
    this.attachements[i].isDeleted = 'Y';
    // this.attachements.filter(obj => {if(obj.id === attachement.id) {obj.isDeleted = 'Y'}});
  }

  uploadRequiredFilesToS3(uploadAttachmentList) {
    // const list = this.attachements.filter(obj => obj.isAdded === true);
    this.invoiceService.preSignedPutFile(uploadAttachmentList.map(a => a.attachmentFileName)).subscribe((res: any) => {
      res.forEach((obj, i) => {
        const filetoUpload = this.fileList.find(file => file.name === obj.fileName);
        this.invoiceService.uploadfileAWSS3(res[i].putUrl, 'application/pdf', filetoUpload).subscribe(ress => {
          this.loadingIcon = false;
          this.dialogRef.close();
        })
      });
    });
  }

  downloadFile(fileName) {
    this.invoiceService.preSignedGetFile(fileName).subscribe((res: any) => {
      if (typeof res !== 'undefined') {
        this.invoiceService.downloadPdfFile(res.url).subscribe(
          blob => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(blob)
            a.href = objectUrl
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(objectUrl);
          }
        )
     }
    });
  }

  getAttachments() {
    const deleted = this.attachements.filter(obj => obj.isDeleted === 'Y');
    deleted.forEach(obj => delete obj.url);
    const newArr = this.attachements.filter(obj => typeof obj.isAdded !== 'undefined');
    newArr.forEach(obj => {
      delete obj.isAdded;
      delete obj.fileUrl;
    });
    const respFormat = deleted.concat(newArr);
    return respFormat;
  }

  deleteInvoice() {
    this.loadingIcon = true;
    this.invoiceService.deleteInvoice({ "invoiceNo": this.invoiceForm.value.invoiceNo }, this.popupDatas.screen).subscribe(res => {
      this.loadingIcon = false;
      this.invoiceUpdateEmit.emit('delete');
      this.dialogRef.close();
    });
  }

  saveInvoiceFn(){
    let formValue         = this.invoiceForm.value;
    let invoiceDateObject = new Date(formValue.invoiceDate);
    let invoiceDate:any   = invoiceDateObject.getDate();
    invoiceDate           = (invoiceDate < 10)?'0'+invoiceDate:invoiceDate;
    let invoiceMonth:any  = invoiceDateObject.getMonth();
    invoiceMonth          = ( (invoiceMonth+1) < 10)?'0'+(invoiceMonth+1):(invoiceMonth+1);
    let invoiceYear       = invoiceDateObject.getFullYear();
    var saveObj = {
      invoiceNo: formValue.invoiceNo,
      vendorId: formValue.vendorId,
      vendorAccountNo: formValue.vendorAccountNo,
      invoiceDate: invoiceMonth+'/'+invoiceDate+'/'+invoiceYear,
      isHoldInSNAP: "N",
      incomeType: formValue.incomeType,
      netAmount: formValue.netAmount ? parseFloat(formValue.netAmount) : 0,
      taxAmount: formValue.taxAmount ? parseFloat(formValue.taxAmount) : 0,
      grossAmount: formValue.grossAmount ? formValue.grossAmount : 0,
      // currency: formValue.currency,  // screen === 'N'
      description: formValue.description ? formValue.description : '',
      state: formValue.state,
      zip: formValue.zip,
      attachements: []
    };
    // Adding unique id that we get from API for update invoice scenario
    if(this.popupDatas.initValue && this.popupDatas.initValue.id) {
      saveObj['id'] = this.popupDatas.initValue.id;
    }
    let copyLineItems = this.iosArrayForSave(formValue.ios);
    copyLineItems = this.iosDeleteArrayForSave(copyLineItems);
    saveObj['ioList'] = this.convertTaxableForSave(copyLineItems);
    // saveObj['lineItems'] = this.convertTaxableForSave(copyLineItems); // will be removed once api team changes it to ioList
    // delete saveObj['ioList'];
    /* Save To Add*/
    if (this.popupDatas.screen === 'N') {
      // saveObj['status'] = 2;  // will be removed once api team makes change
      const uploadAttachmentList = this.attachements.filter(obj => obj.isAdded === true);
      saveObj['attachements'] = this.getAttachments();
      this.invoiceService.createInvoice(saveObj).subscribe((res: any) => {
        if (uploadAttachmentList.length) {
          this.uploadRequiredFilesToS3(uploadAttachmentList);
        } else {
          this.loadingIcon = false;
          this.uS.toastMessage(res.message, '');
          this.dialogRef.close();
        }
        },err => {
          let errorMessage:string = ( err.error !== undefined && err.error.errorDesc !== undefined )?err.error.errorDesc:"Something went wrong.";
          this.uS.toastMessage(errorMessage, '');
          this.loadingIcon = false;
      });
    } else {
      delete saveObj.invoiceNo; // removed for now
      // delete saveObj.vendorAccountNo; // removed for now
      saveObj['status'] = formValue.status;
      const uploadAttachmentList = this.attachements.filter(obj => obj.isAdded === true);
      saveObj['attachements'] = this.getAttachments();
      this.invoiceService.updateInvoice(saveObj, formValue.invoiceNo, this.popupDatas.screen).subscribe(res => {
        this.invoiceUpdateEmit.emit('update');
        if (uploadAttachmentList.length) {
          this.uploadRequiredFilesToS3(uploadAttachmentList);
        } else {
          this.loadingIcon = false;
          this.dialogRef.close();
        }
      },err => {
        let errorMessage:string = ( err.error !== undefined && err.error.errorDesc !== undefined )?err.error.errorDesc:"Something went wrong.";
        this.uS.toastMessage(errorMessage, '');
        this.loadingIcon = false;
    });
    }
  }

  saveInvoice() {
    this.loadingIcon = true;
    setTimeout(() => {
      // calling save method inside time out to ensure the io details are fetched before save function
      if(this.invoiceForm.invalid || this.invoiceForm.value.invoiceNo === '' || !this.saveButtonEnabled){
        this.loadingIcon = false;
      }
      else{
        this.saveInvoiceFn();
      }
    }, 1000);
  }

  iosArrayForSave(iosArray) {
    const copyIOs = [];
    iosArray.forEach(io => {
      const copyIO = {};
      Object.keys(io).forEach(key => {
        if (key !== 'ioError') {
          copyIO[key] = io[key];
        }
      });
      copyIOs.push(copyIO);
    });
    return copyIOs;
  }

  iosDeleteArrayForSave(data) {
    this.deletedIos.filter(ios => {
      ios.wbsElement = null;
      data.push(ios);
    });
    return data;
  }
}
