<div class="multi-line-grid-container">
    <mat-table [dataSource]="data.dataSource" matSort matSortDirection="desc" matSortDisableClear>
        <ng-container *ngFor="let header of gridData.columns" matColumnDef="{{header.name}}">
            <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!header.sort"
                fxFlex="{{header.width}}">{{header.label}}</mat-header-cell>
            <mat-cell *matCellDef="let data; let i = index" fxFlex="{{header.width}}">
                <span *ngIf="header.name === 'rowEdit'">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="onEdit(data, i)"><mat-icon>more_vert</mat-icon></button>
                    <button mat-icon-button (click)="onEdit(data, i);editRow('edit')"><mat-icon>edit</mat-icon></button>
                </span>
                <!-- <span *ngIf="header.name === 'rowEdit' && hideMenu">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="editRow('close')">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="editRow('update')">
                        <mat-icon>check</mat-icon>
                    </button>
                </span> -->
                <span class="custom-switches">
                    <ui-switch uncheckedLabel="Inactive" checkedLabel="Active" *ngIf="header.name === 'active'" size="small" [checked]="data[header.name] === 'Y' ? true : false"></ui-switch>
                    {{header.name !== 'active' ? data[header.name] : ''}}
                </span>
                
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let data; let i = index; columns: displayedColumns;"></mat-row>
        <!-- <mat-row *matRowDef="let data; let i = index; columns: displayedColumns;" [hidden]="selectedIndex === i ? true : false"></mat-row> -->
    </mat-table>
</div>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="editRow('edit')">Edit</button>
    <button mat-menu-item (click)="editRow('delete')">Delete</button>
</mat-menu>

<app-edit-table-component [edittedRow]="emitEditRow()" (updatedRow)="edittableRow($event)" *ngIf="loadEdittedData && hideMenu"></app-edit-table-component>
