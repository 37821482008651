<div class="multiMonthPicker">
  <div>
    <div class="dateCursor" matInput (click)="openClosePicker(true)">
      <div [ngClass]="{'position-createdBy': createdBy !== ''}">
        <div>{{selectedDisplay}}<mat-icon>arrow_drop_down</mat-icon>
          <div class="createdBy-detail">{{createdBy}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="outerCard" *ngIf="showMonthPicker">
    <div class="headLabel">Year<div class="closeMonthPicker" (click)="openClosePicker(false)">X</div>
    </div>
    <div class="topPanel">
      <!-- <button class="prevYearButton" (click)="decrementYear()"> -->
      <mat-icon class="prevYearButton" (click)="decrementYear()">arrow_left</mat-icon>
      <!-- </button> -->
      <span class="yearLabel" (click)="onYearClick(year)" [ngClass]="{isSelected: year==selectedYear }"
        *ngFor="let year of years">{{year}}</span>
      <!-- <button class="nextYearButton" (click)="incrementYear()"> -->
      <mat-icon class="nextYearButton" (click)="incrementYear()">arrow_right</mat-icon>
      <!-- </button> -->
    </div>
    <hr class="noMargin">
    <div class="monthView" *ngIf="isMonthView">
      <div class="headLabelMonth">Month</div>
      <div class="contentPanel">
        <div (click)="onClick(month.monthNo)" class="monthItem" *ngFor="let month of monthsData; let i = index">
          <div class="monthPadding"
            [ngClass]="{inRange: month.isInRange,isLowerEdge: month.isLowerEdge, isUpperEdge: month.isUpperEdge}">
            <div class="monthItemHighlight"
              [ngClass]="{'': rangeIndexes[0]=== null && rangeIndexes[1] == null, isEdge: rangeIndexes[0]===month.monthNo || rangeIndexes[1]===month.monthNo ,inQuarter: (month.isInRange && (rangeIndexes[0]!=month.monthNo || (rangeIndexes[1]!=null && rangeIndexes[1]!=month.monthNo))) }">
              {{ month.monthName }}
            </div>
          </div>
        </div>
      </div>
      <button class="btnOK" [ngClass]="{'okDisabled': rangeIndexes[0]==null && rangeIndexes[1]==null}" (click)="emitSelectedData()"
        [disabled]="rangeIndexes[0]==null && rangeIndexes[1]==null">OK</button>
    </div>
    <div *ngIf="!isMonthView">
      <div class="headLabelMonth">Quarter</div>
      <div (click)="onQuarterClick(quarter.id)" class="quarterItem" [ngClass]="{isSelected: i === (selectedQuarter-1) }" *ngFor="let quarter of quarterList; let i = index">
        {{ quarter.description }}
      </div>
    </div>
  </div>
</div>
