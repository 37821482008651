import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, Form } from '@angular/forms';
import { MatTableDataSource, MatTableModule, MatTable } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAddEstimateDetail, IAddEstimateMatsterData, IShowDetail, IMediaTypeDetail, IImportReq, IEstimatesDetails,
   IImportEstimates, IEstimatesImportDetail } from '../../../models/estimates.model';
import { EstimatesService } from '../../../services/estimates.service';
import { AppSingletonService } from 'src/app/services/app-singleton.service';
import * as moment from 'moment';
import { AppUtilityService } from 'src/app/services/utility.service';
import { IMonthPickerDetail } from '../../../models/multi-month-picker.model';
@Component({
  selector: 'app-add-popup',
  templateUrl: './add-popup.component.html',
  styleUrls: ['./add-popup.component.scss'],
})
export class AddPopupComponent implements OnInit {
  excel = 'excel';
  showTooltipIcon = false;
  hasDuplicate = false;
  @Input() addEstimateDetail: any;
  @Output() newEstimatesEmit: EventEmitter<any> = new EventEmitter<any>();
  displayedColumns: string[];
  showList: IShowDetail[];
  mediaTypeList: IMediaTypeDetail[];
  dataSource: IAddEstimateDetail[] = [];
  addedButNotSavedItems: IAddEstimateDetail[] = [];
  validationMessage = 'A value for [mediatype] for this show is already exists. You must delete this row to continue.';
  tableForm: FormGroup;
  popupId = '';
  public yearList;
  public monthList;
  loadingIcon = false;

  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public popupDatas: any,
    private formBuilder: FormBuilder, private aSS: AppSingletonService,
    private uS: AppUtilityService,
    public dialogRef: MatDialogRef<AddPopupComponent>, public estimateService: EstimatesService
  ) { }

  ngOnInit() {
    this.getMasterData();
    if (!this.popupDatas.isImport) {
      this.onInitMethods();
    }
  }

  onInitMethods(){
    this.setMonthYear();
    this.tableForm = this.formBuilder.group({
      estimates: this.formBuilder.array([]),
    });

    this.loadValues();
    this.setEstimatesForm();
  }

  setMonthYear() {
    this.monthList = this.aSS.mediaJsonStore.estimates.months;
    this.monthList.forEach(month => month.id = Number(month.id));
    const yearList = () => {
      const years = []; const now = moment();
      const dateStart = now.subtract('5', 'years'); const dateEnd = moment().add(9, 'y');
      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push({id: Number(dateStart.format('YYYY')), value: dateStart.format('YYYY')}); dateStart.add(1, 'year'); }
      return years;
     };
    this.yearList = yearList();
  }

  getMasterData() {
    this.estimateService.fetchAddMasterData().subscribe((res: any) => {
      this.showList = res.showTitles;
      this.mediaTypeList = res.mediaTypes;
    });
  }

  getMonthName(monthNo, year) {
    return new Date(year, monthNo - 1, 2).toLocaleDateString(undefined, { month: 'long' });
  }

  loadValues() {
    this.popupId = this.popupDatas.header === 'Import Estimates' ? 'import' : this.popupDatas.header === 'Add New Estimate' ? 'add' : '';
    switch (this.popupId) {
      case 'add': this.dataSource.push(this.initEstimate()); break;
      case 'import':
        this.popupDatas.initValue.forEach(data => {
          this.dataSource.push(this.initEstimate(data));
        });
        break;
    }
    this.displayedColumns = this.popupDatas.displayedColumns;
    if (this.popupDatas.addedButNotSavedItems && this.popupDatas.addedButNotSavedItems.length > 0) {
      this.addedButNotSavedItems = this.popupDatas.addedButNotSavedItems;
    }
  }
  private setEstimatesForm() {
    const userCtrl = this.tableForm.get('estimates') as FormArray;
    this.dataSource.forEach((estimateItem) => {
      userCtrl.push(this.setEstimatesFormArray(estimateItem));
    });
    userCtrl.controls[0] && userCtrl.controls[0].markAllAsTouched();
  }
  private setEstimatesFormArray(estimateItem) {
    return this.formBuilder.group({
      showId: [estimateItem.showId, Validators.required],
      showName: [estimateItem.showName],
      year: [estimateItem.year, Validators.required],
      month: [estimateItem.month, Validators.required],
      mediaTypeId: [estimateItem.mediaTypeId, Validators.required],
      mediaType: [estimateItem.mediaType],
      estimate: [estimateItem.estimate, Validators.required],
      genreNo: [estimateItem.genreNo],
      genreName: [estimateItem.genreName],
      message: [estimateItem.message],
      estimateAmountError: [estimateItem.estimateAmountError],
      estimatePeriodError: [estimateItem.estimatePeriodError],
      showNameError: [estimateItem.showNameError]
    });
  }

  addNewRow() {
    const userCtrl = this.tableForm.get('estimates') as FormArray;
    let newEstimate;
    switch (this.popupId) {
      case 'add':
        newEstimate = this.initEstimate();
        this.dataSource.push(newEstimate);
        userCtrl.push(this.setEstimatesFormArray(newEstimate));
        this.table.renderRows();
        break;
      case 'import':
        if (!this.tableForm.touched && !this.popupDatas.initValue) {
          this.popupDatas.initValue.forEach(data => {
            newEstimate = this.initEstimate(data);
            this.dataSource.push(newEstimate);
            userCtrl.push(this.setEstimatesFormArray(newEstimate));
            this.table.renderRows();
          });
        } else {
          newEstimate = this.initEstimate();
          this.dataSource.push(newEstimate);
          userCtrl.push(this.setEstimatesFormArray(newEstimate));
          this.table.renderRows();
        }
        break;
    }
  }

  initEstimate(data?) {
    let newEstimate;
    if (data) {
      newEstimate = {
        year: data.year ? data.year : null,
        month: data.month ? data.month : null,
        showId: data.showId ? Number(data.showId) : '',
        mediaTypeId: data.mediaTypeId ? Number(data.mediaTypeId) : '',
        estimate: data.estimate,
        message: data.message,
        estimateAmountError: data.estimateAmountError,
        estimatePeriodError: data.estimatePeriodError,
        showNameError: data.showNameError
      } as IAddEstimateDetail;
    } else if (!data && this.popupId !== 'import') {
      newEstimate = {
        year: this.popupDatas.monthSelected[0].year,
        month: this.popupDatas.monthSelected[0].firstMonth,
      } as IAddEstimateDetail;
    } else if (!data && this.popupId === 'import') {
      newEstimate = {} as IAddEstimateDetail;
    }
    return newEstimate;
  }

  addEstimates() {
    this.validateEstimates();
  }

  toUTCFormat(dateToFormat){
    const utcMoment = moment.utc(dateToFormat);
    const utcDate = new Date( utcMoment.format() );
    return utcDate;
  }

  formatSaveReq() {
    // console.log('this.tableForm.value.estimates', this.tableForm.value.estimates);
    const importReqObj = {
      viewType: this.popupDatas.requestDetails.viewType,
      estimateAsOfDate: this.toUTCFormat(this.popupDatas.requestDetails.asOfDate),
      importEstimates:  []
    };
    let periodArr = [];
    this.tableForm.value.estimates.filter(formData => {
      const month = formData.month.toString().length === 2 ? formData.month :
       formData.month.toString().length === 1 ? '0' + formData.month.toString() : '';
      const period = month + '-' + formData.year.toString();
      let list = [];
      if(periodArr.indexOf(period) < 0) {
        periodArr.push(period);
        let estimate = {
          estimatePeriod: period,
          periodType: this.popupDatas.requestDetails.periodType,
          viewType: this.popupDatas.requestDetails.viewType,
          estimateAsOfDate: this.toUTCFormat(this.popupDatas.requestDetails.asOfDate),
          currentVersionNo: null,
          currentEstimateId: null,
          estimates: []
        };
        let estimateDetArr: IEstimatesDetails[] = [];
        list = this.tableForm.value.estimates.filter(data =>data.month === formData.month && data.year === formData.year);
        list.forEach(item => {
           const estimateDetailsArr: IEstimatesImportDetail[] = [];
           const details: IEstimatesImportDetail = {
              estimateDetailId: null,
              mediaTypeId: item.mediaTypeId,
              estimateAmount: item.estimate
            };
           estimateDetailsArr.push(details);
           const estimatesArr: IEstimatesDetails = {
            showId: item.showId,
            estimateDetails: estimateDetailsArr
          };
           estimateDetArr.push(estimatesArr);
        });
        estimate.estimates = estimateDetArr;
        importReqObj.importEstimates.push(estimate);
      } else {
        // continue;
      }
    });
    return importReqObj;
  }

  saveEstimates() {
    const importReq = this.formatSaveReq();
    this.loadingIcon = true;
    this.estimateService.saveImportEstimates(importReq).subscribe((res: any) => {
      if (typeof res !== 'undefined' && res.length) {
        // console.log('save res', res);
        this.loadingIcon = false;
        this.newEstimatesEmit.emit(res);
      }
    }, err => {
      this.loadingIcon = false;
      this.uS.toastMessage('Something went wrong', '');
    });
  }

  validateEstimates() {
    this.tableForm.value.estimates.forEach(val => {
      val.versionNo = this.popupDatas.version;
      val.month     = (val.month < 10)?'0'+val.month:''+val.month;
      val.message   = null;
    });
    this.estimateService.validateEstimates(this.tableForm.value.estimates).subscribe((res: any) => {
      if (typeof res !== 'undefined' && res.length) {
        this.appendValidationMessage(res);
      }
    });
  }

  appendValidationMessage(validationList: any) {
    this.hasDuplicate = false;
    const formValues = this.tableForm.get('estimates') as FormArray;
    validationList.forEach(validationRecord => {
      if (validationRecord.message) {
        formValues.controls.forEach((formRecord, index) => {
          if (formRecord.value.showId === validationRecord.showId && formRecord.value.mediaTypeId === validationRecord.mediaTypeId) {
            this.hasDuplicate = true;
            formRecord.patchValue(
              {
                message: validationRecord.message
              });
            this.dataSource[index].message = validationRecord.message;
          }
        });
      }
    });
    this.checkDuplicatesInAddList();
    this.checkDuplicatesInAddButNotSavedList();
    if (!this.hasDuplicate) {
      this.newEstimatesEmit.emit(this.tableForm.value.estimates);
    }
  }

  checkDuplicatesInAddList() {
    const formValues = (this.tableForm.get('estimates') as FormArray);
    formValues.controls.forEach((checkItem, checkItemIndex) => {
      let counter = 0;
      formValues.controls.forEach((checkAgainstItem, checkAgainstItemIndex) => {
        if (checkAgainstItem.value.showId === checkItem.value.showId &&
          checkAgainstItem.value.mediaTypeId === checkItem.value.mediaTypeId) {
          counter++;
          if (counter > 1) {
            this.hasDuplicate = true;
            const valMessage = this.validationMessage.replace('[mediatype]', checkItem.value.mediaType);
            checkAgainstItem.patchValue(
              {
                message: valMessage
              });
            this.dataSource[checkAgainstItemIndex].message = valMessage;
          }
        }
      });
    });
  }

  checkDuplicatesInAddButNotSavedList() {
    if (this.addedButNotSavedItems.length > 0) {
      const formValues = (this.tableForm.get('estimates') as FormArray);
      formValues.controls.forEach((checkItem, checkItemIndex) => {
        this.addedButNotSavedItems.forEach((checkAgainstItem, checkAgainstItemIndex) => {
          if (checkAgainstItem.showId === checkItem.value.showId &&
            checkAgainstItem.mediaTypeId === checkItem.value.mediaTypeId) {
            this.hasDuplicate = true;
            const valMessage = this.validationMessage.replace('[mediatype]', checkItem.value.mediaType);
            checkItem.patchValue(
              {
                message: valMessage
              });
            this.dataSource[checkItemIndex].message = valMessage;
          }
        });
      });
    }
  }


  deleteShow(rowIndex) {
    const userCtrl = this.tableForm.get('estimates') as FormArray;
    this.dataSource.splice(rowIndex, 1);
    userCtrl.removeAt(rowIndex);
    this.table.renderRows();
  }
  onShowChange($event, rowIndex) {
    const show = this.showList.find(obj => obj.showId === $event.value);
    const userCtrl = this.tableForm.get('estimates') as FormArray;
    userCtrl.controls[rowIndex].patchValue(
      {
        genreNo: show.genreNo,
        genreName: show.genreDesc,
        showName: show.showName,
        showNameError: null,
        message: null
      });
    this.dataSource[rowIndex].genreNo = show.genreNo;
    this.dataSource[rowIndex].genreName = show.genreDesc;
    this.dataSource[rowIndex].showName = show.showName;
    this.dataSource[rowIndex].showNameError = null;
    this.dataSource[rowIndex].message = null;
  }

  onMonthChange($event, rowIndex) {
    const monthValue = this.monthList.find(obj => obj.id === $event.value).id;
    const userCtrl = this.tableForm.get('estimates') as FormArray;
    userCtrl.controls[rowIndex].patchValue({ month: monthValue, estimatePeriodError: null });
    this.dataSource[rowIndex].month = Number(monthValue);
    this.dataSource[rowIndex].estimatePeriodError = null;
  }

  onYearChange($event, rowIndex) {
    const yearValue = this.yearList.find(obj => obj.id === $event.value).id;
    const userCtrl = this.tableForm.get('estimates') as FormArray;
    userCtrl.controls[rowIndex].patchValue({ year: yearValue, estimatePeriodError: null });
    this.dataSource[rowIndex].year = Number(yearValue);
    this.dataSource[rowIndex].estimatePeriodError = null;
  }

  onMediaTypeChange($event, rowIndex) {
    const mediaTypeValue = this.mediaTypeList.find(obj => obj.mediaTypeId === $event.value).mediaTypeDesc;
    const userCtrl = this.tableForm.get('estimates') as FormArray;
    userCtrl.controls[rowIndex].patchValue({ mediaType: mediaTypeValue, message: null });
    this.dataSource[rowIndex].mediaType = mediaTypeValue;
    this.dataSource[rowIndex].message = null;
  }

  onCancel() {
    this.dialogRef.close();
  }

  checkError(event, rowIndex) {
    if (event.target.value.length > 0) {
      const userCtrl = this.tableForm.get('estimates') as FormArray;
      userCtrl.controls[rowIndex].patchValue(
        {
          estimateAmountError: null
        });
      this.dataSource[rowIndex].estimateAmountError = null;
    }
  }

  handleResponse(res){
    this.loadingIcon = true;
    if (res) {
      let initVal = {} as IAddEstimateDetail; let initMonth = {} as IMonthPickerDetail;
      const initValue = [] as IAddEstimateDetail[]; const initMonthVal = [] as IMonthPickerDetail[];
      res.forEach(response => {
        const mediaTypeValue = response.mediaName ? this.mediaTypeList.find(obj => obj.mediaTypeDesc.toLowerCase() === response.mediaName.toLowerCase()): null;
        const showIdValue = response.showName ? this.showList.find(obj => obj.showName.toLowerCase() === response.showName.toLowerCase()).showId : null;
        const showNameValue = response.showName ? this.showList.find(obj => obj.showName.toLowerCase() === response.showName.toLowerCase()).showName : null;
        const month = response.estimatePeriod ? Number(moment(response.estimatePeriod + '-02', 'MM-YYYY-DD').format('MM')) : 0;
        const year = response.estimatePeriod ? Number(moment(response.estimatePeriod.substring(3, 7)).format('YYYY')) : 0;
        initVal = {
          estimate: response.estimateAmount,
          month,
          year,
          mediaTypeId: mediaTypeValue && mediaTypeValue.mediaTypeId,
          showId: showIdValue,
          showName: showNameValue,
          message: response.mediaNameErroMessage,
          estimateAmountError: response.estimateAmountErrorMessage,
          estimatePeriodError: response.estimatePeriodErrorMessage,
          showNameError: response.showNameErroMessage
        };
        initMonth = {
          year: initVal['year'],
          firstMonth: initVal['month'],
          secondMonth: null,
          quarter: Math.floor(initVal['month'] / 3) + 1,
          isMonthViewSelected: true,
          monthRange: response.estimatePeriod,
          selectedDisplay: null
        };
        initValue.push(initVal);
        initMonthVal.push(initMonth);
      });
      this.popupDatas.initValue = initValue;
      this.popupDatas.monthSelected = initMonthVal;
      this.popupDatas.isImport = false;
      this.onInitMethods();
    }
    this.loadingIcon = false;
  }

}
