<div class="sub-head-right" *ngIf="linkItems && linkItems.length>0">
  <div class="linkInARow" *ngFor="let link of linkItems; let i = index">
    <a href="javascript:;" class="linkDisabled" *ngIf="!link.isEnabled">{{link.name}}
      <mat-icon class="filter-icon" *ngIf="link.iconPath">{{link.iconPath}}</mat-icon>
    </a>
    <a href="javascript:void(0);" *ngIf="link.isEnabled" (click)="linkAction(link)">{{link.name}}
      <mat-icon class="filter-icon" [ngClass]="{'filterActive': link.name ==='FILTER' && filterActive}" *ngIf="link.iconPath">{{link.iconPath}}</mat-icon>
    </a>
    <span class="commonSeperator" *ngIf="i <(linkItems.length-1)"></span>
  </div>
</div>
