import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { IAdminDetail } from 'src/app/models/admin.model';
import { EditTableComponentComponent } from '../edit-table-component/edit-table-component.component';

@Component({
  selector: 'app-multi-inline-edit-grid',
  templateUrl: './multi-inline-edit-grid.component.html',
  styleUrls: ['./multi-inline-edit-grid.component.scss'],
})
export class MultiInlineEditGridComponent implements OnInit, AfterViewInit {
  @Input() public gridData;
  @Input() public showDialog;
  // @Output() public emitRowModify: EventEmitter<any> = new EventEmitter();
  // @Output() emittedRow: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [];
  public data = {
    dataSource: new MatTableDataSource<any>(),
    // selected: new SelectionModel<any>(true, [])
  };
  private formattedDataSource;
  toggleSwitch;
  private selectedRow;
  public selectedIndex;
  hideMenu = false;
  // showEdit = false;
  loadEdittedData = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.displayedColumns = this.gridData.displayColumns;
    this.data.dataSource = new MatTableDataSource(this.gridData.data);
    this.formattedDataSource = {...this.data.dataSource};
    // this.data.selected = new SelectionModel<any>(true, []);
    // console.log('multi', this.gridData);
  }

  ngAfterViewInit() {
    this.data.dataSource.sort = this.sort;
  }

  emitEditRow() {
    const emitVal =  {...this.selectedRow};
    return emitVal;
  }

  edittableRow(event) {
    this.hideMenu = event.hideMenu;
    console.log('received Updated data from child', event);
  }

  onEdit(event, i) {
    this.selectedRow = {}; this.selectedIndex = i;
    console.log(event, i);
    this.selectedRow = event;
  }

  openDialog() {
    // Might have to move to the config component
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      width: '372px',
      height: '164px',
      disableClose: true,
      data: { name: 'ioData'}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  editRow(val) {
    let action = '';
    switch (val) {
      case 'edit':
        action = 'update';
        const event = {currentItem: this.selectedRow, operation: 'edit' };
        this.hideMenu = true;
        // this.showEdit = true;
        this.emitEditRow();
        setTimeout(() => {
          this.loadEdittedData = true;
        }, 100);
        break;
      case 'delete':
        action = 'delete';
        // Add API req data
        if (this.selectedRow.ioCount || this.selectedRow.estimateRecordCount) { this.openDialog(); }
        else {
          this.formattedDataSource._data.value.splice(this.selectedIndex, 1);
          this.data.dataSource = this.formattedDataSource._data.value;
        }
        break;
      case 'update':
        action = 'update';
        this.hideMenu = false;
        // this.showEdit = true;
        // Call update API
        break;
      case 'close':
        this.hideMenu = false;
        // this.showEdit = true;
        break;
    }
  }

}
