import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DragDropDirective } from '../../../directive/drag-drop-directive';
import { DomSanitizer } from '@angular/platform-browser';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { InvoiceService } from 'src/app/services/invoice.service';
import { AdminService } from 'src/app/services/admin-service';
import {FormControl, Validators} from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { AppSingletonService } from 'src/app/services/app-singleton.service';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent implements OnInit {
  @Output() doAction: EventEmitter<any> = new EventEmitter<any>();
  public showImport = false;
  public gridDataDetails = {
    data: [],
    columns: [],
    displayColumns: [],
    rowModify: { 'checkbox': true },
    topColumns: {display: false},
    page: 'closeAccruals'
  };
  close = faTimes;
  files: any[] = []; fileUrl;
  content;
  accrualVersions;
  versionNameDuplicate = false;
  // public series = ''; public seasonType = '';
  reader = new FileReader();
  excel = 'excel'; pdfList; fileObjList; errorMsg = '';
  series = new FormControl('', [Validators.required]);
  seasonType = new FormControl('', [Validators.required]);
  mediaType = new FormControl('', [Validators.required]);
  postingDate = new FormControl('', [Validators.required]);
  postingPeriod = new FormControl('', [Validators.required]);
  declinecomment = new FormControl('');
  date = new FormControl(moment());
  versionName = new FormControl('', [Validators.required]);
  selectedItemsList = [];
  checkedIDs = [];

  constructor(public dialogRef: MatDialogRef<WarningDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private iS: InvoiceService,
              private sanitizer: DomSanitizer,
              public cS: CommonService,
              private aSS: AppSingletonService,
              public aS: AdminService) {
              }

  ngOnInit(): void {
    this.content = this.data.name;
    if (this.data.accrualVersions) {
      this.accrualVersions = this.data.accrualVersions;
      this.versionName.setValue(this.data.versionName);
    }
    if (this.data.name && this.data.name === 'pdf-list') {
      this.pdfList = this.data.list; this.fileObjList = this.data.fileList;
      // this.fileUrl = window.URL.createObjectURL(blob);
    }
    if(this.data.name === 'closeAccrual' && this.data.version) {
      this.gridDataDetails.columns = this.aSS.mediaJsonStore.closeAccruals.closeAccrualsColumns;
      this.gridDataDetails.displayColumns = ['chkSelection', 'versionNo', 'versionName', 'journalEntryCount'];
      this.gridDataDetails.data = this.data.version;
      // this.data.version.forEach(version => {
      //   if (version.isChecked) {this.checkedIDs.push(version.versionName)}
      // });
    }
  }

  getFile(fileName){
    let fileObj = this.data.fileList.filter(obj => obj.name === fileName);
    const blob = new Blob(fileObj, { type: 'application/pdf' });
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  handleResponse(e){
    console.log(e);
    this.dialogRef.close({data: e});
  }

  addField(field) {
    const userSSO = this.cS.getLoggedInSSO();
    let reqPayload = {};
    switch (field){
      case 'addSeries':
        reqPayload = { seriesName: this.series.value };
        if (reqPayload && reqPayload['seriesName']) {
          this.aS.addSeries(reqPayload).subscribe(addSrs => {
            if (addSrs) {
              for (const data in addSrs) {
                if (data) {
                  if (addSrs[data].validationMessage) { this.errorMsg = addSrs[data].validationMessage; }
                  else { this.dialogRef.close({data: addSrs}); }
                }
              }
            }
          });
        }
        break;
      case 'addSeasonType':
         reqPayload = { seasonType: this.seasonType.value };
         if (reqPayload && reqPayload['seasonType']) {
          this.aS.addSeasonType(reqPayload).subscribe(addSeason => {
            if (addSeason) {
              for (const data in addSeason) {
                if (data) {
                  if (addSeason[data].validationMessage) { this.errorMsg = addSeason[data].validationMessage; }
                  else { this.dialogRef.close({data: addSeason}); }
                }
              }
            }
           });
         }
         break;
      case 'addMediaType':
        reqPayload = { mediaTypeName: this.mediaType.value};
        if (reqPayload && reqPayload['mediaTypeName']) {
        this.aS.savMediaType(reqPayload).subscribe(addMedia => {
          if (addMedia) {
            for (const data in addMedia) {
              if (data) {
                if (addMedia[data].validationMessage) { this.errorMsg = addMedia[data].validationMessage; }
                else { this.dialogRef.close({data: addMedia}); }
              }
            }
          }
          });
        }
        break;
    }
  }
  onDecline(){
    const declineData = this.declinecomment.value === '' ? 'decline' : this.declinecomment.value;
    this.dialogRef.close({data: declineData, close: true});
  }

  onSkip() {
    this.dialogRef.close({data: 'decline', close: true});
  }

  deleteFile(file, i) {
    this.pdfList.splice(i, 1);
    // this.pdfList.length <= 0 ? this.iS.disableListBtn.next(true) : this.iS.disableListBtn.next(false);
    if(this.pdfList.length <= 0){
      this.onCancel();
      this.iS.disableListBtn.next(true)
    }
    else
    {
      this.iS.disableListBtn.next(false)
    }
    // console.log(file, this.pdfList);
  }

  postingInfo() {
    const postDate = moment(this.postingDate.value).format('MM/DD/YYYY');
    let reqPayload;
    if (this.content === 'postDoc') {
      reqPayload = {postingDate: postDate, postingPeriod: Number(this.postingPeriod.value), close: true};
    } else { reqPayload = {postingDate: postDate, close: true } ; }
    this.dialogRef.close(reqPayload);
  }

  saveAccrual() {
    if (this.accrualVersions.indexOf(this.versionName.value) === -1) {
      this.dialogRef.close({ action: 'save', versionName: this.versionName.value });
    } else {
      this.versionNameDuplicate = true;
    }
  }

  closeAccrual() {
    this.dialogRef.close({ action: 'close', selectedList: this.checkedIDs });
  }

  reset(){
    this.dialogRef.close({reset: true});
  }

  onCancel() {
    this.dialogRef.close({close: false});
  }

  doYesAction(event){
    this.doAction.emit(event);
  }

  changeSelection(data) {
    this.fetchSelectedItems(data);
  }

  fetchSelectedItems(version) {
    this.checkedIDs = [];
    if(version.selectedList && version.selectedList.selected && version.selectedList.selected.length){
      this.checkedIDs = version.selectedList.selected.map(obj => obj.actualVersionName);
      //console.log('selected Versions', test);
    }
  }

}
